var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import Loader from "@skepool/core/src/components/Loader";
import ReportsService from "../services/ReportsService";
import ErrorBoundary from "react-error-boundary";
import { PageHeader } from "@skepool/core/src/components/PageHeader";
import { Page } from "@skepool/core/src/components/Page";
import Chart, { BASE_COLORS } from "@skepool/core/src/components/Chart";
import { useParams } from "react-router-dom";
import { setHours } from "date-fns/esm";
import parseISO from "date-fns/parseISO";
import { hasPermission } from "@skepool/handel/src/utils";
import TradeService from "@skepool/handel/src/services/TradeService";
import { VStack } from "@skepool/core/src/components/Layout";
import Button from "@skepool/core/src/components/Button";
var getChartColors = function (chart) {
    if (chart.type == "hydrological-balance") {
        return [BASE_COLORS[0], BASE_COLORS[3], BASE_COLORS[5]];
    }
    return BASE_COLORS;
};
var getChartType = function (chart, c) {
    if (chart.type == "hydrological-balance" && c.label !== "Total") {
        return "column";
    }
    return "line";
};
export var DynamicChart = function (_a) {
    var chart = _a.chart;
    var config = {
        yAxis: {
            minRange: 0.1,
            maxPadding: 0,
            title: {
                text: chart.unit
            }
        },
        series: chart.data.map(function (c) {
            return {
                name: c.label,
                type: getChartType(chart, c),
                data: c.series.map(function (s) {
                    var d = parseISO(s.date);
                    return [setHours(d, 12).getTime(), s.value];
                }),
                tooltip: {
                    valueSuffix: " " + chart.unit
                }
            };
        }),
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
                day: "%Y-%m-%d",
                month: "%Y-%m",
                week: "%Y-%m-%d"
            },
            labels: {
                style: {
                    fontSize: ".6rem"
                },
                rotation: -45
            }
        },
        title: {
            text: chart.caption,
            align: "left",
            style: {
                fontWeight: 900,
                fontSize: "1.3125rem",
                lineHeight: "1.38",
                fontFamily: "'SK Sans', 'Helvetica Neue', 'Helvetica', sans-serif",
                color: "rgb(51, 51, 51)"
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            height: 320,
            zoomType: "x",
            spacingTop: 8,
            backgroundColor: "transparent"
        },
        tooltip: {
            xDateFormat: "%Y-%m-%d",
            style: {
                padding: "1rem"
            },
            shared: true,
            valueDecimals: 2
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0
        },
        plotOptions: {
            column: {
                stacking: "normal"
            }
        },
        colors: getChartColors(chart)
    };
    return React.createElement(Chart, { config: config });
};
export var ChartContainer = function (_a) {
    var chart = _a.chart, isAdmin = _a.isAdmin, props = __rest(_a, ["chart", "isAdmin"]);
    var update = useAsyncCallback(ReportsService.updateChart);
    var config = {
        yAxis: {
            minRange: 0.1,
            maxPadding: 0,
            title: {
                text: chart.unit
            }
        },
        series: chart.data.map(function (c) {
            return {
                name: c.label,
                type: getChartType(chart, c),
                data: c.series.map(function (s) {
                    var d = parseISO(s.date);
                    return [setHours(d, 12).getTime(), s.value];
                }),
                tooltip: {
                    valueSuffix: " " + chart.unit
                }
            };
        }),
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
                day: "%Y-%m-%d",
                month: "%Y-%m",
                week: "%Y-%m-%d"
            },
            labels: {
                style: {
                    fontSize: ".6rem"
                },
                rotation: -45
            }
        },
        title: {
            text: chart.caption,
            style: {
                fontWeight: 900,
                fontSize: "1.4rem",
                lineHeight: "2rem",
                fontFamily: "'SK Sans', 'Helvetica Neue', 'Helvetica', sans-serif",
                color: "#3c1053"
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            zoomType: "x",
            spacingTop: 24,
            backgroundColor: "transparent"
        },
        tooltip: {
            xDateFormat: "%Y-%m-%d",
            style: {
                padding: "1rem"
            },
            shared: true,
            valueDecimals: 2
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0
        },
        plotOptions: {
            column: {
                stacking: "normal"
            }
        },
        colors: getChartColors(chart)
    };
    var onFileChange = function (e) {
        var files = e.target.files;
        if (files.length && files[0]) {
            var file = files[0];
            update.execute(file, chart.id).then(function () {
                window.location.reload();
            });
        }
    };
    return (React.createElement(Box, __assign({ bg: "white", p: 4 }, props),
        React.createElement(VStack, { alignItems: "center", bg: "white" },
            React.createElement(Chart, { config: config }),
            isAdmin && chart.editable && (React.createElement(VStack, { spacing: 2 },
                update.error && (React.createElement(Text, { color: "red", textAlign: "center" }, "N\u00E5got fick fel vid import")),
                React.createElement(Box, { display: "flex", justifyContent: "center" },
                    React.createElement(Button, { as: "label", px: 4, py: 2, loading: update.loading },
                        "Ladda upp excel",
                        React.createElement(Box, { as: "input", onChange: onFileChange, display: "none", type: "file" }))))),
            chart.excelexport && (React.createElement(Box, null,
                React.createElement(Button, { as: "a", href: "/api/analysis/export/" + chart.id + "/excel" }, "Ladda ner excel"))))));
};
var ChartSkeleton = function (_a) {
    var children = _a.children;
    return (React.createElement(Box, null,
        React.createElement(PageHeader, null,
            React.createElement(Text, { fontSize: "xl", fontWeight: "black" }, "Grafer och diagram")),
        children));
};
var Service = {
    getAll: function (chart_id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, chart, currentUser;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        ReportsService.getChart(chart_id),
                        TradeService.getCurrentUser()
                    ])];
                case 1:
                    _a = _b.sent(), chart = _a[0], currentUser = _a[1];
                    return [2 /*return*/, {
                            chart: chart,
                            currentUser: currentUser
                        }];
            }
        });
    }); }
};
var ChartPage = function () {
    var params = useParams();
    var chart_id = params.chart_id;
    var data = useAsync(function () { return Service.getAll(chart_id); }, [chart_id]);
    if (data.loading) {
        return (React.createElement(ChartSkeleton, null,
            React.createElement(Box, { height: 200, position: "relative" },
                React.createElement(Loader, null))));
    }
    if (data.error) {
        return (React.createElement(ChartSkeleton, null,
            React.createElement(ErrorFallback, { error: data.error })));
    }
    var res = data.result;
    if (!res) {
        return null;
    }
    var isAdmin = hasPermission("CREATE_ANALYSIS", res.currentUser.permissions);
    return (React.createElement(ChartSkeleton, null,
        React.createElement(Page, null,
            React.createElement(ErrorBoundary, { FallbackComponent: ErrorFallback },
                React.createElement(Box, { bg: "offwhite", minHeight: "calc(100vh - 100px)" },
                    React.createElement(ChartContainer, { css: { "> div": { maxWidth: 600 } }, chart: res.chart, isAdmin: isAdmin }))))));
};
export default ChartPage;
