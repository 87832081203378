// theme.js
// breakpoint values
// any array length works with styled-system
// ['sm', 'md', 'lg', 'xl']
var breakpoints = ["576px", "768px", "992px", "1200px"];
var colors = {
    darkpurple: "#3c1053",
    purple: "#702082",
    blue: "#00a9e0",
    lightblue: "#b6e0ee",
    verylightblue: "#dfeff4",
    grey: "#888888",
    midgrey: "#a7a8aa",
    lightgrey: "#e7e7e7",
    offwhite: "#f3f3f3",
    black: "#333333",
    pink: "#e63888",
    green: "#49c5b1",
    orange: "#f4633a",
    eggshell: "#f7f5df"
};
// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
var space = ["0rem", ".25rem", ".5rem", "1rem", "2rem", "4rem", "8rem", "16rem", "32rem"];
var fontSizes = {
    xxs: ".625rem",
    xs: ".75rem",
    sm: ".875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.3125rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem" // 48px
};
var lineHeights = {
    none: 1,
    mini: 1.2,
    tighter: 1.29,
    tight: 1.38,
    normal: 1.5,
    loose: 1.6,
    looser: 1.8
};
var fontWeights = {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
};
var letterSpacings = {
    normal: "normal",
    caps: "0.25em"
};
// border-radius
var radii = [0, 2, 4, 8];
var borders = [0, "1px solid", "2px solid"];
var theme = {
    breakpoints: breakpoints,
    colors: colors,
    space: space,
    fontSizes: fontSizes,
    lineHeights: lineHeights,
    fontWeights: fontWeights,
    letterSpacings: letterSpacings,
    radii: radii,
    borders: borders
};
export default theme;
