var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useCallback, useEffect, useRef, useState } from "react";
import Axios from "axios";
var InitialAsyncState = {
    status: "not-requested",
    loading: false,
    result: undefined,
    error: undefined
};
var InitialAsyncLoadingState = {
    status: "loading",
    loading: true,
    result: undefined,
    error: undefined
};
var defaultSetLoading = function (_asyncState) { return InitialAsyncLoadingState; };
var defaultSetResult = function (result, _asyncState) { return ({
    status: "success",
    loading: false,
    result: result,
    error: undefined
}); };
var defaultSetError = function (error, _asyncState) { return ({
    status: "error",
    loading: false,
    result: undefined,
    error: error
}); };
var noop = function () { };
var DefaultOptions = {
    initialState: function (options) { return (options && options.executeOnMount ? InitialAsyncLoadingState : InitialAsyncState); },
    executeOnMount: true,
    executeOnUpdate: true,
    setLoading: defaultSetLoading,
    setResult: defaultSetResult,
    setError: defaultSetError,
    onSuccess: noop,
    onError: noop
};
var normalizeOptions = function (options) { return (__assign(__assign({}, DefaultOptions), options)); };
var useAsyncState = function (options) {
    var _a = useState(function () { return options.initialState(options); }), value = _a[0], setValue = _a[1];
    var reset = useCallback(function () { return setValue(options.initialState(options)); }, [setValue, options]);
    var setLoading = useCallback(function () { return setValue(options.setLoading(value)); }, [value, setValue]);
    var setResult = useCallback(function (result) { return setValue(options.setResult(result, value)); }, [value, setValue]);
    var setError = useCallback(function (error) { return setValue(options.setError(error, value)); }, [value, setValue]);
    return {
        value: value,
        set: setValue,
        reset: reset,
        setLoading: setLoading,
        setResult: setResult,
        setError: setError
    };
};
var useIsMounted = function () {
    var ref = useRef(false);
    useEffect(function () {
        ref.current = true;
        return function () {
            ref.current = false;
        };
    }, []);
    return function () { return ref.current; };
};
var useCurrentPromise = function () {
    var ref = useRef(null);
    return {
        set: function (promise) { return (ref.current = promise); },
        get: function () { return ref.current; },
        is: function (promise) { return ref.current === promise; }
    };
};
// Relaxed interface which accept both async and sync functions
// Accepting sync function is convenient for useAsyncCallback
var useAsyncInternal = function (asyncFunction, params, options, source) {
    var normalizedOptions = normalizeOptions(options);
    var _a = useState(null), currentParams = _a[0], setCurrentParams = _a[1];
    var AsyncState = useAsyncState(normalizedOptions);
    var isMounted = useIsMounted();
    var CurrentPromise = useCurrentPromise();
    // We only want to handle the promise result/error
    // if it is the last operation and the comp is still mounted
    var shouldHandlePromise = function (p) { return isMounted() && CurrentPromise.is(p); };
    var executeAsyncOperation = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var promise = asyncFunction.apply(void 0, args);
        setCurrentParams(args);
        if (promise instanceof Promise) {
            CurrentPromise.set(promise);
            AsyncState.setLoading();
            promise.then(function (result) {
                if (shouldHandlePromise(promise)) {
                    AsyncState.setResult(result);
                }
                normalizedOptions.onSuccess(result, {
                    isCurrent: function () { return CurrentPromise.is(promise); }
                });
            }, function (error) {
                if (shouldHandlePromise(promise)) {
                    AsyncState.setError(error);
                }
                normalizedOptions.onError(error, {
                    isCurrent: function () { return CurrentPromise.is(promise); }
                });
            });
            return promise;
        }
        else {
            // We allow passing a non-async function (mostly for useAsyncCallback conveniency)
            var syncResult = promise;
            AsyncState.setResult(syncResult);
            return Promise.resolve(syncResult);
        }
    };
    // Keep this outside useEffect, because inside isMounted()
    // will be true as the component is already mounted when it's run
    var isMounting = !isMounted();
    useEffect(function () {
        if (isMounting) {
            normalizedOptions.executeOnMount && executeAsyncOperation.apply(void 0, params);
        }
        else {
            normalizedOptions.executeOnUpdate && executeAsyncOperation.apply(void 0, params);
        }
    }, params);
    return __assign(__assign({}, AsyncState.value), { set: AsyncState.set, setResult: AsyncState.setResult, reset: AsyncState.reset, cancel: function () { return source === null || source === void 0 ? void 0 : source.cancel(); }, execute: executeAsyncOperation, currentPromise: CurrentPromise.get(), currentParams: currentParams });
};
export function useAsync(asyncFunction, params, options) {
    return useAsyncInternal(asyncFunction, params, options);
}
export var useAsyncAbortable = function (asyncFunction, params, options) {
    var abortControllerRef = useRef();
    // Wrap the original async function and enhance it with abortion login
    var asyncFunctionWrapper = function () {
        var p = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            p[_i] = arguments[_i];
        }
        return __awaiter(void 0, void 0, void 0, function () {
            var abortController;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Cancel previous async call
                        if (abortControllerRef.current) {
                            abortControllerRef.current.cancel();
                        }
                        abortController = Axios.CancelToken.source();
                        abortControllerRef.current = abortController;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, asyncFunction.apply(void 0, __spreadArrays([abortController], p))];
                    case 2: 
                    // @ts-ignore // TODO how to type this?
                    return [2 /*return*/, _a.sent()];
                    case 3:
                        // Unset abortController ref if response is already there,
                        // as it's not needed anymore to try to abort it (would it be no-op?)
                        if (abortControllerRef.current === abortController) {
                            abortControllerRef.current = undefined;
                        }
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return useAsync(asyncFunctionWrapper, params, options);
};
export var useCancellableAsyncCallback = function (asyncFunction, options) {
    var abortControllerRef = useRef();
    // Wrap the original async function and enhance it with abortion login
    var asyncFunctionWrapper = function () {
        var p = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            p[_i] = arguments[_i];
        }
        return __awaiter(void 0, void 0, void 0, function () {
            var abortController;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        abortController = Axios.CancelToken.source();
                        abortControllerRef.current = abortController;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, asyncFunction.apply(void 0, __spreadArrays([abortController], p))];
                    case 2: 
                    // @ts-ignore // TODO how to type this?
                    return [2 /*return*/, _a.sent()];
                    case 3:
                        // Unset abortController ref if response is already there,
                        // as it's not needed anymore to try to abort it (would it be no-op?)
                        if (abortControllerRef.current === abortController) {
                            abortControllerRef.current = undefined;
                        }
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return useAsyncCallback(asyncFunctionWrapper, options, abortControllerRef.current);
};
export var useAsyncCallback = function (asyncFunction, options, source) {
    return useAsyncInternal(asyncFunction, 
    // Hacky but in such case we don't need the params,
    // because async function is only executed manually
    [], __assign(__assign({}, options), { executeOnMount: false, executeOnUpdate: false }), source);
};
