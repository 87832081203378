import React from "react";
import Text from "./Text";
import Box from "./Box";
var ErrorFallback = function (_a) {
    var componentStack = _a.componentStack, error = _a.error;
    var _b;
    return (React.createElement(Box, { bg: "rgba(255, 0, 0, 0.1)", p: 3 },
        React.createElement(Text, { p: 3, color: "red", fontSize: "sm" }, componentStack ? "Ojdå, något gick fel... Oväntat fel." : (_b = error === null || error === void 0 ? void 0 : error.message) !== null && _b !== void 0 ? _b : error === null || error === void 0 ? void 0 : error.toString())));
};
export default ErrorFallback;
