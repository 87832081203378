var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Spreadsheet from "@skepool/core/src/components/Spreadsheet";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "../../services/TradeService";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import Loader from "@skepool/core/src/components/Loader";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import { VStack } from "@skepool/core/src/components/Layout";
import Button from "@skepool/core/src/components/Button";
import { SWE, sumByProp } from "../../utils";
import Filters from "../../components/Filters";
import InputGroup from "@skepool/core/src/components/InputGroup";
import Select from "@skepool/core/src/components/Select";
import ExternalTradeModal from "../../modals/ExternalTradeModal";
import uniqBy from "lodash-es/uniqBy";
import { groupByConsumptionYear, setPriceToLowest, } from "../HistoricalTrades/HistoricalTradesUG";
export var getUgPriceTypeFromPriceType = function (priceType) {
    switch (priceType) {
        case "buy": {
            return "Ask_sek";
        }
        case "sell": {
            return "Bid_sek";
        }
        case "pricemaster": {
            return "Pricemaster_sek";
        }
    }
};
var ExternalTradesUG = function (_a) {
    var priceType = _a.priceType, accounts = _a.accounts, canChangePrice = _a.canChangePrice;
    var data = useAsync(function () { return TradeService.getHistoricalPricesUG(); }, []);
    var products = data.result && data.result.products;
    var _b = React.useState(null), product = _b[0], setProduct = _b[1];
    var _c = React.useState(accounts[0]), account = _c[0], setAccount = _c[1];
    var trade = useAsyncCallback(TradeService.externalTradeUG);
    var _d = React.useState(null), orderPayload = _d[0], setOrderPayload = _d[1];
    var _e = React.useState(null), transactionIds = _e[0], setTransactionIds = _e[1];
    var productNames = products ? Array.from(new Set(products.map(function (x) { return x.name; }))) : [];
    var productOptions = productNames.map(function (x) { return ({ label: x, value: x }); });
    var _f = React.useState([]), productRows = _f[0], setProductRows = _f[1];
    var ugPriceType = getUgPriceTypeFromPriceType(priceType);
    React.useEffect(function () {
        if (product === null && productOptions.length) {
            setProduct(productOptions[0]);
        }
    }, [productOptions, product]);
    React.useEffect(function () {
        if (product !== null && products) {
            var rows = groupByConsumptionYear(products);
            rows = rows.filter(function (x) { return x.name === product.value; });
            rows = rows.map(setPriceToLowest("YearOfProduction"));
            var newRows = uniqBy(rows, function (x) {
                return x.YearOfConsumption;
            });
            var newProductRows = newRows.map(function (r) {
                var y = r.products.find(function (x) { return x.YearOfProduction === r.YearOfProduction; });
                if (y) {
                    r.YearOfProduction = y.YearOfProduction;
                    r[ugPriceType] = y[ugPriceType];
                }
                return r;
            });
            setProductRows(newProductRows);
        }
    }, [product, ugPriceType, products]);
    if (data.loading) {
        return (React.createElement(Box, { height: 200, width: 630, position: "relative" },
            React.createElement(Loader, null)));
    }
    else if (data.error) {
        return React.createElement(ErrorFallback, { error: data.error });
    }
    if (!data.result) {
        return null;
    }
    var columns = [
        {
            id: "förbrukningsår",
            alignment: "left",
            header: {
                title: "Förbrukningsår",
                subtitle: "",
            },
            format: function (n) { return n.toString(); },
            get: function (r) { return r.YearOfConsumption; },
            set: function () { },
            readonly: true,
        },
        {
            id: "volume",
            alignment: "right",
            header: {
                title: "Antal",
                subtitle: "st",
            },
            get: function (r) { return r.Volume; },
            set: function (r, val) {
                if (val >= 0) {
                    r.Volume = val;
                }
            },
            format: function (n) { return SWE.format(n); },
        },
        {
            id: "price",
            alignment: "right",
            header: {
                title: "Pris",
                subtitle: "SEK / st",
            },
            readonly: !canChangePrice,
            get: function (r) { return r[ugPriceType]; },
            set: function (r, val) {
                r[ugPriceType] = val;
            },
            format: function (n) { return SWE.format(n); },
        },
        {
            id: "produktionsår",
            alignment: "left",
            header: {
                title: "Produktionsår",
                subtitle: "",
            },
            format: function (n) { return n.toFixed(0); },
            get: function (r) { return r.YearOfProduction; },
            set: function (r, val) {
                var y = r.products.find(function (x) { return x.YearOfProduction === val; });
                if (y) {
                    r.YearOfProduction = y.YearOfProduction;
                    r[ugPriceType] = y[ugPriceType];
                }
            },
            options: function (r) {
                return r.products.map(function (x) { return ({ label: x.YearOfProduction.toString(), value: x.YearOfProduction }); });
            },
        },
    ];
    var onCellChange = function (row, column, value) {
        if (productRows) {
            var newRows = __spreadArrays(productRows);
            column.set(newRows[row], value);
            setProductRows(newRows);
        }
    };
    var totals = {
        Volume: sumByProp(productRows, "Volume"),
    };
    var onOrder = function () {
        if (product) {
            setOrderPayload({ Volume: totals.Volume, product: product.value });
        }
    };
    var onOrderClose = function (success) {
        if (trade.loading)
            return;
        if (success) {
            trade
                .execute({
                accountId: Number(account.value),
                rows: productRows
                    .filter(function (x) { return x.Volume > 0; })
                    .map(function (x) { return ({
                    name: x.name,
                    YearOfConsumption: x.YearOfConsumption,
                    YearOfProduction: x.YearOfProduction,
                    Bid_sek: x[ugPriceType],
                    Ask_sek: x[ugPriceType],
                    Pricemaster_sek: x[ugPriceType],
                    ReferenceText: "",
                    TypeOfDelivery: "annullera",
                    BraMiljo: x.BraMiljo ? 1 : 0,
                    DeliveryDate: x.DeliveryDate,
                    Class: x.Class,
                    Volume: x.Volume,
                    FundFee: x.FundFee,
                }); }),
            })
                .then(function (transactionId) {
                if (typeof transactionId === "string") {
                    setTransactionIds([transactionId]);
                }
                else {
                    setTransactionIds(transactionId);
                }
            });
        }
        else {
            setOrderPayload(null);
            setTransactionIds(null);
        }
    };
    return (React.createElement(VStack, { spacing: 2, alignItems: "flex-start" },
        React.createElement(Filters, null,
            React.createElement(InputGroup, null,
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Konto"),
                React.createElement(Select, { name: "account", placeholder: "V\u00E4lj konto", onChange: function (x) { return setAccount(x); }, value: account, options: accounts })),
            React.createElement(InputGroup, null,
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Produkt"),
                React.createElement(Select, { name: "product", options: productOptions, value: product, onChange: function (x) {
                        setProduct(x);
                    } }))),
        React.createElement(Box, { minWidth: "25rem" },
            React.createElement(Spreadsheet.Table, { css: { minWidth: "100%" }, onCellChange: onCellChange, dataColumns: columns, dataRows: productRows }),
            React.createElement(Button, { mt: 4, onClick: onOrder }, "Registrera")),
        orderPayload && (React.createElement(ExternalTradeModal, { loading: trade.loading, error: trade.error, onClose: onOrderClose, transactionIds: transactionIds },
            React.createElement(VStack, { spacing: 1, mt: 2 },
                React.createElement(Text, { fontWeight: "black", fontSize: "sm" }, "Best\u00E4ll"),
                React.createElement(Box, { as: "table", css: { borderCollapse: "collapse" }, minWidth: "100%" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement(Text, { fontWeight: "bold", fontSize: "sm" }, orderPayload.product)),
                            React.createElement("td", null,
                                React.createElement(Text, { fontWeight: "bold", fontSize: "sm" }, SWE.format(orderPayload.Volume) + " st"))))))))));
};
export default ExternalTradesUG;
