import axios from "axios";
var AdminService = {
    disableAccount: function (account) {
        return axios.delete("/api/accounts/" + account.id).then(function (res) { return res.data; });
    },
    getCompanyAccounts: function (withoutMatrix, companyId, cancelToken) {
        return axios
            .get("/api/companies/" + companyId + "/accounts", {
            cancelToken: cancelToken === null || cancelToken === void 0 ? void 0 : cancelToken.token,
            params: { withMatrix: withoutMatrix ? 0 : 1 }
        })
            .then(function (res) { return res.data; });
    },
    enableAccount: function (account) {
        return axios.post("/api/accounts/" + account.id + "/revert").then(function (res) { return res.data; });
    },
    getCompanies: function () {
        return axios.get("/api/companies").then(function (res) { return res.data; });
    },
    deleteParameterset: function (parameterset) {
        return axios.delete("/api/parametersets/" + parameterset.id).then(function (res) { return res.data; });
    },
    deleteProfile: function (profile) {
        return axios.delete("/api/profiles/" + profile.id).then(function (res) { return res.data; });
    }
};
export default AdminService;
