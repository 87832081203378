var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Box from "./Box";
export var PageHeader = function (props) {
    return (React.createElement(Box, __assign({ bg: "offwhite", width: "100%", display: "flex", alignItems: "center", p: 4, mb: "1px", css: {
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)"
        } }, props)));
};
