var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAsyncCallback } from "../hooks/async";
import { isValidSwedishPIN } from "./UserSettingsModal";
import Axios from "axios";
import React from "react";
import Modal, { ModalTitle } from "../components/Modal";
import { VStack, HStack } from "../components/Layout";
import Text from "../components/Text";
import Input from "../components/Input";
import Button from "../components/Button";
export var UserUpdatePersonNumberModal = function (_a) {
    var onClose = _a.onClose;
    var _b = React.useState(""), personnumber = _b[0], setPersonnumber = _b[1];
    var getCurrentUser = useAsyncCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentUser, user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Axios.get("/api/core/current").then(function (res) { return res.data; })];
                case 1:
                    currentUser = _a.sent();
                    return [4 /*yield*/, Axios.get("/api/users/" + currentUser.userId).then(function (res) { return res.data; })];
                case 2:
                    user = _a.sent();
                    return [2 /*return*/, __assign(__assign({}, user), { company: currentUser.company })];
            }
        });
    }); });
    var updateCurrentUser = useAsyncCallback(function (u) { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (u.password === "") {
                        delete u.password;
                    }
                    return [4 /*yield*/, Axios.put("/api/users/" + u.id, u).then(function (res) { return res.data; })];
                case 1:
                    user = _a.sent();
                    return [2 /*return*/, __assign({}, user)];
            }
        });
    }); });
    var onSubmit = function (e) {
        e.preventDefault();
        getCurrentUser.execute().then(function (currentUser) {
            var u = __assign(__assign({}, currentUser), { personnumber: personnumber });
            updateCurrentUser.execute(u).then(function () {
                onClose();
            });
        });
    };
    var error = updateCurrentUser.error || getCurrentUser.error;
    var loading = updateCurrentUser.loading || getCurrentUser.loading;
    return (React.createElement(Modal, { onClose: onClose },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, "F\u00F6rbered ert konto f\u00F6r BankID-inloggning")),
            React.createElement(VStack, { as: "form", spacing: 4, width: "25rem", onSubmit: onSubmit },
                React.createElement(Text, { color: "red" },
                    "Inloggning med l\u00F6senord i Skepool kommer f\u00F6rsvinna November 2024 och av s\u00E4kerhetssk\u00E4l ers\u00E4ttas med enbart BankID.",
                    " "),
                React.createElement(Text, null, " F\u00F6r att logga in med BankID m\u00E5ste du ange personnummer p\u00E5 personen som loggar in:"),
                React.createElement(VStack, { spacing: 2 },
                    React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "personnumber" }, "Personnummer (\u00C5\u00C5\u00C5\u00C5MMDDXXXX)"),
                    React.createElement(Input, { name: "personnumber", value: personnumber, css: {
                            "&:invalid": {
                                borderColor: "red"
                            }
                        }, onChange: function (e) {
                            var val = e.target.value.replace(/\D/g, "");
                            if (isValidSwedishPIN(val) || val === "") {
                                e.target.setCustomValidity("");
                            }
                            else {
                                e.target.setCustomValidity("Ogiltigt personnummer");
                            }
                            setPersonnumber(val);
                        } }),
                    React.createElement(Text.Small, { color: "grey", fontSize: "xs", css: { a: { color: "blue" } } },
                        "Vill du veta mer om hur vi behandlar personuppgifter och cookies kan du l\u00E4sa",
                        " ",
                        React.createElement("a", { href: "https://www.skekraft.se/om-oss/cookies-och-personuppgifter/" }, "h\u00E4r"),
                        ". ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Gallring av dina personuppgifter sker automatiskt om du inte varit inloggad i Skepool under de senaste sex m\u00E5naderna, eller tidigare om s\u00E5 meddelas.")),
                error && (React.createElement(Text, { color: "red", fontSize: "xs" }, error.message)),
                React.createElement(HStack, { alignItems: "center" },
                    React.createElement(Button, { loading: loading, type: "submit" }, "Spara personnummer"),
                    React.createElement(Button, { onClick: function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            onClose();
                        }, type: "submit", variant: "secondary" }, "Inte nu"))))));
};
export var useUserUpdatePersonNumberModal = function () {
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    return {
        UserUpdatePersonNumberModal: isOpen && React.createElement(UserUpdatePersonNumberModal, { onClose: function () { return setIsOpen(false); } }),
        openUpdatePersonNumberModal: function () { return setIsOpen(true); },
        closeUpdatePersonNumberModal: function () { return setIsOpen(false); }
    };
};
