var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Modal, { ModalTitle, ModalCloseButton } from "@skepool/core/src/components/Modal";
import Button from "@skepool/core/src/components/Button";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import Text from "@skepool/core/src/components/Text";
import Datepicker from "@skepool/core/src/components/Datepicker";
import Input, { ControlledNumberInput } from "@skepool/core/src/components/Input";
import format from "date-fns/format";
import { InputUnit } from "@skepool/core/src/components/InputUnit";
import addDays from "date-fns/addDays";
import { useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "../services/TradeService";
import { BankIdMessage } from "./OrderModal";
import { useStatusPoller } from "@skepool/core/src/hooks/useStatusPoller";
import { useImagePoller } from "@skepool/core/src/hooks/useImagePoller";
import Loader from "@skepool/core/src/components/Loader";
import Alert from "@skepool/core/src/components/Alert";
var Label = function (props) { return (React.createElement(Text, __assign({ fontSize: "sm", css: { flexShrink: 0 }, width: 130, as: "label" }, props))); };
var ObserveModal = function (_a) {
    var _b, _c;
    var offerItem = _a.offerItem, onClose = _a.onClose, children = _a.children, error = _a.error, loading = _a.loading, minDate = _a.minDate, maxDate = _a.maxDate, props = __rest(_a, ["offerItem", "onClose", "children", "error", "loading", "minDate", "maxDate"]);
    var id = "Bevaka terminer";
    var _d = React.useState(addDays(new Date(), 1)), validUntil = _d[0], setValidUntil = _d[1];
    var _e = React.useState(0), yearlyVolume = _e[0], setYearlyVolume = _e[1];
    var _f = React.useState(0), minLimit = _f[0], setMinLimit = _f[1];
    var _g = React.useState(0), maxLimit = _g[0], setMaxLimit = _g[1];
    var _h = React.useState(""), message = _h[0], setMessage = _h[1];
    var _j = React.useState(""), password = _j[0], setPassword = _j[1];
    var sign = useAsyncCallback(TradeService.sign);
    var signPassword = useAsyncCallback(TradeService.signPassword);
    var signStatus = useAsyncCallback(TradeService.getSignStatus);
    var _k = React.useState("IDLE"), bankidState = _k[0], setBankIdState = _k[1];
    var _l = React.useState(false), isSameDevice = _l[0], setIsSameDevice = _l[1];
    var qrcode = useImagePoller(TradeService.getSignQRCodeUrl(), bankidState === "POLLING" && !isSameDevice);
    var signid = window.SKEPOOL.USER + ": " + id;
    useStatusPoller(function () { return signStatus.execute(); }, {
        timeout: 30000,
        interval: 1000,
        enabled: bankidState === "POLLING",
        onSuccess: function (status, cancel) {
            if (status.data.SignedStatus === 1) {
                onClose({
                    validUntil: format(validUntil, "yyyy-MM-dd"),
                    yearlyVolume: yearlyVolume,
                    minLimit: minLimit,
                    maxLimit: maxLimit,
                    message: message,
                    offer: offerItem
                });
                setBankIdState("IDLE");
            }
            else if (status.data.ProgressStatusCode === "FAILED") {
                cancel();
                setBankIdState("IDLE");
            }
        },
        onTimeout: function () {
            setBankIdState("IDLE");
        }
    });
    var onSubmit = function (e) {
        var _a, _b;
        e.preventDefault();
        // @ts-ignore
        var id = (_b = (_a = e.nativeEvent) === null || _a === void 0 ? void 0 : _a.submitter) === null || _b === void 0 ? void 0 : _b.id;
        if (id === "same-device") {
            setIsSameDevice(true);
        }
        else {
            setIsSameDevice(false);
        }
        if (window.SKEPOOL.USER_LOGIN_METHOD === "BankID") {
            sign.execute({ signid: signid, signtext: "Bevakning: " + message }).then(function (res) {
                setBankIdState("POLLING");
                if (id === "same-device") {
                    setTimeout(function () {
                        TradeService.openBankId(res.BankIdAutoStartToken);
                    });
                }
            });
        }
        else {
            signPassword.execute({ signid: signid, signtext: "Bevakning: " + message, password: password }).then(function (result) {
                onClose({
                    validUntil: format(validUntil, "yyyy-MM-dd"),
                    yearlyVolume: yearlyVolume,
                    minLimit: minLimit,
                    maxLimit: maxLimit,
                    message: message,
                    offer: offerItem
                });
            });
        }
    };
    var onCancel = function (e) {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        setBankIdState("IDLE");
    };
    var signError = sign.error || signPassword.error;
    var isBankId = window.SKEPOOL.USER_LOGIN_METHOD === "BankID";
    return (React.createElement(Modal, { onClose: function () { return onClose(null); } },
        React.createElement(VStack, { spacing: 3 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, "Bevaka terminer"),
                React.createElement(ModalCloseButton, { onClose: onClose })),
            React.createElement(VStack, { as: "form", onSubmit: onSubmit },
                React.createElement(VStack, { spacing: 3, mt: 2 },
                    React.createElement(HStack, { alignItems: "baseline" },
                        React.createElement(Label, null, "\u00C5rsf\u00F6rbrukning"),
                        React.createElement(Box, { position: "relative", maxWidth: "12rem" },
                            React.createElement(ControlledNumberInput, { pr: "3rem", value: yearlyVolume, onBlur: function (value) { return setYearlyVolume(value); } }),
                            React.createElement(InputUnit, null, "MWh"))),
                    React.createElement(HStack, { alignItems: "baseline" },
                        React.createElement(Label, null, "Terminpris"),
                        React.createElement(Text, { fontSize: "xl", fontWeight: "black" }, offerItem.electricityDerivativePrice.SEK.toFixed(2))),
                    React.createElement(HStack, { alignItems: "center" },
                        React.createElement(Label, null, "Meddela vid pris under"),
                        React.createElement(Box, { position: "relative", maxWidth: "12rem" },
                            React.createElement(ControlledNumberInput, { pr: "5rem", value: minLimit, onBlur: function (value) { return setMinLimit(value); } }),
                            React.createElement(InputUnit, null, "SEK / MWh"))),
                    React.createElement(HStack, { alignItems: "center" },
                        React.createElement(Label, null, "Meddela vid pris \u00F6ver"),
                        React.createElement(Box, { position: "relative", maxWidth: "12rem" },
                            React.createElement(ControlledNumberInput, { pr: "5rem", value: maxLimit, onBlur: function (value) { return setMaxLimit(value); } }),
                            React.createElement(InputUnit, null, "SEK / MWh"))),
                    React.createElement(HStack, { alignItems: "center" },
                        React.createElement(Label, null, "Bevaka till"),
                        React.createElement(Box, { position: "relative", maxWidth: "12rem", flex: 1 },
                            React.createElement(Datepicker, { "data-testid": "validUntil", value: format(validUntil, "yyyy-MM-dd"), css: { ".react-datepicker-wrapper": { width: "100%" } }, onChange: function (d) {
                                    d && setValidUntil(d);
                                } }))),
                    React.createElement(HStack, { alignItems: "start" },
                        React.createElement(Label, null, "Beskrivning"),
                        React.createElement(Input, { as: "textarea", value: message, onChange: function (e) { return setMessage(e.target.value); }, height: "10rem" })),
                    React.createElement(Box, null,
                        React.createElement(Alert, { mb: signError ? 3 : 0, show: signError ? true : false }, signError && signError.message),
                        React.createElement(Alert, { mb: error ? 3 : 0, show: error ? true : false }, ((_b = error) === null || _b === void 0 ? void 0 : _b.rows) ? (_c = error) === null || _c === void 0 ? void 0 : _c.rows : error && error.message)),
                    React.createElement(TradeSignerUI, { label: "Signera bevakning", labelSameDevice: "Signera bevakning med samma enhet", isBankId: isBankId, isSameDevice: isSameDevice, loading: loading || sign.loading || signPassword.loading, password: password, setPassword: setPassword, qrcode: qrcode, bankidState: bankidState, onCancel: onCancel }))))));
};
export var TradeSignerUI = function (_a) {
    var label = _a.label, labelSameDevice = _a.labelSameDevice, password = _a.password, setPassword = _a.setPassword, isBankId = _a.isBankId, loading = _a.loading, bankidState = _a.bankidState, isSameDevice = _a.isSameDevice, qrcode = _a.qrcode, onCancel = _a.onCancel;
    if (isBankId) {
        if (bankidState === "POLLING" && !isSameDevice) {
            return (React.createElement(VStack, { spacing: 3, px: 3 },
                React.createElement(BankIdMessage, { text: { fontSize: ".75rem" } }, "Starta din Bank ID app p\u00E5 din mobil eller surfplatta och skanna QR-koden"),
                React.createElement(Box, { display: "flex", justifyContent: "center" },
                    React.createElement(Box, { maxWidth: 200, as: "img", width: "100%", style: { aspectRatio: 1 }, mt: 3, src: qrcode })),
                React.createElement(Box, { display: "flex", justifyContent: "flex-end" },
                    React.createElement(Button, { mt: 3, onClick: onCancel, width: 100, height: 40, variant: "secondary" }, "Avbryt"))));
        }
        if (bankidState === "POLLING" && isSameDevice) {
            return (React.createElement(VStack, { spacing: 3, px: 3 },
                React.createElement(BankIdMessage, null, "V\u00E4ntar p\u00E5 signering..."),
                React.createElement(Box, { position: "relative", py: 3 },
                    React.createElement(Loader, null)),
                React.createElement(Button, { onClick: onCancel, width: 100, height: 40, variant: "secondary" }, "Avbryt")));
        }
        return (React.createElement(HStack, { justifyContent: "flex-end" },
            React.createElement(Button, { type: "submit", id: "other-device", loading: loading }, label),
            React.createElement(Button, { type: "submit", id: "same-device", loading: loading }, labelSameDevice)));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement(Box, null,
                React.createElement(Text, { display: "block", as: "label", mb: 1, fontSize: "sm", htmlFor: "password" }, "L\u00F6senord"),
                React.createElement(Input, { value: password, onChange: function (e) { return setPassword(e.target.value); }, width: "13rem", type: "password", i: true, id: "password", name: "password" })),
            React.createElement(Box, { display: "flex", justifyContent: "flex-end" },
                React.createElement(Button, { type: "submit", loading: loading }, label))));
    }
};
export default ObserveModal;
