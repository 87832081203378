var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import Filters from "@skepool/handel/src/components/Filters";
import InputGroup from "@skepool/core/src/components/InputGroup";
import { HStack } from "@skepool/core/src/components/Layout";
import Datepicker from "@skepool/core/src/components/Datepicker";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "@skepool/handel/src/services/TradeService";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import Loader from "@skepool/core/src/components/Loader";
import Select from "@skepool/core/src/components/Select";
import AdminService from "@skepool/handel/src/services/AdminService";
import Button from "@skepool/core/src/components/Button";
import { hasPermission, SWE, serialize } from "@skepool/handel/src/utils";
import ReportsService from "../services/ReportsService";
import format from "date-fns/format";
import parse from "date-fns/parse";
import setDate from "date-fns/setDate";
import setMonth from "date-fns/setMonth";
import { useDynamicAccounts, ReportTableRow, ReportSubtitle, ReportTableColumn, ReportTableHeader, ReportTable, CompaniesFilterSelect, } from "./TransactionsPage";
import { Tabs, TabPanels, TabList, TabPanel, Tab } from "@skepool/core/src/components/Tabs";
import ErrorBoundary from "react-error-boundary";
import { PageHeader } from "@skepool/core/src/components/PageHeader";
import { Page } from "@skepool/core/src/components/Page";
import { subYears, isSameQuarter } from "date-fns";
import useLocalStorage from "@skepool/core/src/hooks/useLocalStorage";
import { SavedFilters } from "./MatrixPortfolio";
export var TableArrowRight = function () {
    var size = ".4rem";
    return (React.createElement(Box, { css: {
            display: "inline-block",
            width: 0,
            height: 0,
            mr: 1,
            borderTop: size + " solid transparent",
            borderBottom: size + " solid transparent",
            borderLeft: size + " solid blue",
            borderLeftColor: "blue",
        } }));
};
export var TableArrowDown = function () {
    var size = ".4rem";
    return (React.createElement(Box, { css: {
            display: "inline-block",
            width: 0,
            height: 0,
            mt: "-.2rem",
            borderLeft: size + " solid transparent",
            borderRight: size + " solid transparent",
            borderTop: size + " solid blue",
            borderTopColor: "blue",
        } }));
};
var groupProducts = function (grouping, month, year, quarter) {
    var rows = null;
    if (grouping === "year") {
        rows =
            year && month
                ? year.map(function (x) {
                    var y = __assign(__assign({}, x), { products: month.filter(function (m) { return m.product.year === Number(x.name); }) });
                    return y;
                })
                : null;
    }
    else if (grouping === "quarter") {
        rows =
            quarter && month
                ? quarter.map(function (x) {
                    var y = __assign(__assign({}, x), { products: month.filter(function (m) {
                            var q = parse(x.name, "yyyyQQQ", new Date());
                            var p = parse(m.product.name, "yyyyMM", new Date());
                            return isSameQuarter(q, p);
                        }) });
                    return y;
                })
                : null;
    }
    else if (grouping === "month") {
        rows = month ? month.map(function (x) { return (__assign(__assign({}, x), { products: [] })); }) : null;
    }
    return rows;
};
var HistoricalPricesTable = function (_a) {
    var year = _a.year, quarter = _a.quarter, month = _a.month, total = _a.total;
    var unit = "MWh";
    var currency = "SEK";
    var renderTable = function (grouping) {
        var rows = groupProducts(grouping, month, year, quarter);
        return (React.createElement(ReportTable, null,
            React.createElement("thead", null,
                React.createElement(ReportTableRow, null,
                    React.createElement(ReportTableHeader, { pr: 0 }),
                    React.createElement(ReportTableHeader, { textAlign: "left" },
                        "Produkt",
                        React.createElement(ReportSubtitle, null, "\u00C5r-M\u00E5nad")),
                    React.createElement(ReportTableHeader, null,
                        "S\u00E4krad volym",
                        React.createElement(ReportSubtitle, null, "MWh")),
                    React.createElement(ReportTableHeader, null,
                        "S\u00E4krat pris",
                        React.createElement(ReportSubtitle, null, unit + "/" + currency)),
                    React.createElement(ReportTableHeader, null,
                        "Skepoolpris",
                        React.createElement(ReportSubtitle, null, unit + "/" + currency)),
                    React.createElement(ReportTableHeader, null,
                        "Ej realiserat",
                        React.createElement(ReportSubtitle, null, "" + currency)),
                    React.createElement(ReportTableHeader, null,
                        "Spotpris",
                        React.createElement(ReportSubtitle, null, unit + "/" + currency)),
                    React.createElement(ReportTableHeader, null,
                        "Realiserat",
                        React.createElement(ReportSubtitle, null, "" + currency)),
                    React.createElement(ReportTableHeader, null,
                        "Totalt v\u00E4rde",
                        React.createElement(ReportSubtitle, null, "" + currency)))),
            React.createElement("tbody", null,
                rows && rows.map(function (r) { return React.createElement(SummaryRowDefault, { key: r.name, row: r, currency: currency }); }),
                total.map(function (r) { return (React.createElement(ReportTableRow, { key: r.name },
                    React.createElement(TableColumn, { pr: 0 }),
                    React.createElement(TableColumn, { fontWeight: "bold", textAlign: "left", verticalAlign: "middle" },
                        React.createElement("span", null, "Summa")),
                    React.createElement(TableColumn, { fontWeight: "bold" }, SWE.format(r.assuredVolume)),
                    React.createElement(TableColumn, { fontWeight: "bold" }, SWE.format(r.assuredPrice[currency])),
                    React.createElement(TableColumn, { fontWeight: "bold" }, SWE.format(r.skepoolPrice[currency])),
                    React.createElement(TableColumn, { fontWeight: "bold" }, SWE.format(r.result1[currency])),
                    React.createElement(TableColumn, { fontWeight: "bold" }, SWE.format(r.spotPrice[currency])),
                    React.createElement(TableColumn, { fontWeight: "bold" }, SWE.format(r.result2[currency])),
                    React.createElement(TableColumn, { fontWeight: "bold" }, SWE.format(r.totalCost[currency])))); }))));
    };
    return (React.createElement(Tabs, null,
        React.createElement(TabList, { ml: 4 },
            React.createElement(Tab, null, "\u00C5r"),
            React.createElement(Tab, null, "Kvartal"),
            React.createElement(Tab, null, "M\u00E5nad")),
        React.createElement(TabPanels, null,
            React.createElement(TabPanel, null, renderTable("year")),
            React.createElement(TabPanel, null, renderTable("quarter")),
            React.createElement(TabPanel, null, renderTable("month")))));
};
var SummaryRowDefault = function (_a) {
    var r = _a.row, currency = _a.currency;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(ReportTableRow, { key: r.name, onClick: function () { return setOpen(!open); }, css: { cursor: "pointer", "&:hover": { bg: "verylightblue" } } },
            React.createElement(TableColumn, { pr: 0 }, r.products.length ? open ? React.createElement(TableArrowDown, null) : React.createElement(TableArrowRight, null) : null),
            React.createElement(TableColumn, { "data-testid": "product-group", textAlign: "left", verticalAlign: "middle" },
                React.createElement("span", null, r.name)),
            React.createElement(TableColumn, null, SWE.format(r.assuredVolume)),
            React.createElement(TableColumn, null, SWE.format(r.assuredPrice[currency])),
            React.createElement(TableColumn, null, SWE.format(r.skepoolPrice[currency])),
            React.createElement(TableColumn, null, SWE.format(r.result1[currency])),
            React.createElement(TableColumn, null, SWE.format(r.spotPrice[currency])),
            React.createElement(TableColumn, null, SWE.format(r.result2[currency])),
            React.createElement(TableColumn, null, SWE.format(r.totalCost[currency]))),
        open &&
            r.products.map(function (m) { return (React.createElement(ReportTableRow, { key: m.name, bg: "offwhite" },
                React.createElement(TableColumn, { pr: 0 }),
                React.createElement(TableColumn, { "data-testid": "product", textAlign: "left" }, format(parse(m.name, "yyyyMM", new Date()), "yyyy-MM")),
                React.createElement(TableColumn, null, SWE.format(m.assuredVolume)),
                React.createElement(TableColumn, null, SWE.format(m.assuredPrice[currency])),
                React.createElement(TableColumn, null, SWE.format(m.skepoolPrice[currency])),
                React.createElement(TableColumn, null, SWE.format(m.result1[currency])),
                React.createElement(TableColumn, null, SWE.format(m.spotPrice[currency])),
                React.createElement(TableColumn, null, SWE.format(m.result2[currency])),
                React.createElement(TableColumn, null, SWE.format(m.totalCost[currency])))); })));
};
var TableColumn = function (props) { return React.createElement(ReportTableColumn, __assign({ fontSize: "sm" }, props)); };
export var useCompaniesFilter = function (isAdmin, currentUser, companiesData, filter, resetFilter) {
    var companyOptions = __spreadArrays([
        { label: "Alla", value: -1, isFixed: true, enabled: true },
        { label: "Alla aktiva företag", value: -2, isFixed: false, enabled: true }
    ], (companiesData
        ? companiesData
            .filter(function (x) { return !x.omitInAdminPortfolio; })
            .map(function (x) { return ({ label: x.name, value: x.id, isFixed: false, enabled: x.enabled }); })
        : []));
    var defaultCompany = companyOptions[0];
    var allActiveCompaniesOption = companyOptions[1];
    var _a = useLocalStorage("ui-reports-historicalprices-companies", [defaultCompany]), companies = _a[0], setCompanies = _a[1];
    var allCompaniesSelected = JSON.stringify(companies) === JSON.stringify([defaultCompany]);
    var allActiveCompaniesSelected = JSON.stringify(companies) === JSON.stringify([allActiveCompaniesOption]);
    var getCompanyIds = function () {
        var companyIds = companies.filter(function (x) { return x.value !== defaultCompany.value; }).map(function (x) { return x.value; });
        if (isAdmin && companiesData) {
            if (companyIds.length && !allActiveCompaniesSelected) {
                return { companyIds: companyIds.join(",") };
            }
            else if (allActiveCompaniesSelected) {
                return {
                    companyIds: companyOptions
                        .filter(function (x) { return x.value !== -2 && x.value !== -1 && x.enabled; })
                        .map(function (x) { return x.value; })
                        .join(","),
                };
            }
        }
        if (isAdmin && allCompaniesSelected) {
            return {
                companyIds: companyOptions
                    .filter(function (x) { return x.value !== -2 && x.value !== -1; })
                    .map(function (x) { return x.value; })
                    .join(","),
            };
        }
        return {
            companyIds: currentUser.company.id + "",
        };
    };
    var onCompaniesChange = function (x, meta) {
        if (meta.action === "clear" || !x || meta.option === defaultCompany) {
            setCompanies([defaultCompany]);
        }
        else if (meta.option === allActiveCompaniesOption) {
            setCompanies(companyOptions.filter(function (x) { return x.enabled && x.value !== -1 && x.value !== -2; }));
        }
        else {
            if (allActiveCompaniesSelected) {
                setCompanies(x.filter(function (y) { return y.value !== -1 && y.value !== -2; }));
            }
            else {
                setCompanies(x.filter(function (y) { return y.value !== -1; }));
            }
        }
        if (resetFilter) {
            resetFilter();
        }
    };
    React.useEffect(function () {
        var _a;
        var filterCompanies = (_a = filter === null || filter === void 0 ? void 0 : filter.values.companyId.split(",").filter(function (x) { return x !== ""; }).map(function (x) { return Number(x); })) !== null && _a !== void 0 ? _a : [];
        if (filterCompanies.length && companyOptions.length > 2) {
            var newCompanies = companyOptions.filter(function (x) { return x.value !== -1 && x.value !== -2 && filterCompanies.indexOf(x.value) !== -1; });
            setCompanies(newCompanies);
        }
    }, [filter === null || filter === void 0 ? void 0 : filter.values.companyId, JSON.stringify(companyOptions)]);
    return {
        companies: companies,
        companyOptions: companyOptions,
        setCompanies: setCompanies,
        allCompaniesSelected: allCompaniesSelected,
        allActiveCompaniesSelected: allActiveCompaniesSelected,
        getCompanyIds: getCompanyIds,
        onCompaniesChange: onCompaniesChange,
    };
};
var HistoricalPrices = function (_a) {
    var areas = _a.areas, companiesData = _a.companies, userAccounts = _a.accounts, currentUser = _a.currentUser, filters = _a.filters, onFiltersChange = _a.onFiltersChange;
    var getSummary = useAsyncCallback(ReportsService.getHistoricalPricesReport);
    var isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
    var isSysAdmin = hasPermission("USER_IS_SYSADMIN", currentUser.permissions);
    var _b = React.useState(null), filter = _b[0], setFilter = _b[1];
    var currentFilter = filters && filter ? filters.find(function (x) { return x.name === filter.label; }) : undefined;
    var _c = useCompaniesFilter(isAdmin, currentUser, companiesData, currentFilter), companies = _c.companies, companyOptions = _c.companyOptions, setCompanies = _c.setCompanies, allCompaniesSelected = _c.allCompaniesSelected, allActiveCompaniesSelected = _c.allActiveCompaniesSelected, getCompanyIds = _c.getCompanyIds, onCompaniesChange = _c.onCompaniesChange;
    var _d = useDynamicAccounts(companies, allCompaniesSelected, allActiveCompaniesSelected, currentUser, userAccounts), accountOptions = _d.accountOptions, accounts = _d.accounts, accountsLoading = _d.accountsLoading, setAccounts = _d.setAccounts, defaultAccount = _d.defaultAccount, accountsData = _d.accountsData;
    var isAccountsDisabled = isAdmin
        ? allCompaniesSelected || allActiveCompaniesSelected || accountsLoading || companies.length > 1
        : accountsLoading;
    var areaOptions = [{ label: "Alla", value: -1 }].concat(areas.map(function (x) { return ({ label: x.name, value: x.id }); }));
    var defaultArea = areaOptions[0];
    var currencyOptions = [
        { value: "sek", label: "SEK" },
        { value: "eur", label: "EUR" },
    ];
    var defaultCurrency = currencyOptions[0];
    var tradeTypes = [
        {
            value: -1,
            label: "Alla",
        },
        {
            value: 2,
            label: "Extern handel",
        },
    ];
    if (isSysAdmin) {
        tradeTypes.push({
            value: 1,
            label: "Historisk handel",
        });
        tradeTypes.push({
            value: 0,
            label: "Vanlig + historisk",
        });
    }
    var defaultTradeType = isSysAdmin ? tradeTypes[tradeTypes.length - 1] : tradeTypes[0];
    var defaultFrom = setDate(setMonth(new Date(), 0), 1);
    var _e = React.useState(defaultFrom), from = _e[0], setFrom = _e[1];
    var _f = React.useState(setMonth(defaultFrom, 11)), to = _f[0], setTo = _f[1];
    var _g = React.useState(setDate(setMonth(subYears(new Date(), 1), 11), 30)), validation = _g[0], setValidation = _g[1];
    var _h = React.useState(defaultArea), area = _h[0], setArea = _h[1];
    var _j = React.useState(defaultCurrency), currency = _j[0], setCurrency = _j[1];
    var _k = React.useState(defaultTradeType), tradeType = _k[0], setTradeType = _k[1];
    var defaultParams = __assign({ from: format(from, "yyyy-MM"), to: format(to, "yyyy-MM"), area: area.value === -1 ? undefined : area.value, grouped: "year,quarter,month,total", iHandelstyp: tradeType.value, validationDate: format(validation, "yyyy-MM-dd"), accountIds: accounts
            .filter(function (x) { return x.value !== -1; })
            .map(function (x) { return x.value; })
            .join(",") }, getCompanyIds());
    var onUpdate = function () {
        getSummary.execute(defaultParams);
    };
    var getExcelLink = function () {
        return "/api/rapport/historical-prices/electricity-derivative/export/excel" + serialize(defaultParams);
    };
    var error = getSummary.error;
    var loading = getSummary.loading;
    var summary = getSummary.result;
    React.useEffect(function () {
        getSummary.reset();
    }, [currency]);
    return (React.createElement(Box, { px: 4, py: 3 },
        React.createElement(Filters, null,
            React.createElement(CompaniesFilterSelect, { isAdmin: isAdmin, companies: companies, companyOptions: companyOptions, onCompaniesChange: onCompaniesChange }),
            React.createElement(InputGroup, { width: "auto", maxWidth: 400, minWidth: ["100%", 224] },
                React.createElement(Text, { as: "label", fontSize: "sm", style: { opacity: isAccountsDisabled ? 0.5 : 1 } }, "Konton"),
                React.createElement(Select, { name: "accounts", disabled: isAccountsDisabled, isMulti: true, height: "auto", closeMenuOnSelect: false, options: [
                        { label: "", options: accountOptions.filter(function (x) { return x.value === -1 || x.value === -2; }) },
                        {
                            label: "Konton",
                            options: accountOptions.filter(function (x) { return !x.matris && x.value !== -1 && x.value !== -2; }),
                        },
                        {
                            label: "Matriskonton",
                            options: accountOptions.filter(function (x) { return x.matris && x.value !== -1 && x.value !== -2; }),
                        },
                    ], value: accounts, isClearable: !accounts.some(function (x) { return x.value === -1; }), onChange: function (x, meta) {
                        if (meta.action === "clear" || !x || meta.option === defaultAccount) {
                            setAccounts([defaultAccount]);
                        }
                        else if (meta.option && meta.option.value === -2) {
                            setAccounts(accountOptions.filter(function (x) { return x.active && x.value !== -1 && x.value !== -2; }));
                        }
                        else {
                            setAccounts(x.filter(function (y) { return y.value !== -1 && x.value !== -2; }));
                        }
                    } })),
            React.createElement(InputGroup, { width: ["100%", 132] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Handelstyp"),
                React.createElement(Select, { name: "tradeType", options: tradeTypes, value: tradeType, onChange: function (x) { return setTradeType(x); } })),
            React.createElement(InputGroup, { width: ["100%", 210] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Period"),
                React.createElement(HStack, null,
                    React.createElement(Datepicker, { "data-testid": "from", selected: from, dateFormat: "yyyy-MM", showMonthYearPicker: true, onChange: function (d) {
                            d && setFrom(d);
                        } }),
                    React.createElement(Text, { as: "span" }, "\u2013"),
                    React.createElement(Datepicker, { "data-testid": "to", selected: to, dateFormat: "yyyy-MM", showMonthYearPicker: true, onChange: function (d) {
                            d && setTo(d);
                        } }))),
            React.createElement(InputGroup, { width: ["100%", 150] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "V\u00E4rderingsdatum"),
                React.createElement(Datepicker, { "data-testid": "validation", selected: validation, dateFormat: "yyyy-MM-dd", onChange: function (d) {
                        d && setValidation(d);
                    } })),
            React.createElement(InputGroup, { width: ["100%", 132] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Elomr\u00E5de"),
                React.createElement(Select, { name: "area", options: areaOptions, value: area, onChange: function (x) { return setArea(x); } })),
            React.createElement(SavedFilters, { currentUser: currentUser, accountsData: accountsData, companiesData: companiesData, onFiltersChange: onFiltersChange, accounts: accounts, companies: companies, filters: filters, filter: filter, setFilter: setFilter, getCompanyIds: getCompanyIds })),
        React.createElement(Filters, null,
            React.createElement(InputGroup, { justifyContent: "flex-end", width: "auto" },
                React.createElement(HStack, { spacing: 3 },
                    React.createElement(Button, { px: 4, py: 2, loading: loading, onClick: onUpdate }, "H\u00E4mta historiska priser"),
                    React.createElement(Button, { px: 4, py: 2, variant: "secondary", as: "a", href: getExcelLink(), target: "_blank", rel: "noopener" }, "H\u00E4mta excel")))),
        error && React.createElement(ErrorFallback, { error: error }),
        React.createElement(Box, { mt: 4, mb: 4 }, summary && React.createElement(HistoricalPricesTable, __assign({}, summary)))));
};
var Service = {
    getConfig: function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentUser, isAdmin, promises, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TradeService.getCurrentUser()];
                case 1:
                    currentUser = _a.sent();
                    isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
                    promises = __spreadArrays([
                        TradeService.getAreas(),
                        ReportsService.getFilterSettings("historiskapriser")
                    ], (isAdmin ? [AdminService.getCompanies()] : []), (!isAdmin ? [TradeService.getAccounts()] : []));
                    return [4 /*yield*/, Promise.all(promises)];
                case 2:
                    result = _a.sent();
                    return [2 /*return*/, {
                            currentUser: currentUser,
                            areas: result[0],
                            userSettings: result[1],
                            companies: result[2],
                            accounts: result[2],
                        }];
            }
        });
    }); },
};
var HistoricalPricesSkeleton = function (_a) {
    var children = _a.children;
    return (React.createElement(Box, null,
        React.createElement(PageHeader, null,
            React.createElement(Text, { fontSize: "xl", fontWeight: "black" }, "Historiska priser")),
        children));
};
var HistoricalPricesPage = function () {
    var data = useAsync(function () { return Service.getConfig(); }, []);
    var onFilterChange = function (u) {
        if (data.result) {
            data.setResult(__assign(__assign({}, data.result), { userSettings: u }));
        }
    };
    if (data.loading) {
        return (React.createElement(HistoricalPricesSkeleton, null,
            React.createElement(Box, { height: 200, position: "relative" },
                React.createElement(Loader, null))));
    }
    if (data.error) {
        return (React.createElement(HistoricalPricesSkeleton, null,
            React.createElement(ErrorFallback, { error: data.error })));
    }
    var res = data.result;
    if (!res) {
        return null;
    }
    return (React.createElement(HistoricalPricesSkeleton, null,
        React.createElement(Page, null,
            React.createElement(ErrorBoundary, { FallbackComponent: ErrorFallback },
                React.createElement(Box, { minHeight: "calc(100vh - 100px)" },
                    React.createElement(HistoricalPrices, { areas: res.areas, companies: res.companies, accounts: res.accounts, currentUser: res.currentUser, filters: res.userSettings.filters, onFiltersChange: onFilterChange }))))));
};
export default HistoricalPricesPage;
