import dlv from "dlv";
import sortBy from "lodash/sortBy";
import React from "react";
export function useDidUpdateEffect(fn, inputs) {
    var didMountRef = React.useRef(false);
    React.useEffect(function () {
        if (didMountRef.current)
            fn();
        else
            didMountRef.current = true;
    }, inputs);
}
export var priceTotal = function (arr, valueColumn, volumeColumn) {
    var totalValue = sumColumn(arr, valueColumn, true);
    var totalVolume = sumColumn(arr, volumeColumn, true);
    return totalValue === 0 && totalVolume === 0 ? 0 : totalValue / totalVolume;
};
export var sumColumn = function (arr, column, abs) {
    if (abs === void 0) { abs = false; }
    return arr.reduce(function (total, row) {
        var value = column.get(row);
        return total + (abs ? Math.abs(value) : value);
    }, 0);
};
export var sumByProp = function (arr, prop, abs) {
    if (abs === void 0) { abs = false; }
    return arr.reduce(function (total, row) {
        var value = dlv(row, prop, 0);
        return total + (abs ? Math.abs(value) : value);
    }, 0);
};
export function serialize(obj) {
    return ("?" +
        Object.keys(obj)
            .reduce(function (a, k) {
            var v = encodeURIComponent(obj[k]);
            if (v !== "" && v !== "undefined") {
                a.push(k + "=" + v);
            }
            return a;
        }, [])
            .join("&"));
}
export var getAreaNiceName = function (area) {
    if (area.id === 5) {
        return area.name;
    }
    else {
        return "SE" + area.id;
    }
};
export var SWE = new Intl.NumberFormat("sv-SV", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});
export var SWE4 = new Intl.NumberFormat("sv-SV", {
    style: "decimal",
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
});
export function hasPermission(perm, permissions) {
    return permissions[perm] ? true : false;
}
export var groupMatrixProfiles = function (profilesData) {
    var customerProfiles = sortBy(profilesData.filter(function (x) { return x.iProfileType === "1"; }).map(function (x) { return ({ label: x.ProfileName, value: x.ID }); }), "label");
    var templateProfiles = sortBy(profilesData.filter(function (x) { return x.iProfileType === "0"; }).map(function (x) { return ({ label: x.ProfileName, value: x.ID }); }), "label");
    var branchProfiles = sortBy(profilesData.filter(function (x) { return x.iProfileType === "2"; }).map(function (x) { return ({ label: x.ProfileName, value: x.ID }); }), "label");
    var profiles = [
        {
            label: "Schablonprofiler",
            options: templateProfiles
        },
        {
            label: "Kundprofiler",
            options: customerProfiles
        },
        {
            label: "Branchprofiler",
            options: branchProfiles
        }
    ];
    return profiles;
};
export var groupProfiles = function (profilesData) {
    var templateProfiles = sortBy(profilesData.filter(function (x) { return x.profileType === 0; }).map(function (x) { return ({ label: x.profileName, value: x.id }); }), "label");
    var customerProfiles = sortBy(profilesData.filter(function (x) { return x.profileType === 1; }).map(function (x) { return ({ label: x.profileName, value: x.id }); }), "label");
    var tradeProfiles = sortBy(profilesData.filter(function (x) { return x.profileType === 2; }).map(function (x) { return ({ label: x.profileName, value: x.id }); }), "label");
    var profiles = [
        {
            label: "Branschprofiler",
            options: tradeProfiles
        },
        {
            label: "Schablonprofiler",
            options: templateProfiles
        },
        {
            label: "Kundprofiler",
            options: customerProfiles
        }
    ];
    return profiles;
};
export var calculateRows = function (rows, useQuotas, quotas) {
    return rows.map(function (r) {
        if (useQuotas) {
            var quota = quotas.find(function (x) { return x.year === r.product.year; });
            if (quota) {
                r.electricityCertificateVolume = r.electricityDerivativeVolume * quota.electricityCertificateQuota;
            }
        }
        var derivative_type = r.electricityDerivativeVolume < 0 ? r.sell : r.buy;
        var certificate_type = r.electricityCertificateVolume < 0 ? r.sell : r.buy;
        r.electricityDerivativeValue = derivative_type.electricityDerivativePrice.SEK * r.electricityDerivativeVolume;
        r.electricityCertificateValue =
            certificate_type.electricityCertificatePrice.SEK * r.electricityCertificateVolume;
        return r;
    });
};
