var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Loader from "./Loader";
import Box from "./Box";
import { MoreInfoIcon } from "./Icons";
var variants = {
    touchable: {
        bg: "transparent",
        height: "auto",
        color: "black",
        lineHeight: "normal",
        border: "none"
    },
    secondary: {
        bg: "white",
        color: "blue",
        borderColor: "blue"
    }
};
var Button = function (_a) {
    var children = _a.children, variant = _a.variant, disabled = _a.disabled, loading = _a.loading, css = _a.css, props = __rest(_a, ["children", "variant", "disabled", "loading", "css"]);
    var v = variant ? variants[variant] : null;
    return (React.createElement(Box, __assign({ as: "button", px: 3, lineHeight: "1.5", m: 0, minHeight: "2rem", fontSize: "sm", overflow: "hidden", fontFamily: '"Sk Sans"', border: 2, borderRadius: "2rem", display: "inline-flex", alignItems: "center", justifyContent: "center", position: "relative", borderColor: "blue", bg: "blue", color: "white", disabled: disabled || loading, css: __assign({ transition: [
                "0.2s opacity ease",
                "0.2s background ease",
                "0.2s border-color ease",
                "0.2s color ease"
            ].join(","), pointerEvents: disabled || loading ? "none" : undefined, opacity: disabled ? 0.6 : undefined, textDecoration: "none", backfaceVisibility: "hidden", cursor: "pointer", "&:active": {
                opacity: 0.5,
                transform: "translateY(1px)"
            }, "&:hover": {
                opacity: 0.8
            } }, css) }, v, props),
        React.createElement(React.Fragment, null,
            loading && (React.createElement(Loader, { "data-testid": "loader", bg: v ? v.bg : props.bg || "blue", color: v ? v.color : props.color || "white", size: 16 })),
            children)));
};
export var Touchable = function (props) { return (React.createElement(Button, __assign({ minHeight: 0, bg: "transparent", height: "auto", color: "black", border: "none" }, props))); };
export var MoreInfoButton = function (_a) {
    var css = _a.css, children = _a.children, props = __rest(_a, ["css", "children"]);
    return (React.createElement(Touchable, __assign({ px: 0, py: 0, color: "grey", css: __assign({ "&:hover": { color: "blue" } }, css) }, props),
        React.createElement(MoreInfoIcon, null),
        children));
};
export default Button;
