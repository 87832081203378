var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "./Box";
export var VStack = function (_a) {
    var _b = _a.spacing, spacing = _b === void 0 ? 3 : _b, _c = _a.debug, debug = _c === void 0 ? false : _c, css = _a.css, props = __rest(_a, ["spacing", "debug", "css"]);
    return (React.createElement(Box, __assign({ className: "VStack", display: "flex", alignSelf: "stretch", flex: "1 1 auto", flexDirection: "column", css: __assign(__assign(__assign({}, (debug
            ? {
                "> *": {
                    outline: "1px solid #357edd !important"
                }
            }
            : {})), { "> *:not(:last-child)": {
                mb: spacing
            }, "> .Divider": {
                height: "1px"
            } }), css) }, props)));
};
export var HStack = function (_a) {
    var _b = _a.spacing, spacing = _b === void 0 ? 3 : _b, _c = _a.debug, debug = _c === void 0 ? false : _c, css = _a.css, props = __rest(_a, ["spacing", "debug", "css"]);
    return (React.createElement(Box, __assign({ className: "HStack", display: "flex", alignSelf: "stretch", alignItems: "center", flexDirection: "row", css: __assign(__assign(__assign({}, (debug
            ? {
                "> *": {
                    outline: "1px solid #357edd !important"
                }
            }
            : {})), { "> *:not(:last-child)": {
                mr: spacing
            }, "> .Divider": {
                width: "1px"
            } }), css) }, props)));
};
export var Spacer = function (props) { return React.createElement(Box, __assign({ className: "Spacer", flex: "1" }, props)); };
export var Divider = function (props) { return (React.createElement(Box, __assign({ className: "Divider", bg: "lightgray", alignSelf: "stretch" }, props))); };
VStack.displayName = "VStack";
HStack.displayName = "HStack";
Spacer.displayName = "Spacer";
Divider.displayName = "Divider";
