var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "./Box";
export var Close = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: "1.5rem", height: "1.5rem", viewBox: "0 0 24 24" }, props),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { cx: 12, cy: 12, r: 12, fill: "#00A9E0" }),
        React.createElement("path", { fill: "#FFF", fillRule: "nonzero", d: "M6.59 16.454l4.297-4.296L6.579 7.85l1.334-1.334 4.308 4.308 4.284-4.284 1.37 1.37-4.284 4.284 4.308 4.308-1.334 1.334-4.308-4.308-4.296 4.296z" })))); };
export var Locked = function (props) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", style: { width: "0.875rem", height: "0.875rem" }, fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", strokeWidth: 2 },
        React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" })));
};
export var Delete = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 12, height: 12, viewBox: "0 0 12 12", fill: "none", color: "grey" }, props),
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { clipRule: "evenodd", d: "M0 0h12v12H0V0z", stroke: "currentColor" }),
        React.createElement("path", { d: "M3.429 9.143a.86.86 0 00.857.857h3.428a.86.86 0 00.857-.857V4H3.43v5.143zM9 2.428H7.5L7.072 2H4.928l-.428.428H3v.857h6v-.857z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("path", { fill: "#fff", d: "M0 0H12V12H0z" }))))); };
export var Add = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 80, height: 80, color: "midgrey", viewBox: "0 0 80 80" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M44 20h-8v16H20v8h16v16h8V44h16v-8H44V20zM40 0C18 0 0 18 0 40s18 40 40 40 40-18 40-40S62 0 40 0zm0 72C22.4 72 8 57.6 8 40S22.4 8 40 8s32 14.4 32 32-14.4 32-32 32z" }))); };
export var Checkmark = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 16, height: 12 }, props),
    React.createElement("path", { fill: "#FFF", fillRule: "evenodd", d: "M5.48 11.4L0 5.92l1.535-1.535L5.48 8.33 13.81 0l1.535 1.535z" }))); };
export var NavigateBefore = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "black" : _b, props = __rest(_a, ["color"]);
    return (React.createElement(Box, __assign({ as: "svg", viewBox: "0 0 24 24", width: "18px", height: "18px" }, props),
        React.createElement("path", { d: "M0 0h24v24H0z", fill: "none" }),
        React.createElement("path", { d: "M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z", fill: "currentColor" })));
};
export var NavigateNext = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "black" : _b, props = __rest(_a, ["color"]);
    return (React.createElement(Box, __assign({ as: "svg", viewBox: "0 0 24 24", width: "18px", height: "18px" }, props),
        React.createElement("path", { d: "M0 0h24v24H0z", fill: "none" }),
        React.createElement("path", { d: "M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z", fill: "currentColor" })));
};
export var ArrowRight = function (props) { return (React.createElement(Box, { css: __assign({ width: 0, height: 0, borderTop: "0.5rem solid transparent", borderBottom: "0.5rem solid transparent", borderLeft: "0.5rem solid", borderLeftColor: "blue" }, props.css) })); };
export var Refresh = function (props) { return (React.createElement(Box, __assign({ as: "svg", color: "grey", width: 20, height: 20, viewBox: "0 0 20 20" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M17 3c-1.75-1.875-4.25-3-7-3C4.5 0 0 4.5 0 10s4.5 10 10 10c4.625 0 8.5-3.25 9.625-7.5H17c-1 2.875-3.75 5-7 5-4.125 0-7.5-3.375-7.5-7.5S5.875 2.5 10 2.5c2.125 0 3.875.875 5.25 2.25l-4 4H20V0l-3 3z" }))); };
export var Info = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 20, color: "blue", height: 20, viewBox: "0 0 20 20" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M9 15h2V9H9v6zm1-15C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM9 7h2V5H9v2z" }))); };
export var EditModalIcon = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 12, height: 12, color: "grey", viewBox: "0 0 12 12" }, props),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { fill: "currentColor", d: "M6 4.733V6h1.267l3.666-3.7-1.266-1.267L6 4.733zm5.9-3.4a.322.322 0 000-.466L11.133.1a.322.322 0 00-.466 0l-.6.6 1.266 1.267.567-.634z" }),
        React.createElement("path", { stroke: "currentColor", d: "M12 5.186V12H0V0h7.097" })))); };
export var AddModalIcon = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 12, height: 12, color: "grey", viewBox: "0 0 12 12" }, props),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { stroke: "currentColor", d: "M0 0h12v12H0z" }),
        React.createElement("path", { fill: "currentColor", d: "M3 5h6v2H3z" }),
        React.createElement("path", { fill: "currentColor", d: "M5 3h2v6H5z" })))); };
export var MoreInfoIcon = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 16, height: 16, viewBox: "0 0 16 16" }, props),
    React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M13.657 2.343c-3.111-3.111-8.203-3.111-11.314 0-3.111 3.111-3.111 8.203 0 11.314 3.111 3.111 8.203 3.111 11.314 0 3.111-3.111 3.111-8.203 0-11.314zm-7.071.849h3.96v3.96L9.13 5.736 6.87 8 5.737 6.869 8 4.606 6.586 3.192zm2.828 9.616h-3.96v-3.96l1.415 1.415L9.13 8l1.132 1.131L8 11.394l1.414 1.414z" }))); };
export var PDFIcon = function (props) { return (React.createElement(Box, __assign({ as: "svg", width: 16, height: 18, viewBox: "0 0 16 18" }, props),
    React.createElement("g", { fill: "none" },
        React.createElement("path", { fill: "#333", d: "M14.99 3.506L12.44.994C11.881.448 10.785 0 10 0H1.429C.643 0 0 .633 0 1.406v15.188C0 17.367.643 18 1.429 18H14.57c.786 0 1.429-.633 1.429-1.406V5.906c0-.773-.454-1.852-1.01-2.4z" }),
        React.createElement("path", { fill: "#FFF", d: "M14.857 16.594a.29.29 0 01-.286.281H1.43a.288.288 0 01-.286-.281V1.406c0-.152.13-.281.286-.281H10c.086 0 .182.01.286.03v4.472h4.539c.021.102.032.197.032.281v10.688-.002z" }),
        React.createElement("path", { fill: "#333", d: "M13.66 11.137c-.074.046-.289.074-.424.074-.443 0-.986-.2-1.754-.524.297-.02.564-.031.807-.031.443 0 .572 0 1.007.109.433.105.436.327.365.372zm-7.681.067c.171-.295.346-.608.525-.942.435-.812.714-1.452.917-1.976a6.62 6.62 0 001.518 1.857c.075.063.154.123.24.186-1.218.239-2.272.527-3.2.875zM7.4 3.51c.243 0 .382.6.393 1.165.01.563-.122.957-.29 1.252C7.365 5.49 7.3 4.808 7.3 4.36c0 0-.01-.852.1-.852zm-4.764 10.8c.139-.37.682-1.1 1.485-1.751.05-.039.175-.155.29-.26-.84 1.322-1.404 1.845-1.775 2.01zm11.257-3.948c-.243-.236-.786-.359-1.607-.37a13.698 13.698 0 00-1.932.138c-.315-.18-.64-.373-.897-.609C8.771 8.887 8.2 8.012 7.843 7.05c.021-.092.043-.169.06-.25 0 0 .386-2.162.283-2.893a.772.772 0 00-.05-.208l-.032-.087c-.104-.238-.311-.491-.636-.478l-.19-.006h-.003c-.36 0-.657.182-.732.452-.236.854.007 2.127.446 3.776l-.114.27c-.314.753-.707 1.512-1.054 2.18l-.046.088c-.364.703-.696 1.3-.996 1.807l-.311.162c-.022.014-.554.288-.679.362-1.057.622-1.76 1.329-1.876 1.891-.037.176-.01.405.179.514l.3.147a.93.93 0 00.408.095c.754 0 1.629-.921 2.832-2.992A28.542 28.542 0 0110 10.856c1.057.587 2.357.995 3.179.995.146 0 .271-.014.375-.042a.617.617 0 00.371-.25c.157-.235.193-.559.146-.893-.01-.098-.092-.221-.178-.305z" }),
        React.createElement("path", { fill: "#FFF", d: "M14.182 4.3c.057.056.11.123.164.2H11.43V1.627c.078.053.146.108.203.163l2.55 2.51z" })))); };
