var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Modal, { ModalTitle, ModalCloseButton } from "@skepool/core/src/components/Modal";
import Button from "@skepool/core/src/components/Button";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import Text from "@skepool/core/src/components/Text";
import Input, { Checkbox } from "@skepool/core/src/components/Input";
import { useAsyncCallback, useAsync } from "@skepool/core/src/hooks/async";
import Axios from "axios";
import Loader from "../components/Loader";
export var NotificationSettingsModal = function (_a) {
    var onClose = _a.onClose;
    var getNotificationSettings = useAsync(function () {
        return Axios.get("/api/notifications/config").then(function (res) { return res.data.config; });
    }, []);
    var updateNotificationSettings = useAsyncCallback(function (n) {
        return Axios.put("/api/notifications/config", n).then(function (res) { return res.data.config; });
    });
    var configNames = {
        "1": "När det kommer ett nytt webinar",
        "2": "När det kommer ett nytt veckobrev",
        "3": "När det kommer ett nytt VD-brev",
        "4": "När det kommer ett nytt kundtjänstbrev",
        "5": "När det kommer ett nytt slutkundsbrev",
        "6": "När det kommer en ny marknadskommentar",
        "20": "Vid inlägg i forumet",
        "30": "När det kommer ett nytt portalmeddelande i Skepool"
    };
    var notificationSettings = getNotificationSettings.result;
    var onSubmit = function (e) {
        e.preventDefault();
        if (notificationSettings) {
            updateNotificationSettings.execute({ config: notificationSettings }).then(function () {
                onClose();
            });
        }
    };
    var onChange = function (n) {
        getNotificationSettings.setResult(n);
    };
    return (React.createElement(Modal, { onClose: onClose },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, "Notisinst\u00E4llningar"),
                React.createElement(ModalCloseButton, { onClose: onClose })),
            getNotificationSettings.loading && (React.createElement(Box, { height: 294, position: "relative" },
                React.createElement(Loader, null))),
            getNotificationSettings.error && React.createElement(Text, { color: "red" }, getNotificationSettings.error.message),
            notificationSettings && (React.createElement(VStack, { as: "form", spacing: 4, width: "25rem", onSubmit: onSubmit },
                React.createElement(VStack, { spacing: 1 }, Object.keys(notificationSettings).map(function (key) { return (React.createElement(React.Fragment, null,
                    React.createElement(Checkbox, { checked: notificationSettings[key] === 1 || notificationSettings[key] === 2, onChange: function (e) {
                            var _a;
                            onChange(__assign(__assign({}, notificationSettings), (_a = {}, _a[key] = e.target.checked ? 1 : 0, _a)));
                        } },
                        React.createElement(Text, { fontSize: "sm", ml: 3 }, configNames[key])),
                    key === "20" && notificationSettings[key] ? (React.createElement(VStack, { pl: "3rem", spacing: 1 },
                        React.createElement(HStack, { as: "label" },
                            React.createElement(Input, { checked: notificationSettings["20"] === 1, onChange: function (e) {
                                    var _a;
                                    onChange(__assign(__assign({}, notificationSettings), (_a = {}, _a[key] = 1, _a)));
                                }, width: "auto", height: "auto", type: "radio" }),
                            React.createElement(Text, { fontSize: "sm" }, "N\u00E4r n\u00E5gon skrivit i en tr\u00E5d jag medverkat i")),
                        React.createElement(HStack, { as: "label" },
                            React.createElement(Input, { checked: notificationSettings["20"] === 2, onChange: function (e) {
                                    var _a;
                                    onChange(__assign(__assign({}, notificationSettings), (_a = {}, _a[key] = 2, _a)));
                                }, width: "auto", height: "auto", type: "radio" }),
                            React.createElement(Text, { fontSize: "sm" }, "Varje g\u00E5ng n\u00E5gon skriver n\u00E5got")))) : null)); })),
                updateNotificationSettings.error && (React.createElement(Text, { color: "red", fontSize: "xs" }, updateNotificationSettings.error.message)),
                React.createElement(Box, null,
                    React.createElement(Button, { loading: updateNotificationSettings.loading, type: "submit" }, "Spara")))))));
};
export var useNotificationSettingsModal = function () {
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    return {
        NotificationSettingsModal: isOpen && React.createElement(NotificationSettingsModal, { onClose: function () { return setIsOpen(false); } }),
        openNotificationSettingsModal: function () { return setIsOpen(true); },
        closeNotificationSettingsModal: function () { return setIsOpen(false); }
    };
};
