var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Modal, { ModalTitle, ModalCloseButton } from "@skepool/core/src/components/Modal";
import Button from "@skepool/core/src/components/Button";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import Text from "@skepool/core/src/components/Text";
import Input, { Checkbox } from "@skepool/core/src/components/Input";
import { useAsyncCallback, useAsync } from "@skepool/core/src/hooks/async";
import Axios from "axios";
import Loader from "../components/Loader";
export function isValidSwedishPIN(input) {
    var p = (input + "").replace(/\D/g, "").split("");
    if (p.length > 12) {
        return false;
    }
    var pin = input
        .replace(/\D/g, "")
        .split("") // convert string to array
        .reverse() // reverse order for Luhn
        .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)
    // verify we got 10 digits, otherwise it is invalid
    if (pin.length != 10) {
        return false;
    }
    var sum = pin
        // convert to number
        .map(function (n) {
        return Number(n);
    })
        // perform arithmetic and return sum
        .reduce(function (previous, current, index) {
        // multiply every other number with two
        if (index % 2)
            current *= 2;
        // if larger than 10 get sum of individual digits (also n-9)
        if (current > 9)
            current -= 9;
        // sum it up
        return previous + current;
    });
    // sum must be divisible by 10
    return 0 === sum % 10;
}
export var UserSettingsModal = function (_a) {
    var onClose = _a.onClose;
    var getCurrentUser = useAsync(function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentUser, user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Axios.get("/api/core/current").then(function (res) { return res.data; })];
                case 1:
                    currentUser = _a.sent();
                    return [4 /*yield*/, Axios.get("/api/users/" + currentUser.userId).then(function (res) { return res.data; })];
                case 2:
                    user = _a.sent();
                    return [2 /*return*/, __assign(__assign({}, user), { company: currentUser.company })];
            }
        });
    }); }, []);
    var updateCurrentUser = useAsyncCallback(function (u) { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (u.password === "") {
                        delete u.password;
                    }
                    return [4 /*yield*/, Axios.put("/api/users/" + u.id, u).then(function (res) { return res.data; })];
                case 1:
                    user = _a.sent();
                    return [2 /*return*/, __assign({}, user)];
            }
        });
    }); });
    var currentuser = getCurrentUser.result;
    var onSubmit = function (e) {
        e.preventDefault();
        if (currentuser) {
            updateCurrentUser.execute(currentuser).then(function () {
                onClose();
            });
        }
    };
    var onChange = function (u) {
        getCurrentUser.setResult(u);
    };
    return (React.createElement(Modal, { onClose: onClose },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, "Inst\u00E4llningar"),
                React.createElement(ModalCloseButton, { onClose: onClose })),
            getCurrentUser.loading && (React.createElement(Box, { height: 396, position: "relative" },
                React.createElement(Loader, null))),
            getCurrentUser.error && React.createElement(Text, { color: "red" }, getCurrentUser.error.message),
            currentuser && (React.createElement(VStack, { as: "form", spacing: 4, width: "25rem", onSubmit: onSubmit },
                React.createElement(HStack, null,
                    React.createElement(VStack, { spacing: 2 },
                        React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "firstName" }, "F\u00F6rnamn"),
                        React.createElement(Input, { name: "firstName", value: currentuser.firstName, onChange: function (e) {
                                onChange(__assign(__assign({}, currentuser), { firstName: e.target.value }));
                            } })),
                    React.createElement(VStack, { spacing: 2 },
                        React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "lastName" }, "Efternamn"),
                        React.createElement(Input, { name: "lastName", value: currentuser.lastName, onChange: function (e) {
                                onChange(__assign(__assign({}, currentuser), { lastName: e.target.value }));
                            } }))),
                React.createElement(VStack, { spacing: 2 },
                    React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "personnumber" }, "Personnummer (\u00C5\u00C5\u00C5\u00C5MMDDXXXX)"),
                    React.createElement(Input, { name: "personnumber", value: currentuser.personnumber, css: {
                            "&:invalid": {
                                borderColor: "red",
                            },
                        }, onChange: function (e) {
                            var val = e.target.value.replace(/\D/g, "");
                            if (isValidSwedishPIN(val) || val === "") {
                                e.target.setCustomValidity("");
                            }
                            else {
                                e.target.setCustomValidity("Ogiltigt personnummer");
                            }
                            onChange(__assign(__assign({}, currentuser), { personnumber: val }));
                        } }),
                    React.createElement(Text.Small, { color: "grey", fontSize: "xs", css: { a: { color: "blue" } } },
                        "Vill du veta mer om hur vi behandlar personuppgifter och cookies kan du l\u00E4sa",
                        " ",
                        React.createElement("a", { href: "https://www.skekraft.se/om-oss/cookies-och-personuppgifter/" }, "h\u00E4r"),
                        ". ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Gallring av dina personuppgifter sker automatiskt om du inte varit inloggad i Skepool under de senaste sex m\u00E5naderna, eller tidigare om s\u00E5 meddelas.")),
                React.createElement(VStack, { spacing: 2 },
                    React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "company" }, "F\u00F6retag"),
                    React.createElement(Input, { name: "company", css: { opacity: 0.5 }, disabled: true, readonly: true, value: currentuser.company.name })),
                React.createElement(VStack, { spacing: 2 },
                    React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "email" }, "E-post / anv\u00E4ndarnamn"),
                    React.createElement(Input, { name: "email", css: { opacity: 0.5 }, disabled: true, readonly: true, value: currentuser.email })),
                React.createElement(VStack, { spacing: 2 },
                    React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "password" },
                        "L\u00F6senord",
                        React.createElement(Text, { as: "span", ml: 2, fontSize: "xxs" }, "(l\u00E4mna tomt f\u00F6r att beh\u00E5lla befintligt)")),
                    React.createElement(Input, { name: "password", type: "password", value: currentuser.password, onChange: function (e) {
                            onChange(__assign(__assign({}, currentuser), { password: e.target.value }));
                        } })),
                React.createElement(Checkbox, { onChange: function (e) {
                        onChange(__assign(__assign({}, currentuser), { withoutQuittance: !e.target.checked }));
                    }, checked: !currentuser.withoutQuittance },
                    React.createElement(Text, { ml: 2 }, "Skicka mail vid handel")),
                updateCurrentUser.error && (React.createElement(Text, { color: "red", fontSize: "xs" }, updateCurrentUser.error.message)),
                React.createElement(Box, null,
                    React.createElement(Button, { loading: updateCurrentUser.loading, type: "submit" }, "Spara")))))));
};
export var useUserSettingsModal = function () {
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    return {
        UserSettingsModal: isOpen && React.createElement(UserSettingsModal, { onClose: function () { return setIsOpen(false); } }),
        openUserSettingsModal: function () { return setIsOpen(true); },
        closeUserSettingsModal: function () { return setIsOpen(false); },
    };
};
