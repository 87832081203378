import React from "react";
import { Tabs, Tab, TabPanels, TabPanel, TabList } from "@skepool/core/src/components/Tabs";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import { useAsync } from "@skepool/core/src/hooks/async";
import Loader from "@skepool/core/src/components/Loader";
import Select from "@skepool/core/src/components/Select";
import TradeService from "../../services/TradeService";
import { PageHeader } from "@skepool/core/src/components/PageHeader";
import HistoricalTradesElcert from "./HistoricalTradesElcert";
import HistoricalTradesTermCert from "./HistoricalTradesTermCert";
import HistoricalTradesUG from "./HistoricalTradesUG";
import InputGroup from "@skepool/core/src/components/InputGroup";
import Filters from "../../components/Filters";
var Historical = function (props) {
    var areasData = useAsync(function () { return TradeService.getAreas(); }, []);
    var accountsData = useAsync(function () { return TradeService.getAccounts(); }, []);
    var loading = areasData.loading || accountsData.loading;
    var error = areasData.error || accountsData.error;
    if (loading) {
        return (React.createElement(Box, { className: "react-app", style: {
                width: "75rem",
                margin: "2rem auto",
                float: "left",
            } },
            React.createElement(Loader, null)));
    }
    if (error) {
        return (React.createElement(Box, { className: "react-app", style: {
                width: "75rem",
                margin: "2rem auto",
                float: "left",
            } },
            React.createElement(Text, { color: "red" }, error.message)));
    }
    if (!areasData.result || !accountsData.result) {
        return null;
    }
    var areas = areasData.result;
    var accounts = accountsData.result;
    return React.createElement(HistoricalTrades, { accounts: accounts, areas: areas });
};
var HistoricalTrades = function (_a) {
    var accountsData = _a.accounts, areasData = _a.areas;
    var areas = areasData.map(function (x) { return ({ label: x.name, value: x.id }); });
    var accounts = accountsData.map(function (x) { return ({ label: x.name, value: x.id }); });
    var _b = React.useState(areas[0]), area = _b[0], setArea = _b[1];
    var _c = React.useState(accounts[0]), account = _c[0], setAccount = _c[1];
    var _d = React.useState(0), index = _d[0], setIndex = _d[1];
    var priceTypeOptions = [
        { value: "buy", label: "Köp" },
        { value: "sell", label: "Sälj" },
        { value: "pricemaster", label: "Medel" },
    ];
    var _e = React.useState(priceTypeOptions[0]), priceType = _e[0], setPriceType = _e[1];
    return (React.createElement(Box, null,
        React.createElement(PageHeader, { pb: "2.5rem" },
            React.createElement(Text, { fontSize: "xl", fontWeight: "black" }, "Historisk handel")),
        React.createElement(Tabs, { mt: "-2rem", px: 4, onChange: function (i) {
                if (index !== i) {
                    setPriceType(priceTypeOptions[0]);
                }
                setIndex(index);
            } },
            React.createElement(TabList, null,
                React.createElement(Tab, null, "Terminer & Elcertifikat"),
                React.createElement(Tab, null, "Elcertifikat"),
                React.createElement(Tab, null, "Milj\u00F6produkter")),
            React.createElement(Filters, null,
                index !== 2 && (React.createElement(React.Fragment, null,
                    React.createElement(InputGroup, { width: ["100%", 132] },
                        React.createElement(Text, { as: "label", fontSize: "sm" }, "Elomr\u00E5de"),
                        React.createElement(Select, { name: "area", placeholder: "V\u00E4lj elomr\u00E5de", options: areas, value: area, onChange: function (x) { return setArea(x); } })),
                    React.createElement(InputGroup, { width: ["100%", 132] },
                        React.createElement(Text, { as: "label", fontSize: "sm" }, "Konto"),
                        React.createElement(Select, { name: "account", placeholder: "V\u00E4lj konto", onChange: function (x) { return setAccount(x); }, value: account, options: accounts })))),
                React.createElement(InputGroup, { width: ["100%", 132] },
                    React.createElement(Text, { as: "label", fontSize: "sm" }, "Pris"),
                    React.createElement(Select, { name: "price", placeholder: "V\u00E4lj pris", options: priceTypeOptions, value: priceType, onChange: function (x) { return setPriceType(x); } }))),
            React.createElement(TabPanels, null,
                React.createElement(TabPanel, { renderOnlyWhenSelected: true },
                    React.createElement(HistoricalTradesTermCert, { priceType: priceType.value, area: area.value, account: account.value })),
                React.createElement(TabPanel, { renderOnlyWhenSelected: true },
                    React.createElement(HistoricalTradesElcert, { priceType: priceType.value, area: area.value, account: account.value })),
                React.createElement(TabPanel, { renderOnlyWhenSelected: true },
                    React.createElement(HistoricalTradesUG, { priceType: priceType.value, accounts: accounts }))))));
};
export default Historical;
