var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import ReactSelect from "react-select";
import appTheme from "../theme";
var textStyles = {
    padding: ".5rem",
};
var customStyles = function (height, locked, width) { return ({
    clearIndicator: function (provided, state) {
        return __assign(__assign({}, provided), { padding: appTheme.space[1], svg: {
                width: appTheme.space[3],
                height: appTheme.space[3],
            } });
    },
    dropdownIndicator: function (provided, state) {
        return __assign(__assign({}, provided), { padding: appTheme.space[1], svg: {
                width: appTheme.space[3],
                height: appTheme.space[3],
            } });
    },
    multiValueRemove: function (base, state) {
        return state.data.isFixed ? __assign(__assign({}, base), { display: "none" }) : base;
    },
    container: function (provided, state) {
        return __assign(__assign({}, provided), { opacity: locked ? 1 : state.isDisabled ? 0.5 : 1, height: height, width: width, color: "#333333", fontFamily: '"SK Sans"', fontSize: ".75rem" });
    },
    placeholder: function (provided) {
        return __assign({}, provided);
    },
    singleValue: function (provided) {
        return __assign({}, provided);
    },
    option: function (provided, state) {
        return __assign(__assign({}, provided), { padding: ".5rem" });
    },
    menu: function (provided, state) {
        return __assign(__assign({}, provided), { marginTop: 1, zIndex: 99 });
    },
    groupHeading: function (provided, state) {
        return __assign(__assign({}, provided), { padding: "0.5rem" });
    },
    control: function (provided, state) {
        return __assign(__assign({}, provided), { minHeight: "2rem!important", border: "none" });
    },
}); };
var Select = function (_a) {
    var options = _a.options, width = _a.width, name = _a.name, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.locked, locked = _c === void 0 ? false : _c, _d = _a.height, height = _d === void 0 ? "2rem" : _d, props = __rest(_a, ["options", "width", "name", "disabled", "locked", "height"]);
    var styles = customStyles(height, locked, width);
    return (React.createElement("div", { "data-testid": name + "-select" },
        React.createElement(ReactSelect, __assign({ className: "Select", styles: styles, locked: locked, id: name + "-select", inputId: name + "-select-input", instanceId: name + "-select", isDisabled: disabled || locked, theme: function (theme) { return (__assign(__assign({}, theme), { borderRadius: 0, colors: __assign(__assign({}, theme.colors), { neutral0: appTheme.colors.offwhite, primary25: appTheme.colors.verylightblue, primary50: appTheme.colors.lightblue, primary: appTheme.colors.blue }) })); }, options: options }, props))));
};
Select.displayName = "Select";
export default Select;
