var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Axios from "axios";
import { uid } from "@skepool/core/src/utils";
var ReportsService = {
    getAreas: function () {
        return Axios.get("/api/areas/all").then(function (res) { return res.data; });
    },
    getTransactions: function (params) {
        return Axios.get("/api/rapport/transactions", { params: params }).then(function (res) { return res.data; });
    },
    getAccounts: function (withMatrix) {
        return Axios.get("api/accounts", {
            params: { withMatrix: withMatrix ? 1 : 0 },
        }).then(function (res) { return res.data; });
    },
    getSummaryReport: function (type, params) {
        return Axios.get("/api/rapport/summary/" + type, { params: params }).then(function (res) { return res.data; });
    },
    get4Portfolios: function (params) {
        return Axios.get("/api/rapport/4portfolio", { params: params }).then(function (res) { return res.data; });
    },
    getSummaryReportUG: function (params) {
        return Axios.get("/api/rapport/summary/ug", { params: params }).then(function (res) { return res.data; });
    },
    getMatrixSummary: function (params) {
        return Axios.get("/api/matris/summary", { params: params }).then(function (res) { return res.data; });
    },
    getSummaryReportNasdaq: function (_a) {
        var priceAreas = _a.priceAreas, params = __rest(_a, ["priceAreas"]);
        return ReportsService.getSummaryReport("nasdaq", params).then(function (result) {
            if (typeof result === "string") {
                return {
                    month: [],
                    quarter: [],
                    year: [],
                };
            }
            else {
                return {
                    month: result.month.map(function (x) {
                        var area = priceAreas.find(function (y) { return y.id === x.PriceArea; });
                        if (area) {
                            x.PriceArea = area.name;
                        }
                        return x;
                    }),
                    quarter: result.quarter.map(function (x) {
                        var area = priceAreas.find(function (y) { return y.id === x.PriceArea; });
                        if (area) {
                            x.PriceArea = area.name;
                        }
                        return x;
                    }),
                    year: result.year.map(function (x) {
                        var area = priceAreas.find(function (y) { return y.id === x.PriceArea; });
                        if (area) {
                            x.PriceArea = area.name;
                        }
                        return x;
                    }),
                };
            }
        });
    },
    getDeliveryTransactionsElcert: function (params) {
        return Axios.get("/api/rapport/delivery/transactions", {
            params: params,
        })
            .then(function (res) { return res.data; })
            .then(function (data) {
            data.history = data.history.filter(function (x) { return x.electricityCertificate.transactions.length; });
            return data;
        });
    },
    getDeliveryTransactionsUG: function (params) {
        return Axios.get("/api/rapport/delivery/transactions", {
            params: params,
        })
            .then(function (res) { return res.data; })
            .then(function (data) {
            data.history = data.history.filter(function (x) { return x.miljoprod.transactions.length; });
            return data;
        });
    },
    getDeliveryTransactionsNasdaq: function (params) {
        return Axios.get("/api/rapport/delivery/transactions", {
            params: params,
        })
            .then(function (res) { return res.data; })
            .then(function (data) {
            data.history = data.history.filter(function (x) {
                return x.nasdaqelprod.transactions.length ||
                    x.nasdaqepadprod.transactions.length ||
                    x.nasdaqvalutaprod.transactions.length;
            });
            return data;
        });
    },
    getDeliveryReportElcert: function (params) {
        return Axios.get("/api/rapport/delivery/electricity-derivative", { params: params }).then(function (res) { return res.data; });
    },
    getDeliveryReportNasdaq: function (params) {
        return Axios.get("/api/rapport/delivery/nasdaq", { params: params }).then(function (res) { return res.data; });
    },
    getDeliveryReportUG: function (params) {
        return Axios.get("/api/rapport/delivery/ug", {
            params: params,
        }).then(function (res) { return res.data; });
    },
    getHistoricalPricesReport: function (params) {
        return Axios.get("/api/rapport/historical-prices/electricity-derivative", {
            params: params,
        }).then(function (res) { return res.data; });
    },
    getPortfolioReport: function (params) {
        return Axios.get("/api/rapport/portfolio", { params: params })
            .then(function (res) { return res.data; })
            .catch(function (e) {
            var _a, _b;
            if (((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                throw new Error("Du måste ladda upp en prognos för att kunna hämta kundportfölj.");
            }
            else if (((_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
                throw new Error("Du saknar rättighet att se kundportfölj.");
            }
            else {
                throw e;
            }
        });
    },
    uploadPortfolioPrediction: function (file) {
        var fd = new FormData();
        fd.append("table", file, file.name);
        return Axios.post("/api/rapport/customer-predictions/import/excel", fd, {
            transformRequest: function (x) { return x; },
            headers: { "Content-Type": undefined },
        }).then(function (res) { return res.data; });
    },
    getPortfolioPDFLink: function (params) {
        return Axios.post("api/rapport/portfolio", params).then(function (res) { return res.data; });
    },
    savePrediction: function (params) {
        return Axios.put("/api/rapport/customer-predictions/" + params.area + "/" + params.accountId, {
            derivativePredictions: params.prediction.map(function (x) { return ({ product: x.product.name, volume: x.volume }); }),
        });
    },
    getChart: function (id) {
        return Axios.get("api/analysis/charts/" + id).then(function (res) { return res.data; });
    },
    getCharts: function () {
        return Axios.get("api/analysis/charts/all").then(function (res) { return res.data; });
    },
    updateChart: function (file, id) {
        var fd = new FormData();
        fd.append("table", file, file.name);
        return Axios.post("/api/analysis/charts/" + id, fd, {
            transformRequest: function (x) { return x; },
            headers: { "Content-Type": undefined },
        }).then(function (res) { return res.data; });
    },
    getMatrisPortfolio: function (params) {
        return Axios.get("/api/matris/portfolio", { params: params }).then(function (res) { return res.data; });
    },
    getPricePredictionExcelUrl: function () {
        return Axios.get("/api/rapport/price-prediction/excel/url").then(function (res) { return res.data; });
    },
    getMatrisPortfolioPDF: function (params) {
        return Axios.post("/api/matris/portfolio", params).then(function (res) { return res.data; });
    },
    getMatrisAccounts: function (params) {
        return Axios.get("/api/matris/account", { params: params }).then(function (res) { return res.data.Accounts; });
    },
    getFilterSettings: function (path) {
        return Axios.get("/api/core/current/property/" + path).then(function (res) {
            var _a, _b;
            return {
                filters: (_b = (_a = res.data.filters) === null || _a === void 0 ? void 0 : _a.map(function (x) {
                    var _a;
                    x.id = (_a = x.id) !== null && _a !== void 0 ? _a : uid();
                    return x;
                })) !== null && _b !== void 0 ? _b : [],
            };
        });
    },
    saveFilterSettings: function (path, params) {
        return Axios.put("api/core/current/property/" + path, {
            value: params,
        }).then(function (res) { return res.data; });
    },
};
export default ReportsService;
