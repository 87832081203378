var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import Loader from "@skepool/core/src/components/Loader";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import TradeService from "../services/TradeService";
import { VStack } from "@skepool/core/src/components/Layout";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@skepool/core/src/components/Tabs";
import Button, { Touchable } from "@skepool/core/src/components/Button";
import { useAccountModal } from "../modals/AccountModal";
import AdminService from "../services/AdminService";
import { PageHeader } from "@skepool/core/src/components/PageHeader";
var AccountsWrapper = function (props) {
    var accountsData = useAsync(function () { return TradeService.getAccounts(); }, []);
    var parametersetsData = useAsync(function () { return TradeService.getParameterSets(); }, []);
    var profilesData = useAsync(function () { return TradeService.getProfiles(); }, []);
    var loading = accountsData.loading || parametersetsData.loading || profilesData.loading;
    var error = accountsData.error || parametersetsData.error || profilesData.error;
    var accounts = accountsData.result;
    var profiles = profilesData.result;
    var parametersets = parametersetsData.result;
    var onAccountUpdated = function (account) {
        var newAccounts = __spreadArrays(accounts);
        var accountIndex = newAccounts.findIndex(function (x) { return x.id === account.id; });
        if (accountIndex === -1) {
            newAccounts.push(account);
            accountsData.setResult(newAccounts);
        }
        else {
            newAccounts[accountIndex] = account;
            accountsData.setResult(newAccounts);
        }
    };
    return (React.createElement(Box, null,
        React.createElement(PageHeader, { pb: "2.5rem" },
            React.createElement(Text, { fontSize: "xl", fontWeight: "black" }, "Konton")),
        React.createElement(Tabs, { mt: "-23px" },
            React.createElement(TabList, { bg: "offwhite", px: 4, py: [3, 0] },
                React.createElement(Tab, null, "Aktiva"),
                React.createElement(Tab, null, "Inaktiva")),
            error && React.createElement(ErrorFallback, { error: error }),
            loading && React.createElement(Loader, null),
            profiles && parametersets && accounts && (React.createElement(TabPanels, null,
                React.createElement(TabPanel, { renderOnlyWhenSelected: true, bg: "white", px: 4 },
                    React.createElement(Accounts, { profiles: profiles, parametersets: parametersets, accounts: accounts.filter(function (x) { return x.active; }), onAccountUpdated: onAccountUpdated })),
                React.createElement(TabPanel, { renderOnlyWhenSelected: true, bg: "white", px: 4 },
                    React.createElement(Accounts, { profiles: profiles, parametersets: parametersets, accounts: accounts.filter(function (x) { return !x.active; }), onAccountUpdated: onAccountUpdated })))))));
};
export var TableHeader = function (props) {
    return React.createElement(Text, __assign({ p: 3, textAlign: "left", fontWeight: "bold", fontSize: "sm", as: "th" }, props));
};
export var TableColumn = function (props) {
    return React.createElement(Text, __assign({ p: 3, as: "td", fontSize: "sm" }, props));
};
export var TableRow = function (props) {
    return React.createElement(Box, __assign({ as: "tr" }, props));
};
var AccountRow = function (_a) {
    var account = _a.account, profiles = _a.profiles, parametersets = _a.parametersets, onAccountUpdated = _a.onAccountUpdated, openAccountModal = _a.openAccountModal;
    var disableAccount = useAsyncCallback(AdminService.disableAccount);
    var enableAccount = useAsyncCallback(AdminService.enableAccount);
    var profile = profiles.find(function (x) { return x.id === (account.storage && Number(account.storage.defaultProfileId)); });
    var parameterset = parametersets.find(function (x) { return x.id === (account.storage && Number(account.storage.defaultParametersetId)); });
    var toggleAccount = function () {
        if (account.active) {
            disableAccount.execute(account).then(function (newAccount) { return onAccountUpdated(newAccount); });
        }
        else {
            enableAccount.execute(account).then(function (newAccount) { return onAccountUpdated(newAccount); });
        }
    };
    return (React.createElement(TableRow, null,
        React.createElement(TableColumn, null,
            React.createElement(Touchable, { lineHeight: "normal", px: 0, py: 0, onClick: function () { return openAccountModal(account); } }, account.name)),
        React.createElement(TableColumn, null, profile && profile.profileName),
        React.createElement(TableColumn, null, parameterset && parameterset.name),
        React.createElement(TableColumn, null,
            React.createElement(Button, { loading: disableAccount.loading || enableAccount.loading, onClick: toggleAccount }, account.active ? "Inaktivera" : "Aktivera"))));
};
var Accounts = function (_a) {
    var accounts = _a.accounts, profiles = _a.profiles, parametersets = _a.parametersets, onAccountUpdated = _a.onAccountUpdated;
    var _b = useAccountModal(function (newAccount) {
        onAccountUpdated(newAccount);
    }), EditAccountModal = _b.EditAccountModal, CreateAccountModal = _b.CreateAccountModal, openAccountModal = _b.openAccountModal;
    var _openAccountModal = function (account) {
        openAccountModal(account, profiles, parametersets);
    };
    return (React.createElement(VStack, { py: 3 },
        React.createElement(Box, null,
            React.createElement(Box, { as: "table", css: {
                    borderCollapse: "collapse",
                    "tbody tr:not(:last-child), thead tr": {
                        borderBottom: 1,
                        borderColor: "midgrey"
                    }
                } },
                React.createElement("thead", null,
                    React.createElement(TableRow, null,
                        React.createElement(TableHeader, null, "Namn"),
                        React.createElement(TableHeader, null, "Profil"),
                        React.createElement(TableHeader, null, "Parameterset"),
                        React.createElement(TableHeader, { textAlign: "right" },
                            React.createElement(Button, { variant: "secondary", onClick: function () { return _openAccountModal(null); } }, "Skapa nytt")))),
                React.createElement("tbody", null, accounts.map(function (a) { return (React.createElement(AccountRow, { key: a.id, account: a, openAccountModal: _openAccountModal, profiles: profiles, parametersets: parametersets, onAccountUpdated: onAccountUpdated })); }))),
            EditAccountModal,
            CreateAccountModal)));
};
export default AccountsWrapper;
