var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Modal, { ModalTitle, ModalCloseButton, ModalText } from "@skepool/core/src/components/Modal";
import Text from "@skepool/core/src/components/Text";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import Input, { Checkbox } from "@skepool/core/src/components/Input";
import { useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "../services/TradeService";
import Alert from "@skepool/core/src/components/Alert";
import { useStatusPoller } from "@skepool/core/src/hooks/useStatusPoller";
import { useImagePoller } from "@skepool/core/src/hooks/useImagePoller";
import { TradeSignerUI } from "./ObserveModal";
export var BankIdMessage = function (_a) {
    var text = _a.text, _b = _a.bg, bg = _b === void 0 ? "lightblue" : _b, _c = _a.color, color = _c === void 0 ? "black" : _c, children = _a.children, props = __rest(_a, ["text", "bg", "color", "children"]);
    return (React.createElement(Box, __assign({ bg: bg, p: 3 }, props),
        React.createElement(Text, __assign({ fontWeight: "bold", color: color, lineHeight: "1.375rem" }, text), children)));
};
var OrderModal = function (_a) {
    var id = _a.id, currentUser = _a.currentUser, onClose = _a.onClose, _b = _a.loading, loading = _b === void 0 ? false : _b, error = _a.error, children = _a.children, props = __rest(_a, ["id", "currentUser", "onClose", "loading", "error", "children"]);
    var _c = React.useState(!currentUser.withoutQuittance), sendReciept = _c[0], setSendReciept = _c[1];
    var _d = React.useState(""), password = _d[0], setPassword = _d[1];
    var _e = React.useState(""), message = _e[0], setMessage = _e[1];
    var messageRef = React.useRef();
    var sign = useAsyncCallback(TradeService.sign);
    var signPassword = useAsyncCallback(TradeService.signPassword);
    var signStatus = useAsyncCallback(TradeService.getSignStatus);
    var _f = React.useState("IDLE"), bankidState = _f[0], setBankIdState = _f[1];
    var _g = React.useState(false), isSameDevice = _g[0], setIsSameDevice = _g[1];
    var qrcode = useImagePoller(TradeService.getSignQRCodeUrl(), bankidState === "POLLING" && !isSameDevice);
    var signid = window.SKEPOOL.USER + ": " + id;
    useStatusPoller(function () { return signStatus.execute(); }, {
        timeout: 50000,
        interval: 1000,
        enabled: bankidState === "POLLING",
        onSuccess: function (status, cancel) {
            if (status.data.SignedStatus === 1) {
                cancel();
                onClose({ message: message, sendReciept: sendReciept, password: password });
                setBankIdState("IDLE");
            }
            else if (status.data.ProgressStatusCode === "FAILED") {
                cancel();
                setBankIdState("IDLE");
            }
        },
        onTimeout: function () {
            setBankIdState("IDLE");
        }
    });
    var onCancel = function (e) {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        setBankIdState("IDLE");
    };
    var onSubmit = function (e) {
        var _a, _b;
        e.preventDefault();
        // @ts-ignore
        var id = (_b = (_a = e.nativeEvent) === null || _a === void 0 ? void 0 : _a.submitter) === null || _b === void 0 ? void 0 : _b.id;
        if (id === "same-device") {
            setIsSameDevice(true);
        }
        else {
            setIsSameDevice(false);
        }
        if (window.SKEPOOL.USER_LOGIN_METHOD === "BankID") {
            sign.execute({ signid: signid, signtext: "Handel: " + message }).then(function (res) {
                setBankIdState("POLLING");
                if (id === "same-device") {
                    setTimeout(function () {
                        TradeService.openBankId(res.BankIdAutoStartToken);
                    });
                }
            });
        }
        else {
            signPassword.execute({ signid: signid, signtext: "Handel: " + message, password: password }).then(function (result) {
                onClose({ message: message, sendReciept: sendReciept, password: password });
            });
        }
    };
    var isBankId = window.SKEPOOL.USER_LOGIN_METHOD === "BankID";
    var signError = sign.error || signPassword.error;
    return (React.createElement(Modal, { initialFocusRef: messageRef, onClose: function () { return onClose(); } },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, "Genomf\u00F6r order"),
                React.createElement(ModalCloseButton, { onClose: onClose })),
            React.createElement(VStack, { maxWidth: "28.5rem", width: "100%" },
                React.createElement(ModalText, null, "F\u00F6ljande volymer registreras n\u00E4r du trycker p\u00E5 Best\u00E4ll-knappen. Slutgiltiga priser kan \u00E4ndras fr\u00E5n din best\u00E4llning tills dess att handeln har registrerats. En kvittens med slutgiltiga uppgifter och ett transaktionsnummer kommer n\u00E4r k\u00F6pet \u00E4r klart."),
                React.createElement(ModalText, null, "Skulle n\u00E5got g\u00E5 fel ring 0910-772631 eller maila oss p\u00E5 krafthandel@skekraft.se"),
                children),
            React.createElement(VStack, { as: "form", onSubmit: onSubmit },
                React.createElement(HStack, null,
                    currentUser.withoutQuittance && (React.createElement(Checkbox, { name: "remember", checked: sendReciept, onChange: function (e) {
                            setSendReciept(e.target.checked);
                        } },
                        React.createElement(Text, { mb: 0, mt: 0, mx: 2, lineHeight: "looser" }, "Skicka kvittens till min e-post"))),
                    bankidState !== "POLLING" ? (React.createElement(Box, null,
                        React.createElement(Text, { display: "block", as: "label", mb: 1, fontSize: "sm", htmlFor: "comment" }, "Kommentar till kvittens"),
                        React.createElement(Input, { value: message, ref: messageRef, onChange: function (e) { return setMessage(e.target.value); }, width: "13rem", id: "comment", name: "comment" }))) : null),
                React.createElement(Box, null,
                    React.createElement(Alert, { show: signError ? true : false }, signError && signError.message),
                    React.createElement(Alert, { mb: error ? 3 : 0, show: error ? true : false }, error && error.message)),
                React.createElement(TradeSignerUI, { label: "Signera best\u00E4llning", labelSameDevice: "Signera best\u00E4llning med samma enhet", isBankId: isBankId, isSameDevice: isSameDevice, loading: loading || sign.loading || signPassword.loading, password: password, setPassword: setPassword, qrcode: qrcode, bankidState: bankidState, onCancel: onCancel })))));
};
export default OrderModal;
