var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "./Box";
import Text from "./Text";
import Heading from "./Heading";
import { HStack, VStack, Spacer, Divider } from "./Layout";
import { useAsync, useAsyncCallback } from "../hooks/async";
import Axios from "axios";
import Loader from "./Loader";
import ThemeWrapper from "./ThemeWrapper";
import { Touchable } from "./Button";
import { useUserSettingsModal } from "../modals/UserSettingsModal";
import { useNotificationSettingsModal } from "../modals/NotificationSettingsModal";
import getWeek from "date-fns/getWeek";
import { Info, PDFIcon } from "./Icons";
import webinarIcon from "../assets/webinar-icon.jpg";
import { useTracking, TrackingProvider } from "../hooks/useTracking";
import isSameDay from "date-fns/isSameDay";
import TradeService from "@skepool/handel/src/services/TradeService";
import { useUserUpdatePersonNumberModal } from "../modals/UserUpdatePersonNumber";
var MenuText = function (_a) {
    var css = _a.css, props = __rest(_a, ["css"]);
    return (React.createElement(Text, __assign({ fontSize: "m", css: __assign({ opacity: 0.66, "&:visited": { color: "white" }, "&:hover": { opacity: 1 } }, css), color: "white" }, props)));
};
var Service = {
    getMainMenu: function () {
        return Axios.get("/api/core/menu/main").then(function (res) { return res.data; });
    },
    getAdminMenu: function () {
        return Axios.get("/api/admin/menu/main").then(function (res) { return res.data; });
    },
};
var MenuOverlay = function (props) {
    return (React.createElement(Box, __assign({ className: "menu-overlay", top: 64, left: 0, position: "absolute", width: "100%", bg: "white", borderLeft: 1, borderBottom: 1, borderRight: 1, borderColor: "midgrey", py: 3, px: 4, css: {
            zIndex: 99,
            display: "none",
        } }, props)));
};
var UserMenuOverlay = function (_a) {
    var _b = useUserSettingsModal(), UserSettingsModal = _b.UserSettingsModal, openUserSettingsModal = _b.openUserSettingsModal;
    var _c = useUserUpdatePersonNumberModal(), UserUpdatePersonNumberModal = _c.UserUpdatePersonNumberModal, openUpdatePersonNumberModal = _c.openUpdatePersonNumberModal;
    var _d = useNotificationSettingsModal(), NotificationSettingsModal = _d.NotificationSettingsModal, openNotificationSettingsModal = _d.openNotificationSettingsModal;
    var trackLink = useTracking().trackLink;
    var logout = useAsyncCallback(function () {
        return Axios.post("/api/core/logout").then(function (res) {
            window.location.href = "/";
        });
    });
    var ping = useAsyncCallback(function () { return Axios.put("/api/notifications/ping").then(function (res) { return res.data; }); });
    var _e = React.useState("new"), notifactionState = _e[0], setNotificationState = _e[1];
    var getNotifications = useAsync(function () { return Axios.get("/api/notifications").then(function (res) { return res.data; }); }, []);
    var notifications = getNotifications.result;
    React.useEffect(function () {
        if (window.location.href.indexOf("portal#/usersettings") !== -1) {
            openUserSettingsModal();
        }
        if (window.location.href.indexOf("portal#/update") !== -1) {
            openUpdatePersonNumberModal();
        }
    }, [window.location.href]);
    return (React.createElement(MenuOverlay, null,
        React.createElement(HStack, { justifyContent: "flex-end", flex: 1 },
            notifications && (React.createElement(Box, { onMouseEnter: function () {
                    ping.execute();
                } },
                React.createElement(HStack, { mb: 1, spacing: 2 },
                    React.createElement(Heading.S5, { onClick: function () { return setNotificationState("new"); }, css: notifactionState === "all"
                            ? { cursor: "pointer", color: "blue", fontWeight: "normal" }
                            : {} }, "Nya notiser"),
                    React.createElement(Divider, null),
                    React.createElement(Heading.S5, { onClick: function () { return setNotificationState("all"); }, css: notifactionState === "new"
                            ? { cursor: "pointer", color: "blue", fontWeight: "normal" }
                            : {} }, "Alla notiser")),
                React.createElement(VStack, { overflowY: "auto", height: 150, width: 250, flexGrow: 0, spacing: 0 }, notifications
                    .filter(function (x) {
                    if (notifactionState === "all") {
                        return true;
                    }
                    else {
                        return x.isNew;
                    }
                })
                    .map(function (x) { return (React.createElement(Box, { as: "a", onClick: trackLink("normal", x.url, "Klickade p\u00E5 notis: \"" + x.message + " - " + x.link_text + "\"", ""), key: x.id, href: x.url, py: 2, style: { opacity: !x.isNew ? 0.5 : 1 } },
                    React.createElement(Text, { fontSize: "sm", as: "span", display: "block" }, x.message),
                    React.createElement(Text, { as: "span", display: "block", color: "blue", fontSize: "xs", css: { textDecoration: "underline" } }, x.link_text))); })))),
            React.createElement(Box, null,
                React.createElement(VStack, { spacing: 2, borderLeft: 2, borderColor: "blue", px: 3, alignItems: "Flex-start", alignSelf: "flex-start", pb: 3, width: 170 },
                    React.createElement(Heading.S5, null, "Inst\u00E4llningar"),
                    React.createElement(Text.Small, { px: 0, py: 0, as: Touchable, onClick: function () { return openUserSettingsModal(); } }, "Kontoinst\u00E4llningar"),
                    UserSettingsModal,
                    NotificationSettingsModal,
                    UserUpdatePersonNumberModal,
                    React.createElement(Text.Small, { px: 0, py: 0, as: Touchable, onClick: function () { return openNotificationSettingsModal(); } }, "Notisinst\u00E4llningar"),
                    React.createElement(Text.Small, { textAlign: "left", px: 0, mt: 4, py: 0, as: Touchable, onClick: function () { return logout.execute(); } }, "Logga ut"))))));
};
var AdminMenuOverlay = function (_a) {
    var routes = _a.routes;
    var _b = React.useState(false), hide = _b[0], setHide = _b[1];
    if (hide) {
        return null;
    }
    var onLinkClick = function () {
        setHide(true);
        requestAnimationFrame(function () {
            setHide(false);
        });
    };
    return (React.createElement(MenuOverlay, null,
        React.createElement(HStack, { display: "inline-flex" }, routes.groups.map(function (x) {
            return (React.createElement(VStack, { key: x.id, spacing: 2, borderLeft: 2, borderColor: "darkpurple", px: 3, alignSelf: "flex-start", pb: 3 },
                React.createElement(Heading.S5, null, x.label),
                x.children.map(function (y) { return (React.createElement(Text.Small, { onClick: onLinkClick, as: "a", href: y.route }, y.label)); })));
        }))));
};
var MainMenuOverlay = function (_a) {
    var routes = _a.routes, analysisList = _a.analysisList, charts = _a.charts;
    var _b = React.useState(false), hide = _b[0], setHide = _b[1];
    var trackLink = useTracking().trackLink;
    if (hide) {
        return null;
    }
    var onLinkClick = function () {
        setHide(true);
        requestAnimationFrame(function () {
            setHide(false);
        });
    };
    var getChartName = function (str) {
        if (str === "FEUA MIDDEC") {
            return "Utsläppsrätter";
        }
        return str;
    };
    return (React.createElement(MenuOverlay, null,
        React.createElement(HStack, { display: "inline-flex" }, routes.groups
            .filter(function (x) { return x.children.length || x.id === "analysis-group" || x.id === "charts-group"; })
            .map(function (x) {
            var isAnalysis = x.id === "analysis-group";
            var isCharts = x.id === "charts-group";
            var adminChildren = x.children.filter(function (x) { return /-admin/.test(x.id); });
            var normalChildren = x.children.filter(function (x) { return !/-admin/.test(x.id); });
            var nameMap = {
                vdbrev: "VD-brev",
                comment: "Marknadskommentar",
                veckobrev: "Veckobrev",
                kundtjanst: "Kundservicebrev",
                slutkund: "Slutkundsbrev",
                webinar: "Webinar",
            };
            return (React.createElement(VStack, { key: x.id },
                React.createElement(VStack, { spacing: 2, borderLeft: 2, borderColor: "blue", flex: "none", px: 3, alignSelf: "flex-start", pb: 3 },
                    React.createElement(Heading.S5, null, x.label),
                    isAnalysis &&
                        analysisList &&
                        analysisList.map(function (y) {
                            var _a, _b, _c, _d, _e;
                            var week = getWeek(new Date(y.published_at * 1000));
                            var niceName = nameMap[y.type.name] || y.type.name;
                            if (y.type.name === "webinar" || y.type.name === "comment") {
                                return (React.createElement(Text.Small, { key: y.type.name, css: { textTransform: "capitalize" }, as: "a", display: "flex", alignItems: "center", target: "_blank", rel: "noopener", href: (_b = (_a = y.data.default) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : "", onClick: trackLink("newtab", (_d = (_c = y.data.default) === null || _c === void 0 ? void 0 : _c.url) !== null && _d !== void 0 ? _d : "", "Klickade p\u00E5 " + niceName, (_e = y.title) !== null && _e !== void 0 ? _e : "") },
                                    React.createElement(Box, { as: "img", mr: 2, src: webinarIcon, width: 16, height: 16 }),
                                    React.createElement(Text.Small, { as: "span" },
                                        niceName,
                                        y.title && " - " + y.title)));
                            }
                            return (React.createElement(Text.Small, { key: y.type.name, as: "a", display: "flex", alignItems: "center", href: "/analys/" + y.id, onClick: trackLink("normal", "/analys/" + y.id, "Klickade p\u00E5 " + niceName, "v" + week) },
                                React.createElement(PDFIcon, { mr: 2 }),
                                React.createElement(Text.Small, { as: "span" },
                                    React.createElement(Box, { as: "span", css: { textTransform: "capitalize" } }, nameMap[y.type.name] || y.type.name), " v" + week)));
                        }),
                    isCharts &&
                        charts &&
                        charts.map(function (y) { return (React.createElement(Text.Small, { key: y.id, css: { textTransform: "capitalize" }, as: "a", onClick: onLinkClick, href: "/rapport#/grafer/" + y.id }, getChartName(y.caption))); }),
                    normalChildren.map(function (y) { return (React.createElement(Text.Small, { as: "a", key: y.label, onClick: onLinkClick, href: y.route }, y.label)); })),
                adminChildren.length > 0 && (React.createElement(VStack, { spacing: 2, borderLeft: 2, borderColor: "darkpurple", px: 3, alignSelf: "flex-start", pb: 3 }, adminChildren.map(function (y) { return (React.createElement(Text.Small, { key: y.label, onClick: onLinkClick, as: "a", href: y.route }, y.label)); })))));
        }))));
};
var useMainMenu = function () {
    var filter = /forum|admin/;
    var mainData = useAsync(function () { return Service.getMainMenu(); }, []);
    var adminData = useAsync(function () { return Service.getAdminMenu(); }, []);
    var mainMenu = mainData.result;
    var adminMenu = adminData.result;
    var mainRoutes = mainMenu ? mainMenu.groups.filter(function (x) { return !filter.test(x.id); }) : null;
    var secondaryRoutes = mainMenu ? mainMenu.groups.filter(function (x) { return filter.test(x.id); }) : null;
    var analysisList = mainMenu
        ? Object.keys(mainMenu.analysis).reduce(function (acc, key) {
            // @ts-ignore
            acc.push(mainMenu.analysis[key]);
            return acc;
        }, [])
        : null;
    return {
        AdminMenu: adminMenu ? (React.createElement(Box, { display: "flex", justifyContent: "flex-end", className: "admin-menu", height: "100%", alignItems: "center", css: {
                "&:hover": {
                    ".menu-overlay": {
                        display: "block",
                    },
                },
            } },
            React.createElement(MenuText, { as: "a", css: { textDecoration: "none" }, href: "/admin" }, "Admin"),
            React.createElement(AdminMenuOverlay, { routes: adminMenu }))) : null,
        MainMenu: mainMenu && mainRoutes && secondaryRoutes ? (React.createElement(Box, { display: "inline-flex", className: "main-menu", id: "main-menu", height: "100%", alignItems: "center", css: {
                "&:not([hidden]):hover": {
                    ".menu-overlay": {
                        display: "block",
                    },
                },
            } },
            React.createElement(Box, { display: "flex" }, mainRoutes.map(function (x) { return (React.createElement(Box, { ml: 3, key: x.id },
                React.createElement(MenuText, { as: "a", css: { textDecoration: "none" }, href: x.route }, x.label))); })),
            React.createElement(Box, { display: "flex" }, secondaryRoutes.map(function (x) { return (React.createElement(Box, { ml: 5, key: x.id },
                React.createElement(MenuText, { as: "a", css: { textDecoration: "none" }, href: x.route }, x.label))); })),
            React.createElement(MainMenuOverlay, { analysisList: analysisList, charts: mainMenu.charts, routes: mainMenu }))) : (React.createElement(Box, null,
            React.createElement(Box, { width: "2rem", height: "2rem", position: "relative" },
                React.createElement(Loader, { color: "white", size: "1rem" })),
            React.createElement(Spacer, null))),
    };
};
var Menu = function (props) {
    var _a, _b;
    var getNotificationCount = useAsync(function () { return Axios.get("/api/notifications/new/number").then(function (res) { return res.data; }); }, []);
    var getHolidays = useAsync(function () {
        return Axios.get("/api/holidays").then(function (res) {
            var data = res.data.map(function (x) {
                return {
                    id: x.id,
                    date: new Date(x.date),
                };
            });
            return data;
        });
    }, []);
    var getIsOpen = useAsync(function () { return TradeService.getIsOpen(); }, []);
    var notificationCount = getNotificationCount.result;
    var isHoliday = (_b = (_a = getHolidays.result) === null || _a === void 0 ? void 0 : _a.some(function (x) {
        return isSameDay(new Date(), x.date);
    })) !== null && _b !== void 0 ? _b : false;
    var isTradeClosed = isHoliday ? false : getIsOpen.result === undefined ? false : !getIsOpen.result;
    var _c = useMainMenu(), MainMenu = _c.MainMenu, AdminMenu = _c.AdminMenu;
    var isClosedMessageBg = "rgba(244, 99, 58, 0.8)";
    var bg = isHoliday ? "#f56565" : isTradeClosed ? "#f4633a" : "blue";
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { width: "100%", height: isHoliday || isTradeClosed ? "84px" : "64px" }),
        React.createElement(Box, __assign({ width: "100%", height: isHoliday || isTradeClosed ? "84px" : "64px", position: "fixed", top: 0, left: 0, zIndex: 100 }, props),
            isHoliday ? (React.createElement(HStack, { bg: "#feb2b2", alignItems: "center", justifyContent: "center", p: 1 },
                React.createElement(Info, { color: "#f56565" }),
                React.createElement(Text, { fontSize: "xs", lineHeight: "1rem" }, "Handel i Skepool \u00E4r st\u00E4ngd p\u00E5 grund av helgdag"))) : isTradeClosed ? (React.createElement(HStack, { bg: isClosedMessageBg, alignItems: "center", justifyContent: "center", p: 1 },
                React.createElement(Info, { color: "white" }),
                React.createElement(Text, { fontSize: "xs", lineHeight: "1rem", color: "white" }, "Skepool ej \u00F6ppet, vi saknar priser fr\u00E5n Nasdaq"))) : null,
            React.createElement(Box, { px: 4, position: "relative", css: { zIndex: 100 }, width: "100%", height: 64, bg: bg, display: "block", alignItems: "center" },
                React.createElement(HStack, { width: "100%", alignItems: "center", height: "100%" },
                    React.createElement(Text, { as: "a", style: { textDecoration: "none" }, href: "/portal", fontSize: "28px", fontWeight: "black", color: "white" }, "Skepool"),
                    MainMenu,
                    AdminMenu,
                    React.createElement(Spacer, null),
                    React.createElement(HStack, null,
                        React.createElement(Box, { height: "100%", display: "flex", pl: "2rem", alignItems: "center", css: {
                                "&:hover": {
                                    ".menu-overlay": {
                                        display: "block",
                                    },
                                },
                            } },
                            React.createElement(HStack, { spacing: 1 },
                                React.createElement(MenuText, { as: "span" }, props.userName),
                                React.createElement(Box, { style: { opacity: notificationCount ? 1 : 0 }, width: ".85rem", height: ".85rem", bg: "white", borderRadius: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
                                    React.createElement(Text, { as: "span", fontSize: "xxs", color: "orange" }, notificationCount))),
                            React.createElement(UserMenuOverlay, null))))))));
};
export var WrappedMenu = function (props) {
    return (React.createElement(ThemeWrapper, null,
        React.createElement(TrackingProvider, null,
            React.createElement(Box, { className: "react-app" },
                React.createElement(Menu, { userName: window.SKEPOOL.USER })))));
};
Menu.displayName = "Menu";
export default Menu;
