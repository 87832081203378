var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
// @ts-ignore
import isNaN from "lodash.isnan";
import Box from "./Box";
import { Checkmark } from "./Icons";
var Input = React.forwardRef(function (props, ref) { return (React.createElement(Box, __assign({ as: "input", ref: ref, p: 2, m: 0, lineHeight: "normal", border: "1px solid", borderColor: "offwhite", bg: "offwhite", fontSize: "sm", height: 32, fontWeight: "normal", color: "black", width: "100%", css: __assign({ "&:read-only": {
            opacity: 0.7,
            pointerEvents: "none",
        }, "&:focus": {
            outline: "none",
            borderColor: "grey-darkest",
        }, "&:hover": {
            outline: "none",
            borderColor: "grey-darkest",
        }, transition: "250ms border-color ease" }, props.css) }, props))); });
export var NativeSelect = function (props) { return (React.createElement(Input, __assign({ as: "select", fontSize: "sm", css: __assign({ outline: "none", appearance: "none", background: "url(\"data:image/svg+xml,%0A%3Csvg width='12px' height='8px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 50.2 %2855047%29 - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-552.000000, -299.000000%29' fill='%23757575'%3E%3Cg transform='translate%28341.000000, 282.000000%29'%3E%3Cg transform='translate%28211.000000, 17.000000%29'%3E%3Cpolygon points='10.6 2.30926389e-14 6 4.6 1.4 2.30926389e-14 0 1.4 6 7.4 12 1.4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\") no-repeat", backgroundPosition: "calc(100% - 16px) 50%", "&[multiple]": {
            background: "none",
        } }, props.css) }, props))); };
export var Checkbox = function (_a) {
    var name = _a.name, onChange = _a.onChange, checked = _a.checked, disabled = _a.disabled, children = _a.children, color = _a.color, css = _a.css, props = __rest(_a, ["name", "onChange", "checked", "disabled", "children", "color", "css"]);
    return (React.createElement(Box, __assign({ as: "label", htmlFor: name, display: "flex", fontSize: "sm", lineHeight: "normal", height: "2rem", position: "relative", alignItems: "center", css: __assign({ cursor: "pointer", userSelect: "none", "input:disabled + div, input:disabled + div + div + p": { opacity: 0.5 }, "input.focus-visible:focus + .checked, input.focus-visible:focus + .checked + .unchecked": {
                outline: "1px solid",
                outlineColor: "#888888",
                outlineOffset: ".25rem",
            }, input: { opacity: 0, zIndex: -1, width: 24, height: 24, position: "absolute" }, p: { userSelect: "none" }, "input:checked + .checked": { display: "flex", color: "green" }, "input:checked + .checked + .unchecked": { display: "none" }, "input:not(:checked) + .checked": { display: "none" } }, css) }, props),
        React.createElement("input", { type: "checkbox", tabIndex: 0, disabled: disabled, id: name, name: name, checked: checked, onChange: onChange }),
        React.createElement(Box, { className: "checked", display: "flex", justifyContent: "center", alignItems: "center", border: 1, width: 24, height: 24, position: "relative", zIndex: 0, borderRadius: "3px", borderColor: "blue", bg: "blue" },
            React.createElement(Checkmark, null)),
        React.createElement(Box, { className: "unchecked", bg: "white", position: "relative", zIndex: 0, border: 1, width: 24, height: 24, borderRadius: "3px", borderColor: "grey" }),
        children));
};
export var Radio = function (_a) {
    var name = _a.name, onChange = _a.onChange, checked = _a.checked, children = _a.children, color = _a.color, css = _a.css, props = __rest(_a, ["name", "onChange", "checked", "children", "color", "css"]);
    return (React.createElement(Box, __assign({ as: "label", htmlFor: name, display: "flex", fontSize: "sm", lineHeight: "normal", height: "2rem", position: "relative", alignItems: "center", css: __assign({ cursor: "pointer", userSelect: "none", "input.focus-visible:focus + .checked, input.focus-visible:focus + .checked + .unchecked": {
                outline: "1px solid",
                outlineColor: "#888888",
                outlineOffset: ".25rem",
            }, input: { opacity: 0, zIndex: -1, width: 24, height: 24, position: "absolute" }, p: { userSelect: "none" }, "input:checked + span": { borderColor: "blue" }, "input:checked + span span": { display: "block" } }, css) }, props),
        React.createElement("input", { type: "radio", tabIndex: 0, id: name, name: name, checked: checked, onChange: onChange }),
        React.createElement(Box, { as: "span", border: 1, alignItems: "center", display: "flex", justifyContent: "center", borderColor: "grey", width: 24, height: 24, borderRadius: "100%" },
            React.createElement(Box, { as: "span", bg: "blue", display: "none", width: 12, height: 12, borderRadius: "100%" })),
        children));
};
export var ControlledNumberInput = function (_a) {
    var error = _a.error, input = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, props = __rest(_a, ["error", "value", "onChange", "onBlur"]);
    var _b = React.useState(input), val = _b[0], setValue = _b[1];
    React.useEffect(function () {
        if (input !== val) {
            setValue(input);
        }
    }, [input]);
    return (React.createElement(Input, __assign({ value: val, css: error ? {
            borderColor: "red",
        } : {}, onChange: function (e) {
            var v = e.target.value.replace(",", ".");
            setValue(v);
            var val = Number(v);
            if (onChange && !isNaN(val)) {
                onChange(val);
            }
        }, onKeyDown: function (e) {
            if (e.key === "Enter") {
                e.target.blur();
            }
        }, onBlur: function (e) {
            var v = Number(val);
            if (onBlur && !isNaN(v)) {
                onBlur(v);
                onChange && onChange(v);
            }
            else if (onBlur) {
                setValue(input);
                onBlur(input);
                onChange && onChange(v);
            }
        } }, props)));
};
export default Input;
