import React from "react";
import ThemeWrapper from "@skepool/core/src/components/ThemeWrapper";
import Box from "@skepool/core/src/components/Box";
import Button from "@skepool/core/src/components/Button";
import { useAsync } from "@skepool/core/src/hooks/async";
import { MarketAnalysisService } from "./MarketAnalysisForm";
import Loader from "@skepool/core/src/components/Loader";
import { TableRow, TableColumn, TableHeader } from "@skepool/handel/src/pages/Accounts";
import format from "date-fns/format";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
export var STATUS_MAP = {
    1: "Ej publicerad",
    2: "Publicerad",
};
var AnalysisCommentRow = function (_a) {
    var comment = _a.comment;
    return (React.createElement(TableRow, { as: "a", href: "#/analys/comment/" + comment.id, css: {
            textDecoration: "none",
            display: "table-row",
            "&:hover": {
                bg: "offwhite",
            },
        } },
        React.createElement(TableColumn, { css: { fontVariant: "tabular-nums" } }, format(new Date(comment.created_at * 1000), "yyyy-MM-dd HH:mm")),
        React.createElement(TableColumn, null, STATUS_MAP[comment.status]),
        React.createElement(TableColumn, null)));
};
var MarketAnalysisList = function (props) {
    return (React.createElement(Box, { as: "table", css: {
            minWidth: "30rem",
            borderCollapse: "collapse",
            "tbody tr:not(:last-child), thead tr": {
                borderBottom: 1,
                borderColor: "midgrey",
            },
        } },
        React.createElement("thead", null,
            React.createElement(TableRow, null,
                React.createElement(TableHeader, null, "Skapad"),
                React.createElement(TableHeader, null, "Status"),
                React.createElement(TableHeader, { textAlign: "right" },
                    React.createElement(Button, { as: "a", href: "#/analys/comment/new" }, "Skapa ny marknadskommentar")))),
        React.createElement("tbody", null, props.list.map(function (x) { return (React.createElement(AnalysisCommentRow, { key: x.id, comment: x })); }))));
};
export var MarketAnalysisListWithThemeWrapper = function (props) {
    return (React.createElement(ThemeWrapper, null,
        React.createElement(MarketAnalysisListContainer, null)));
};
var MarketAnalysisListSkeleton = function () { return (React.createElement(Box, { className: "react-app", style: {
        width: "75rem",
        margin: "2rem auto",
        float: "left",
    } },
    React.createElement(Box, { p: "2rem", bg: "white" },
        React.createElement(Loader, null)))); };
var MarketAnalysisListContainer = function (props) {
    var list = useAsync(function () { return MarketAnalysisService.getMarketAnalysisList(); }, []);
    if (list.loading || !list.result) {
        return React.createElement(MarketAnalysisListSkeleton, null);
    }
    if (list.error) {
        return React.createElement(ErrorFallback, { error: list.error });
    }
    return (React.createElement(Box, { className: "react-app", style: {
            width: "75rem",
            margin: "2rem auto",
            float: "left",
        } },
        React.createElement(Box, { p: "2rem", bg: "white" },
            React.createElement(MarketAnalysisList, { list: list.result }))));
};
export default MarketAnalysisListContainer;
