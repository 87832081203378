import React from "react";
import { ThemeProvider } from "emotion-theming";
import theme from "../theme";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/core";
import extraScopePlugin from "stylis-plugin-extra-scope";
var cache = createCache({ stylisPlugins: [[extraScopePlugin(".react-app")]] });
var ThemeWrapper = function (_a) {
    var children = _a.children;
    return (React.createElement(CacheProvider, { value: cache },
        React.createElement(ThemeProvider, { theme: theme }, children)));
};
export default ThemeWrapper;
