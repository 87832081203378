var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Modal, { ModalTitle, ModalCloseButton } from "@skepool/core/src/components/Modal";
import Text from "@skepool/core/src/components/Text";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import { useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "../services/TradeService";
import Alert from "@skepool/core/src/components/Alert";
import { useStatusPoller } from "@skepool/core/src/hooks/useStatusPoller";
import { useImagePoller } from "@skepool/core/src/hooks/useImagePoller";
import { TradeSignerUI } from "./ObserveModal";
var ExternalTradeModal = function (_a) {
    var onClose = _a.onClose, _b = _a.loading, loading = _b === void 0 ? false : _b, error = _a.error, children = _a.children, transactionIds = _a.transactionIds, props = __rest(_a, ["onClose", "loading", "error", "children", "transactionIds"]);
    var _c = React.useState(""), password = _c[0], setPassword = _c[1];
    var passwordRef = React.useRef();
    var sign = useAsyncCallback(TradeService.sign);
    var signPassword = useAsyncCallback(TradeService.signPassword);
    var signStatus = useAsyncCallback(TradeService.getSignStatus);
    var _d = React.useState("IDLE"), bankidState = _d[0], setBankIdState = _d[1];
    var _e = React.useState(false), isSameDevice = _e[0], setIsSameDevice = _e[1];
    var qrcode = useImagePoller(TradeService.getSignQRCodeUrl(), bankidState === "POLLING" && !isSameDevice);
    var signid = window.SKEPOOL.USER + ": Extern handel";
    useStatusPoller(function () { return signStatus.execute(); }, {
        timeout: 50000,
        interval: 1000,
        enabled: bankidState === "POLLING",
        onSuccess: function (status, cancel) {
            if (status.data.SignedStatus === 1) {
                cancel();
                onClose(true);
                setBankIdState("IDLE");
            }
            else if (status.data.ProgressStatusCode === "FAILED") {
                cancel();
                setBankIdState("IDLE");
            }
        },
        onTimeout: function () {
            setBankIdState("IDLE");
        },
    });
    var onCancel = function (e) {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        setBankIdState("IDLE");
    };
    var onSubmit = function (e) {
        var _a, _b;
        e.preventDefault();
        // @ts-ignore
        var id = (_b = (_a = e.nativeEvent) === null || _a === void 0 ? void 0 : _a.submitter) === null || _b === void 0 ? void 0 : _b.id;
        if (id === "same-device") {
            setIsSameDevice(true);
        }
        else {
            setIsSameDevice(false);
        }
        if (window.SKEPOOL.USER_LOGIN_METHOD === "BankID") {
            sign.execute({
                signid: signid,
                signtext: "Extern handel",
            }).then(function (res) {
                setBankIdState("POLLING");
                if (id === "same-device") {
                    setTimeout(function () {
                        TradeService.openBankId(res.BankIdAutoStartToken);
                    });
                }
            });
        }
        else {
            signPassword.execute({ password: password, signid: signid, signtext: "Extern handel" }).then(function (result) {
                onClose(true);
            });
        }
    };
    var isBankId = window.SKEPOOL.USER_LOGIN_METHOD === "BankID";
    var signError = sign.error || signPassword.error;
    return (React.createElement(Modal, { initialFocusRef: passwordRef, onClose: function () { return onClose(false); } },
        React.createElement(VStack, { spacing: 3 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, transactionIds !== null ? "Extern handel genomförd" : "Genomför extern handel"),
                React.createElement(ModalCloseButton, { onClose: function () {
                        onClose(false);
                    } })),
            transactionIds === null && children,
            transactionIds === null && (React.createElement(VStack, { spacing: 4, as: "form", onSubmit: onSubmit },
                React.createElement(Box, null,
                    React.createElement(Alert, { mb: signError ? 3 : 0, show: signError ? true : false }, signError && signError.message),
                    React.createElement(Alert, { mb: error ? 3 : 0, show: error ? true : false }, error && error.message)),
                React.createElement(TradeSignerUI, { label: "Signera handel", labelSameDevice: "Signera handel med samma enhet", isBankId: isBankId, isSameDevice: isSameDevice, loading: loading || sign.loading || signPassword.loading, password: password, setPassword: setPassword, qrcode: qrcode, bankidState: bankidState, onCancel: onCancel }))),
            transactionIds !== null && React.createElement(Text, { fontSize: "sm" },
                "TransactionsID: ",
                transactionIds.join(", ")))));
};
export default ExternalTradeModal;
