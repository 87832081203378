import * as React from "react";
export var useImagePoller = function (url, enabled) {
    var _a = React.useState(undefined), image = _a[0], setImage = _a[1];
    React.useEffect(function () {
        if (!enabled)
            return;
        setImage(url + "?t=" + new Date().getTime());
        var interval = setInterval(function () {
            setImage(url + "?t=" + new Date().getTime());
        }, 5000);
        return function () { return clearInterval(interval); };
    }, [url, enabled]);
    return image;
};
