var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Tabs as ReachTabs, TabList as ReachTabList, Tab as ReachTab, TabPanels as ReachTabPanels, TabPanel as ReachTabPanel } from "@reach/tabs";
import Box from "./Box";
import Text from "./Text";
import "@reach/tabs/styles.css";
import "./Tabs.css";
export var Tab = function (props) { return (React.createElement(ReachTab, __assign({ css: {
        pointerEvents: props.disabled ? "none" : "auto",
        opacity: props.disabled ? 0.5 : 1
    }, as: Text, p: 0, fontSize: "base" }, props))); };
export var Tabs = function (props) { return React.createElement(ReachTabs, __assign({ as: Box }, props)); };
export var TabPanels = ReachTabPanels;
export var TabPanel = function (_a) {
    var _b = _a.renderOnlyWhenSelected, renderOnlyWhenSelected = _b === void 0 ? false : _b, isSelected = _a.isSelected, children = _a.children, props = __rest(_a, ["renderOnlyWhenSelected", "isSelected", "children"]);
    return (React.createElement(ReachTabPanel, __assign({ isSelected: isSelected, position: "relative", minHeight: 200, py: 3, as: Box }, props), renderOnlyWhenSelected ? (isSelected ? children : null) : children));
};
export var TabList = function (_a) {
    var _b = _a.css, css = _b === void 0 ? {} : _b, props = __rest(_a, ["css"]);
    return (React.createElement(ReachTabList, __assign({ flexWrap: ["wrap", "no-wrap"], bg: "transparent", as: Box }, props)));
};
