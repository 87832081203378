var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Box from "./Box";
import { keyframes } from "@emotion/core";
var rotate = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    0% {\n        transform: rotate(0);\n    }\n    \n    100% {\n        transform: rotate(360deg);\n    }\n"], ["\n    0% {\n        transform: rotate(0);\n    }\n    \n    100% {\n        transform: rotate(360deg);\n    }\n"])));
var LoaderComponent = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, _c = _a.strokeSize, strokeSize = _c === void 0 ? "2px" : _c, _d = _a.color, color = _d === void 0 ? "black" : _d, props = __rest(_a, ["size", "strokeSize", "color"]);
    return (React.createElement(Box, __assign({ position: "relative", width: size, height: size, color: color, css: __assign({ pointerEvents: "none", "&:after": {
                animation: "\n                    " + rotate + " 0.5s infinite linear\n                ",
                border: strokeSize + " solid currentColor",
                borderRadius: "50%",
                borderRightColor: "transparent",
                borderTopColor: "transparent",
                content: '""',
                display: "block",
                height: "100%",
                width: "100%",
                left: "50%",
                top: "50%",
                marginLeft: "-50%",
                marginTop: "-50%",
                position: "absolute",
                zIndex: 1
            } }, props.css) }, props)));
};
var Loader = function (_a) {
    var bg = _a.bg, maxWidth = _a.maxWidth, props = __rest(_a, ["bg", "maxWidth"]);
    return (React.createElement(Box, { width: "100%", height: "100%", position: "absolute", left: 0, top: 0, maxWidth: maxWidth || "50rem", display: "flex", bg: bg, flexDirection: "column", justifyContent: "center", alignItems: "center" },
        React.createElement(LoaderComponent, __assign({}, props))));
};
export default Loader;
var templateObject_1;
