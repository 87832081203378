import React from "react";
import Modal, { ModalTitle, ModalCloseButton } from "./Modal";
import { VStack, HStack } from "./Layout";
import Button from "./Button";
import Box from "./Box";
import Text from "./Text";
import Input from "./Input";
var ConfirmDialog = function (_a) {
    var onClose = _a.onClose, message = _a.message, _b = _a.usePassword, usePassword = _b === void 0 ? false : _b, options = _a.options;
    var _c = React.useState(""), password = _c[0], setPassword = _c[1];
    return (React.createElement(Modal, { onClose: function () { return onClose(false); } },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, message),
                React.createElement(ModalCloseButton, { onClose: onClose })),
            usePassword && (React.createElement(Box, null,
                React.createElement(Text, { display: "block", as: "label", mb: 1, fontSize: "sm", htmlFor: "password" }, "L\u00F6senord"),
                React.createElement(Input, { value: password, onChange: function (e) { return setPassword(e.target.value); }, width: "13rem", type: "password", id: "password", name: "password" }))),
            React.createElement(HStack, null,
                React.createElement(Button, { onClick: function () { return onClose(false); } }, options.dissaprove),
                React.createElement(Button, { onClick: function () { return onClose(true, password); } }, options.approve)))));
};
export var useConfirm = function (message, onClose, usePassword, options) {
    if (usePassword === void 0) { usePassword = false; }
    if (options === void 0) { options = {
        approve: "Radera",
        dissaprove: "Avbryt",
    }; }
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var callbacks = React.useRef();
    var onDialogClose = function (status, password) {
        var _a;
        setIsOpen(false);
        (_a = callbacks.current) === null || _a === void 0 ? void 0 : _a.resolve(status);
        onClose(status, password);
    };
    return {
        Dialog: isOpen ? (React.createElement(ConfirmDialog, { options: options, usePassword: usePassword, message: message, onClose: onDialogClose })) : null,
        open: function () {
            setIsOpen(true);
            return new Promise(function (resolve) {
                callbacks.current = {
                    resolve: resolve,
                };
            });
        },
    };
};
export default ConfirmDialog;
