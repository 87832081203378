var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Box from "./Box";
import Text from "./Text";
import Button from "./Button";
import Select from "./Select";
// @ts-ignore
import isNaN from "lodash.isnan";
import Datepicker from "./Datepicker";
import { Checkbox } from "./Input";
var copyToClipboard = function (str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    var selection = document.getSelection();
    if (selection !== null) {
        selection.removeAllRanges();
        var selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
        el.select();
        el.focus();
        document.execCommand("copy");
        document.body.removeChild(el);
        if (selected) {
            selection.removeAllRanges();
            selection.addRange(selected);
        }
    }
};
var dataToCSV = function (rows, columns, selection) {
    return rows
        .reduce(function (arr, currRow, currentIndex) {
        var cells = columns.filter(function (x, index) { return selection[currentIndex][index]; });
        if (cells.length) {
            var output = cells
                .map(function (x) { return x.get(currRow); })
                .join("\t")
                .replace(/\./g, ",");
            return arr.concat([output]);
        }
        return arr;
    }, [])
        .join("\n");
};
var format = function (n) {
    return typeof n === "number" ? Number(n).toFixed(2).replace(".", ",") : n;
};
var Spreadsheet = function (_a) {
    var css = _a.css, props = __rest(_a, ["css"]);
    return (React.createElement(Box, __assign({ as: "table", minWidth: "25rem", css: __assign({ borderColor: "lightgrey", userSelect: "none", "*": {
                userSelect: "none",
            }, borderCollapse: "collapse", "tr:not(:first-of-type)": {
                borderTop: 1,
                borderColor: "midgrey",
            } }, css), className: "Spreadsheet" }, props)));
};
Spreadsheet.Table = function (_a) {
    var _b = _a.onRemoveClick, onRemoveClick = _b === void 0 ? function () { } : _b, _c = _a.removeableRows, removeableRows = _c === void 0 ? false : _c, columns = _a.dataColumns, rows = _a.dataRows, onCellChange = _a.onCellChange, css = _a.css, props = __rest(_a, ["onRemoveClick", "removeableRows", "dataColumns", "dataRows", "onCellChange", "css"]);
    return (React.createElement(Spreadsheet, __assign({ css: __assign({ "td, th": {
                border: 1,
                borderColor: "midgrey",
            } }, css) }, props),
        React.createElement("thead", null,
            React.createElement(Spreadsheet.Row, null, columns.map(function (x) { return (React.createElement(Spreadsheet.Header, { key: x.id, textAlign: x.alignment },
                React.createElement(Text.Small, { fontWeight: "bold" }, x.bigheader || ""))); })),
            React.createElement(Spreadsheet.Row, null,
                columns.map(function (x) { return (React.createElement(Spreadsheet.Header, { key: x.id },
                    React.createElement(Text.Small, { fontWeight: "bold", textAlign: x.alignment },
                        x.header.title,
                        x.header.subtitle ? (React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, x.header.subtitle)) : null))); }),
                removeableRows ? React.createElement(Spreadsheet.Header, { border: "none!important" }) : null)),
        React.createElement(Grid, { gridStyle: "SHEET", onRemoveClick: onRemoveClick, removeableRows: removeableRows, rows: rows, columns: columns, onCellChange: onCellChange }),
        React.createElement("tbody", null,
            React.createElement(Spreadsheet.Row, null, columns.map(function (x, index) {
                if (!x.total) {
                    return null;
                }
                var total = typeof x.total === "function" ? x.total(rows, x) : x.total;
                var formattedValue = typeof x.format === "function" ? x.format(total) : format(total);
                return (React.createElement(Spreadsheet.Header, { key: x.id },
                    React.createElement(Text.Small, { fontWeight: "bold", textAlign: x.alignment }, formattedValue)));
            })))));
};
Spreadsheet.Excel = function (_a) {
    var columns = _a.dataColumns, rows = _a.dataRows, onCellChange = _a.onCellChange, onGridPaste = _a.onGridPaste, removeableRows = _a.removeableRows, onRemoveClick = _a.onRemoveClick, props = __rest(_a, ["dataColumns", "dataRows", "onCellChange", "onGridPaste", "removeableRows", "onRemoveClick"]);
    return (React.createElement(Spreadsheet, __assign({}, props),
        React.createElement("thead", null,
            React.createElement(Spreadsheet.Row, null, columns.map(function (x) { return (React.createElement(Spreadsheet.Header, { key: x.id, textAlign: x.alignment },
                React.createElement(Text.Small, { fontWeight: "bold" }, x.bigheader || ""))); })),
            React.createElement(Spreadsheet.Row, null,
                columns.map(function (x) { return (React.createElement(Spreadsheet.Header, { key: x.id },
                    React.createElement(Text.Small, { fontWeight: "bold", textAlign: x.alignment },
                        x.header.title,
                        x.header.subtitle ? (React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, x.header.subtitle)) : null))); }),
                removeableRows ? React.createElement(Spreadsheet.Header, { border: "none!important" }) : null)),
        React.createElement(Grid, { gridStyle: "TRADE", rows: rows, removeableRows: removeableRows, columns: columns, onCellChange: onCellChange, onGridPaste: onGridPaste, onRemoveClick: onRemoveClick }),
        React.createElement("tbody", null,
            React.createElement(Spreadsheet.Row, null, columns.map(function (x, index) {
                if (!x.total) {
                    return null;
                }
                var total = typeof x.total === "function" ? x.total(rows, x) : x.total;
                var formattedValue = typeof x.format === "function" ? x.format(total) : format(total);
                return (React.createElement(Spreadsheet.Header, { key: x.id },
                    React.createElement(Text.Small, { fontWeight: "bold", textAlign: x.alignment }, formattedValue)));
            })))));
};
Spreadsheet.Row = function (props) {
    return React.createElement(Box, __assign({ as: "tr" }, props));
};
Spreadsheet.Column = function (_a) {
    var css = _a.css, selected = _a.selected, alignment = _a.alignment, gridStyle = _a.gridStyle, readonly = _a.readonly, props = __rest(_a, ["css", "selected", "alignment", "gridStyle", "readonly"]);
    return (React.createElement(Box, __assign({ as: "td", className: "Column " + (selected ? "Column__Selected" : ""), position: "relative", bg: readonly ? "transparent" : "offwhite", css: __assign({ fontVariantNumeric: "tabular-nums", backgroundClip: "padding-box", border: gridStyle === "SHEET" ? "1px solid lightgrey" : "1px solid transparent", borderBottom: "1px solid lightgrey", "&.Column__Selected": {
                bg: "verylightblue",
            }, "&.Column__Selected input": {
                bg: "verylightblue",
            }, input: {
                textAlign: alignment,
                fontSize: "sm",
                border: "none",
                fontWeight: "inherit",
                outline: "none",
                minWidth: "100%",
                backgroundColor: "transparent",
                p: 2,
                userSelect: readonly ? "none" : "text",
            }, "&.Column__Selected:after": {
                display: "block",
                content: '""',
                left: "-1px",
                top: "-1px",
                width: "100%",
                zIndex: 1,
                height: "100%",
                position: "absolute",
                border: selected ? "1px solid" : "none",
                borderColor: "blue",
            } }, css), p: 2 }, props)));
};
var gridReducer = function (state, action) {
    switch (action.type) {
        case "UPDATE_GRID": {
            if (action.payload.selection) {
                return __assign(__assign({}, state), { selection: action.payload.selection });
            }
            return state;
        }
        case "RESET_SELECTION": {
            var newState = __assign(__assign({}, state), { isSelecting: false, selection: state.selection.map(function (x) { return x.map(function (_) { return false; }); }) });
            return newState;
        }
        case "UPDATE_IS_SELECTING": {
            return __assign(__assign({}, state), { isSelecting: action.payload.isSelecting });
        }
        case "SELECT_CELL": {
            var _a = action.payload, row = _a.row, cell = _a.cell;
            var newSelection = state.selection.map(function (x) { return x.map(function (_) { return false; }); });
            if (newSelection[row] !== undefined) {
                if (newSelection[row][cell] !== undefined) {
                    newSelection[row][cell] = true;
                }
            }
            return __assign(__assign({}, state), { selection: newSelection, startRow: row, startCell: cell, selectionIndex: {
                    row: row,
                    col: cell,
                } });
        }
        case "SELECT_TO": {
            var _b = action.payload, row = _b.row, cell = _b.cell;
            if (!state.isSelecting) {
                return state;
            }
            var rowStart = state.startRow;
            var cellStart = state.startCell;
            var rowEnd = row;
            var cellEnd = cell;
            if (rowEnd < rowStart) {
                rowStart = rowEnd;
                rowEnd = state.startRow;
            }
            if (cellEnd < cellStart) {
                cellStart = cellEnd;
                cellEnd = state.startCell;
            }
            var newSelection = state.selection.map(function (x) { return x.map(function (_) { return false; }); });
            for (var i = rowStart; i <= rowEnd; i++) {
                for (var j = cellStart; j <= cellEnd; j++) {
                    newSelection[i][j] = true;
                }
            }
            return __assign(__assign({}, state), { selection: newSelection, selectionIndex: {
                    row: row,
                    col: cell,
                } });
        }
    }
    return state;
};
var Grid = function (_a) {
    var onRemoveClick = _a.onRemoveClick, gridStyle = _a.gridStyle, _b = _a.removeableRows, removeableRows = _b === void 0 ? false : _b, columns = _a.columns, onGridPaste = _a.onGridPaste, rows = _a.rows, onCellChange = _a.onCellChange;
    var grid = rows.map(function (x) { return columns.map(function (y) { return false; }); });
    var initialGridState = {
        selection: __spreadArrays(grid),
        startCell: 0,
        startRow: 0,
        selectionIndex: {
            row: 0,
            col: 0,
        },
        isSelecting: false,
    };
    var _c = React.useReducer(gridReducer, initialGridState), selection = _c[0].selection, dispatch = _c[1];
    var tbodyRef = React.useRef(null);
    React.useEffect(function () {
        dispatch({ type: "UPDATE_GRID", payload: { selection: grid } });
    }, [JSON.stringify(grid)]);
    var onMouseClick = React.useCallback(function (e) {
        if (tbodyRef.current && !tbodyRef.current.contains(e.target)) {
            dispatch({ type: "RESET_SELECTION", payload: {} });
        }
    }, [dispatch]);
    var onKeyDown = React.useCallback(function (e) {
        if (e.key === "c" && (e.metaKey || e.ctrlKey)) {
            copyToClipboard(dataToCSV(rows, columns, selection));
        }
        if (e.key === "Escape") {
            dispatch({ type: "RESET_SELECTION", payload: {} });
        }
    }, [rows, columns, selection]);
    React.useEffect(function () {
        // document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("click", onMouseClick);
        return function () {
            // document.removeEventListener("mouseup", onMouseUp);
            document.removeEventListener("click", onMouseClick);
        };
    }, []);
    var onPaste = React.useCallback(function (rowIndex, cellIndex, lines) {
        var values = rows.map(function (x) { return columns.map(function (y) { return undefined; }); });
        lines.forEach(function (line, lineIndex) {
            var cells = line.split("\t");
            cells.forEach(function (text, columnIndex) {
                var row = rows[rowIndex + lineIndex];
                if (row) {
                    var col = columns[cellIndex + columnIndex];
                    var readonly = col && typeof col.readonly === "function" ? col.readonly(row) : col && col.readonly;
                    if (col && !readonly && !row.ReadOnly) {
                        var value = Number(text.replace(",", "."));
                        if (isNaN(value)) {
                            if (onGridPaste) {
                                values[rowIndex + lineIndex][cellIndex + columnIndex] = text;
                            }
                            else {
                                onCellChange(rowIndex + lineIndex, col, text);
                            }
                        }
                        else {
                            if (onGridPaste) {
                                values[rowIndex + lineIndex][cellIndex + columnIndex] = value;
                            }
                            else {
                                onCellChange(rowIndex + lineIndex, col, value);
                            }
                        }
                    }
                }
            });
        });
        if (onGridPaste) {
            onGridPaste(rows, columns, values);
        }
    }, [rows, columns]);
    return (React.createElement("tbody", { ref: tbodyRef, onKeyDown: onKeyDown }, rows.map(function (row, rowIndex) { return (React.createElement(MemoizedCellRow, { key: rowIndex, row: row, dispatch: dispatch, onCellChange: onCellChange, onPaste: onPaste, gridStyle: gridStyle, removeableRows: removeableRows, onRemoveClick: onRemoveClick, columns: columns, rowIndex: rowIndex, selection: selection && selection[rowIndex] })); })));
};
var CellRow = function (_a) {
    var row = _a.row, rowIndex = _a.rowIndex, removeableRows = _a.removeableRows, onRemoveClick = _a.onRemoveClick, selection = _a.selection, dispatch = _a.dispatch, columns = _a.columns, gridStyle = _a.gridStyle, onCellChange = _a.onCellChange, onPaste = _a.onPaste, props = __rest(_a, ["row", "rowIndex", "removeableRows", "onRemoveClick", "selection", "dispatch", "columns", "gridStyle", "onCellChange", "onPaste"]);
    return (React.createElement(Spreadsheet.Row, __assign({}, props),
        columns.map(function (x, cellIndex) {
            var value = x.get(row);
            var cellReadonly = typeof x.readonly === "function" ? x.readonly(row) : x.readonly || false;
            if (x.type === "boolean") {
                return (React.createElement(CheckboxCell, { key: x.id + value, readonly: row.ReadOnly === 1 ? true : false || cellReadonly, selected: selection && selection[cellIndex], value: value, gridStyle: gridStyle, rowIndex: rowIndex, cellIndex: cellIndex, dispatch: dispatch, onCellChange: onCellChange, onPaste: onPaste, column: x }));
            }
            if (x.type === "date") {
                return (React.createElement(DateCell, { key: x.id + value, readonly: row.ReadOnly === 1 ? true : false || cellReadonly, selected: selection && selection[cellIndex], value: value, gridStyle: gridStyle, rowIndex: rowIndex, cellIndex: cellIndex, dispatch: dispatch, onCellChange: onCellChange, onPaste: onPaste, column: x }));
            }
            if (x.options) {
                var option = {
                    label: value.toString(),
                    value: value,
                };
                var options = x.options(row);
                if (x.options(row).length === 1 || cellReadonly) {
                    return (React.createElement(Cell, { key: x.id + value, readonly: true, selected: selection && selection[cellIndex], value: value, gridStyle: gridStyle, rowIndex: rowIndex, cellIndex: cellIndex, dispatch: dispatch, onCellChange: onCellChange, onPaste: onPaste, column: x }));
                }
                return (React.createElement(SelectCell, { key: x.id + value, readonly: row.ReadOnly === 1 ? true : false || cellReadonly, selected: selection && selection[cellIndex], value: option, options: options, gridStyle: gridStyle, rowIndex: rowIndex, cellIndex: cellIndex, dispatch: dispatch, onCellChange: onCellChange, onPaste: onPaste, column: x }));
            }
            return (React.createElement(Cell, { key: x.id + value, readonly: row.ReadOnly === 1 ? true : false || cellReadonly, selected: selection && selection[cellIndex], value: value, gridStyle: gridStyle, rowIndex: rowIndex, cellIndex: cellIndex, dispatch: dispatch, onCellChange: onCellChange, onPaste: onPaste, column: x }));
        }),
        removeableRows ? (React.createElement(Box, { as: "td", border: "none!important", display: row.notSaved ? "table-cell" : "none" },
            React.createElement(Button, { my: 1, mx: 2, variant: "secondary", color: "black", borderColor: "black", fontSize: "sm", fontWeight: "normal", borderRadius: "100%", p: 0, width: 24, height: 24, minHeight: 24, onClick: function () { return onRemoveClick && onRemoveClick(rowIndex); } }, "\u2715"))) : null));
};
function deepEqual(a, b) {
    if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length)
            return false;
        for (var i = 0; i < a.length; i++) {
            if (!deepEqual(a[i], b[i]))
                return false;
        }
        return true;
    }
    if (typeof a === "object" && a !== null && b !== null) {
        if (!(typeof b === "object"))
            return false;
        var keys = Object.keys(a);
        if (keys.length !== Object.keys(b).length)
            return false;
        for (var key in a) {
            if (!deepEqual(a[key], b[key]))
                return false;
        }
        return true;
    }
    return a === b;
}
var MemoizedCellRow = React.memo(CellRow, function (prev, next) {
    return deepEqual(prev, next);
});
var Cell = function (_a) {
    var value = _a.value, rowIndex = _a.rowIndex, cellIndex = _a.cellIndex, readonly = _a.readonly, column = _a.column, selected = _a.selected, gridStyle = _a.gridStyle, onPaste = _a.onPaste, onCellChange = _a.onCellChange, dispatch = _a.dispatch, props = __rest(_a, ["value", "rowIndex", "cellIndex", "readonly", "column", "selected", "gridStyle", "onPaste", "onCellChange", "dispatch"]);
    var inputRef = React.useRef(null);
    var onMouseDown = React.useCallback(function (e) {
        setTimeout(function () {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        });
        dispatch({ type: "UPDATE_IS_SELECTING", payload: { isSelecting: true } });
        // gridDispatch({ type: "SELECT_CELL", payload: { row: rowIndex, cell: columnIndex } })
    }, [rowIndex, cellIndex]);
    var onMouseEnter = React.useCallback(function (e) {
        dispatch({
            type: "SELECT_TO",
            payload: { row: rowIndex, cell: cellIndex },
        });
    }, [dispatch, rowIndex, cellIndex]);
    var onMouseUp = React.useCallback(function (e) {
        dispatch({
            type: "UPDATE_IS_SELECTING",
            payload: { isSelecting: false },
        });
    }, [dispatch]);
    return (React.createElement(Spreadsheet.Column, __assign({ "data-label": column.header.title, selected: selected, gridStyle: gridStyle, readonly: readonly, onMouseDown: onMouseDown, onMouseEnter: onMouseEnter, onMouseUp: onMouseUp, alignment: column.alignment, position: "relative", p: 0, fontWeight: column.bold ? "extrabold" : undefined }, props),
        React.createElement(CellInput, { onPaste: onPaste, onCellChange: onCellChange, column: column, selected: selected, dispatch: dispatch, cellIndex: cellIndex, rowIndex: rowIndex, value: value, readonly: readonly, ref: inputRef })));
};
var DateCell = function (_a) {
    var value = _a.value, options = _a.options, rowIndex = _a.rowIndex, cellIndex = _a.cellIndex, readonly = _a.readonly, column = _a.column, selected = _a.selected, gridStyle = _a.gridStyle, onPaste = _a.onPaste, onCellChange = _a.onCellChange, dispatch = _a.dispatch, props = __rest(_a, ["value", "options", "rowIndex", "cellIndex", "readonly", "column", "selected", "gridStyle", "onPaste", "onCellChange", "dispatch"]);
    var inputRef = React.useRef(null);
    var onMouseDown = React.useCallback(function (e) {
        setTimeout(function () {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        });
        dispatch({ type: "UPDATE_IS_SELECTING", payload: { isSelecting: true } });
        // gridDispatch({ type: "SELECT_CELL", payload: { row: rowIndex, cell: columnIndex } })
    }, [rowIndex, cellIndex]);
    var onMouseEnter = React.useCallback(function (e) {
        dispatch({
            type: "SELECT_TO",
            payload: { row: rowIndex, cell: cellIndex },
        });
    }, [dispatch, rowIndex, cellIndex]);
    var onMouseUp = React.useCallback(function (e) {
        dispatch({
            type: "UPDATE_IS_SELECTING",
            payload: { isSelecting: false },
        });
    }, [dispatch]);
    return (React.createElement(Spreadsheet.Column, __assign({ selected: selected, gridStyle: gridStyle, readonly: readonly, onMouseDown: onMouseDown, onMouseEnter: onMouseEnter, onMouseUp: onMouseUp, alignment: column.alignment, position: "relative", p: 0 }, props),
        React.createElement(Datepicker, { "data-testid": "DateCell", readOnly: readonly, value: value, onFocus: function () {
                dispatch({
                    type: "SELECT_CELL",
                    payload: { row: rowIndex, cell: cellIndex },
                });
            }, onChange: function (d) {
                onCellChange(rowIndex, column, d.toString());
            } })));
};
var CheckboxCell = function (_a) {
    var value = _a.value, options = _a.options, rowIndex = _a.rowIndex, cellIndex = _a.cellIndex, readonly = _a.readonly, column = _a.column, selected = _a.selected, gridStyle = _a.gridStyle, onPaste = _a.onPaste, onCellChange = _a.onCellChange, dispatch = _a.dispatch, props = __rest(_a, ["value", "options", "rowIndex", "cellIndex", "readonly", "column", "selected", "gridStyle", "onPaste", "onCellChange", "dispatch"]);
    var inputRef = React.useRef(null);
    var onMouseDown = React.useCallback(function (e) {
        setTimeout(function () {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        });
        dispatch({ type: "UPDATE_IS_SELECTING", payload: { isSelecting: true } });
        //gridDispatch({ type: "SELECT_CELL", payload: { row: rowIndex, cell: columnIndex } })
    }, [rowIndex, cellIndex]);
    var onMouseEnter = React.useCallback(function (e) {
        dispatch({
            type: "SELECT_TO",
            payload: { row: rowIndex, cell: cellIndex },
        });
    }, [dispatch, rowIndex, cellIndex]);
    var onMouseUp = React.useCallback(function (e) {
        dispatch({
            type: "UPDATE_IS_SELECTING",
            payload: { isSelecting: false },
        });
    }, [dispatch]);
    return (React.createElement(Spreadsheet.Column, __assign({ selected: selected, gridStyle: gridStyle, readonly: readonly, onMouseDown: onMouseDown, onMouseEnter: onMouseEnter, onMouseUp: onMouseUp, alignment: column.alignment, position: "relative", p: 0, css: { justifyContent: "center" } }, props),
        React.createElement(Checkbox, { readOnly: readonly, checked: typeof value === "boolean" ? value : false, onFocus: function () {
                dispatch({
                    type: "SELECT_CELL",
                    payload: { row: rowIndex, cell: cellIndex },
                });
            }, onChange: function (e) {
                onCellChange(rowIndex, column, e.target.checked);
            }, css: { justifyContent: "center" } })));
};
var SelectCell = function (_a) {
    var value = _a.value, options = _a.options, rowIndex = _a.rowIndex, cellIndex = _a.cellIndex, readonly = _a.readonly, column = _a.column, selected = _a.selected, gridStyle = _a.gridStyle, onPaste = _a.onPaste, onCellChange = _a.onCellChange, dispatch = _a.dispatch, props = __rest(_a, ["value", "options", "rowIndex", "cellIndex", "readonly", "column", "selected", "gridStyle", "onPaste", "onCellChange", "dispatch"]);
    var inputRef = React.useRef(null);
    var onMouseDown = React.useCallback(function (e) {
        setTimeout(function () {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        });
        dispatch({ type: "UPDATE_IS_SELECTING", payload: { isSelecting: true } });
        // gridDispatch({ type: "SELECT_CELL", payload: { row: rowIndex, cell: columnIndex } })
    }, [rowIndex, cellIndex]);
    var onMouseEnter = React.useCallback(function (e) {
        dispatch({
            type: "SELECT_TO",
            payload: { row: rowIndex, cell: cellIndex },
        });
    }, [dispatch, rowIndex, cellIndex]);
    var onMouseUp = React.useCallback(function (e) {
        dispatch({
            type: "UPDATE_IS_SELECTING",
            payload: { isSelecting: false },
        });
    }, [dispatch]);
    return (React.createElement(Spreadsheet.Column, __assign({ selected: selected, gridStyle: gridStyle, readonly: readonly, onMouseDown: onMouseDown, onMouseEnter: onMouseEnter, onMouseUp: onMouseUp, alignment: column.alignment, position: "relative", p: 0 }, props),
        React.createElement(Select, { name: "cell", menuPosition: "fixed", value: value, options: options, height: "2.125rem", onFocus: function () {
                dispatch({
                    type: "SELECT_CELL",
                    payload: { row: rowIndex, cell: cellIndex },
                });
            }, onChange: function (x) {
                onCellChange(rowIndex, column, x.value);
            } })));
};
var CellInput = React.forwardRef(function (_a, ref) {
    var value = _a.value, onPaste = _a.onPaste, readonly = _a.readonly, rowIndex = _a.rowIndex, column = _a.column, selected = _a.selected, cellIndex = _a.cellIndex, onCellChange = _a.onCellChange, dispatch = _a.dispatch;
    var _b = React.useState(false), isEditing = _b[0], setIsEditing = _b[1];
    var displayValue = column.format ? column.format(value) : format(value);
    var inputRef = React.useRef(null);
    // @ts-ignore
    React.useImperativeHandle(ref, function () { return ({
        focus: function () {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        },
    }); });
    React.useEffect(function () { }, [selected]);
    var onLocalPaste = function (e) {
        e.preventDefault();
        setIsEditing(false);
        var lines = e.clipboardData.getData("Text").split("\n");
        onPaste(rowIndex, cellIndex, lines);
    };
    var onInputFocus = React.useCallback(function () {
        dispatch({
            type: "SELECT_CELL",
            payload: { row: rowIndex, cell: cellIndex },
        });
    }, [dispatch, rowIndex, cellIndex]);
    var onDisplayFocus = React.useCallback(function () {
        if (!readonly) {
            setIsEditing(true);
        }
        dispatch({
            type: "SELECT_CELL",
            payload: { row: rowIndex, cell: cellIndex },
        });
    }, [dispatch, rowIndex, cellIndex, readonly]);
    var onSubmit = function (e) {
        if (typeof value === "number") {
            var val = Number(e.target.value.replace(",", "."));
            if (!isNaN(val)) {
                if (val !== value) {
                    onCellChange(rowIndex, column, val);
                }
            }
        }
        else {
            if (e.target.value !== value) {
                onCellChange(rowIndex, column, e.target.value);
            }
        }
        setIsEditing(false);
    };
    return isEditing ? (React.createElement("input", { defaultValue: value.toString().replace(".", ","), key: "value " + isEditing + value, onPaste: onLocalPaste, ref: function (input) { return input && input.select(); }, size: value ? value.toString().length + 2 : 3, onFocus: onInputFocus, onKeyDown: function (e) {
            if (e.key === "Enter" || e.key === "Escape") {
                e.target.blur();
            }
        }, onBlur: onSubmit })) : (React.createElement("input", { key: "display " + isEditing + displayValue, defaultValue: displayValue, onSelect: function (e) {
            if (readonly) {
                e.target.selectionStart = e.target.selectionEnd;
            }
        }, ref: inputRef, onPaste: onLocalPaste, readOnly: true, onFocus: onDisplayFocus, size: displayValue ? displayValue.length + 2 : 3 }));
});
Spreadsheet.Header = function (props) {
    return (React.createElement(Box, __assign({ as: "th", p: 2, css: {
            verticalAlign: "top",
            fontVariantNumeric: "tabular-nums",
        }, textAlign: "right" }, props)));
};
Spreadsheet.displayName = "Spreadsheet";
Spreadsheet.Row.displayName = "SpreadsheetRow";
Spreadsheet.Column.displayName = "SpreadsheetColumn";
Spreadsheet.Header.displayName = "SpreadsheetHeader";
export default Spreadsheet;
