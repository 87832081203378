var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Modal, { ModalTitle, ModalCloseButton } from "@skepool/core/src/components/Modal";
import Button from "@skepool/core/src/components/Button";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import Text from "@skepool/core/src/components/Text";
import Input from "@skepool/core/src/components/Input";
import Heading from "@skepool/core/src/components/Heading";
import sortBy from "lodash/sortBy";
import Select from "@skepool/core/src/components/Select";
import { useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "../services/TradeService";
var AccountModal = function (_a) {
    var onClose = _a.onClose, account = _a.account, title = _a.title, profilesData = _a.profiles, onAccountUpdated = _a.onAccountUpdated, parameterSetsData = _a.parameterSets;
    var createAccount = useAsyncCallback(TradeService.createAccount);
    var updateAccount = useAsyncCallback(TradeService.updateAccount);
    var _b = React.useState(account || {
        id: null,
        name: "",
        matris: false,
        storage: {
            defaultParametersetId: null,
            defaultProfileId: null,
        },
    }), draft = _b[0], updateDraft = _b[1];
    var templateProfiles = sortBy(profilesData.filter(function (x) { return x.profileType === 0; }).map(function (x) { return ({ label: x.profileName, value: x.id }); }), "label");
    var customerProfiles = sortBy(profilesData.filter(function (x) { return x.profileType === 1; }).map(function (x) { return ({ label: x.profileName, value: x.id }); }), "label");
    var tradeProfiles = sortBy(profilesData.filter(function (x) { return x.profileType === 2; }).map(function (x) { return ({ label: x.profileName, value: x.id }); }), "label");
    var profiles = [
        {
            label: "Branschprofiler",
            options: tradeProfiles,
        },
        {
            label: "Schablonprofiler",
            options: templateProfiles,
        },
        {
            label: "Kundprofiler",
            options: customerProfiles,
        },
    ];
    var selectedProfile = profilesData.find(function (x) { return String(x.id) === draft.storage["defaultProfileId"]; });
    var profile = selectedProfile ? { label: selectedProfile.profileName, value: selectedProfile.id } : null;
    var selectedParameterSet = parameterSetsData.find(function (x) { return String(x.id) === draft.storage["defaultParametersetId"]; });
    var parameterset = selectedParameterSet
        ? { label: selectedParameterSet.name, value: selectedParameterSet.id }
        : null;
    var parameterSets = parameterSetsData.map(function (x) { return ({ label: x.name, value: x.id }); });
    var onSubmit = function (e) {
        e.preventDefault();
        if (draft.id) {
            updateAccount.execute(draft).then(function (newAccount) {
                if (onAccountUpdated) {
                    onAccountUpdated(newAccount);
                }
                onClose();
            });
        }
        else {
            createAccount.execute(draft).then(function (newAccount) {
                if (onAccountUpdated) {
                    onAccountUpdated({
                        name: draft.name,
                        storage: draft.storage,
                        active: true,
                        matris: draft.matris,
                        id: newAccount,
                    });
                }
                onClose();
            });
        }
    };
    var loading = updateAccount.loading || createAccount.loading;
    var error = updateAccount.error || createAccount.error;
    return (React.createElement(Modal, { onClose: onClose },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, title),
                React.createElement(ModalCloseButton, { onClose: onClose })),
            React.createElement(VStack, { as: "form", spacing: 4, width: "25rem", onSubmit: onSubmit },
                React.createElement(VStack, { spacing: 2 },
                    React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "name" }, "Namn p\u00E5 konto *"),
                    React.createElement(Input, { name: "name", value: draft.name, onChange: function (e) {
                            updateDraft(__assign(__assign({}, draft), { name: e.target.value }));
                        } })),
                React.createElement(VStack, { spacing: 0 },
                    React.createElement(Heading.S5, { color: "black" }, "F\u00F6rvald profil och parameterset"),
                    React.createElement(Text, { fontSize: "xs", color: "grey" }, "Ange f\u00F6rvald profil och parameterset s\u00E5 beh\u00F6ver du inte \u00E4ndra inst\u00E4llningar i offertr\u00E4knaren eller vid handel")),
                React.createElement(VStack, null,
                    React.createElement(VStack, { spacing: 2 },
                        React.createElement(Text, { as: "label", fontSize: "sm" }, "Profil"),
                        React.createElement(Select, { name: "profile", menuPosition: "fixed", placeholder: "V\u00E4lj profil", options: profiles, value: profile, onChange: function (x) {
                                updateDraft(__assign(__assign({}, draft), { storage: __assign(__assign({}, draft.storage), { defaultProfileId: String(x.value) }) }));
                            } })),
                    React.createElement(VStack, { spacing: 2 },
                        React.createElement(Text, { as: "label", fontSize: "sm" }, "Parameterset"),
                        React.createElement(Select, { name: "parameterset", menuPosition: "fixed", placeholder: "V\u00E4lj parameterset", options: parameterSets, value: parameterset, onChange: function (x) {
                                updateDraft(__assign(__assign({}, draft), { storage: __assign(__assign({}, draft.storage), { defaultParametersetId: String(x.value) }) }));
                            } }))),
                error && (React.createElement(Text, { color: "red", fontSize: "xs" }, error.message)),
                React.createElement(Box, null,
                    React.createElement(Button, { loading: loading, type: "submit" }, "Spara"))))));
};
export var useAccountModal = function (onAccountUpdated) {
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = React.useState(null), account = _b[0], setAccount = _b[1];
    var _c = React.useState(null), profiles = _c[0], setProfiles = _c[1];
    var _d = React.useState(null), parameterSets = _d[0], setParameterSets = _d[1];
    var open = function (account, profiles, parameterSets) {
        setIsOpen(true);
        setAccount(account);
        setProfiles(profiles);
        setParameterSets(parameterSets);
    };
    var close = function () {
        setIsOpen(false);
        setAccount(null);
    };
    return {
        CreateAccountModal: isOpen && account === null && profiles && parameterSets ? (React.createElement(AccountModal, { title: "Skapa konto", profiles: profiles, parameterSets: parameterSets, onAccountUpdated: onAccountUpdated, account: account, onClose: close })) : null,
        EditAccountModal: isOpen && account !== null && profiles && parameterSets ? (React.createElement(AccountModal, { title: "Redigera konto", profiles: profiles, parameterSets: parameterSets, onAccountUpdated: onAccountUpdated, account: account, onClose: close })) : null,
        openAccountModal: open,
        closeAccountModal: close,
    };
};
export default AccountModal;
