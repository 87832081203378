var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import ReactHighcharts from "react-highcharts";
// @ts-ignore
import HighchartsExporting from "highcharts-exporting";
HighchartsExporting(ReactHighcharts.Highcharts);
import "./Chart.css";
// @ts-ignore
ReactHighcharts.Highcharts.setOptions({
    credits: {
        enabled: false
    },
    lang: {
        resetZoom: "Zooma ut",
        resetZoomTitle: "Zooma ut",
        noData: "Ingen data",
        downloadJPEG: "Ladda ner jpg",
        downloadPNG: "Ladda ner png",
        downloadPDF: "Ladda ner pdf",
        downloadSVG: "Ladda ner svg",
        printChart: "Skriv ut"
    }
});
export var BASE_COLORS = ["#24b9e3", "#d1d1d1", "#e63888", "#3c1053", "#702082", "#f4633a"];
export var TerminPris = function (_a) {
    var data = _a.data, area = _a.area;
    var chart = {
        area: "SE" + area,
        yTitle: "SEK",
        xTitle: "MWh",
        dates: data.map(function (item) {
            return item.date;
        }),
        series: data.map(function (item) {
            return item.value;
        })
    };
    var config = {
        yAxis: {
            labels: {
                style: {
                    fontSize: ".6rem"
                }
            },
            minRange: 0.1,
            maxPadding: 0,
            title: {
                text: chart.yTitle.toUpperCase() + " / " + chart.xTitle
            }
        },
        series: [
            {
                name: chart.area,
                type: "line",
                data: chart.series,
                color: BASE_COLORS[3],
                tooltip: {
                    valueSuffix: " " + chart.yTitle.toUpperCase() + " / " + chart.xTitle
                }
            }
        ],
        xAxis: {
            categories: chart.dates,
            minTickInterval: 10,
            labels: {
                style: {
                    fontSize: ".6rem"
                },
                rotation: 0
            }
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false
                }
            }
        },
        title: {
            text: undefined
        },
        colors: BASE_COLORS,
        credits: {
            enabled: false
        },
        chart: {
            zoomType: "x",
            height: "300px",
            spacingTop: 20,
            backgroundColor: "transparent"
        },
        tooltip: {
            xDateFormat: "%Y-%m",
            style: {
                padding: 10
            },
            shared: true,
            valueDecimals: 2
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0
        }
    };
    config.tooltip.xDateFormat = "%Y-%w";
    return React.createElement(Chart, { config: config });
};
export var Hydro = function (_a) {
    var data = _a.data, props = __rest(_a, ["data"]);
    var getChartType = function (chart, series) {
        if (chart.type == "hydrological-balance" && series.label !== "Total") {
            return "column";
        }
        return "line";
    };
    var getChartColors = function (chart) {
        if (chart.type == "hydrological-balance") {
            return [BASE_COLORS[0], BASE_COLORS[3], BASE_COLORS[5]];
        }
        return BASE_COLORS;
    };
    var chart = data;
    var config = {
        colors: getChartColors(chart),
        yAxis: {
            // min: 0,
            minRange: 0.1,
            maxPadding: 0,
            title: {
                text: chart.unit
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            zoomType: "x",
            height: 320,
            spacingTop: 8
        },
        tooltip: {
            xDateFormat: "%Y-%m",
            style: {
                padding: 10
            },
            shared: true,
            valueDecimals: 2
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0
        },
        series: chart.data.map(function (c) {
            return {
                name: c.label,
                type: getChartType(chart, c),
                data: c.series.map(function (s) {
                    return [new Date(s.date).getTime(), s.value];
                }),
                tooltip: {
                    valueSuffix: " " + chart.unit
                }
            };
        }),
        title: {
            text: chart.caption,
            align: "left",
            style: {
                fontWeight: 900,
                fontSize: "1.3125rem",
                lineHeight: "1.38",
                fontFamily: "'SK Sans', 'Helvetica Neue', 'Helvetica', sans-serif",
                color: "rgb(51, 51, 51)"
            }
        },
        xAxis: [
            {
                type: "datetime",
                tickInterval: 28 * 24 * 3600 * 1000,
                ordinal: false,
                dateTimeLabelFormats: {
                    second: "%H:%M:%S",
                    minute: "%H:%M",
                    hour: "%H:%M",
                    day: "%Y-%m-%d",
                    week: "%Y-%w",
                    month: "%Y-%m",
                    year: "%Y"
                },
                labels: {
                    rotation: -45
                },
                crosshair: true
            }
        ],
        plotOptions: {
            column: {
                stacking: "normal"
            }
        }
    };
    config.tooltip.xDateFormat = "%Y-%m-%d";
    config.xAxis[0].tickInterval = 28 * 24 * 3600 * 1000;
    return React.createElement(Chart, { config: config });
};
export var Spot = function (_a) {
    var data = _a.data, props = __rest(_a, ["data"]);
    var chart = {
        area: data.area,
        yTitle: data.currency,
        xTitle: data.referenceUnit,
        series: data.spotPrices.map(function (item) {
            return [new Date(item.date * 1000).getTime(), item.price];
        })
    };
    var config = {
        yAxis: {
            labels: {
                style: {
                    fontSize: ".6rem"
                }
            },
            minRange: 0.1,
            maxPadding: 0,
            title: {
                text: chart.yTitle.toUpperCase() + " / " + chart.xTitle
            }
        },
        series: [
            {
                name: chart.area,
                type: "line",
                data: chart.series,
                color: BASE_COLORS[0],
                tooltip: {
                    valueSuffix: " " + chart.yTitle.toUpperCase() + " / " + chart.xTitle
                }
            }
        ],
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
                day: "%Y-%m-%d"
            },
            tickPixelInterval: 50,
            labels: {
                rotation: -45,
                style: {
                    fontSize: ".6rem"
                }
            }
        },
        title: {
            text: ""
        },
        colors: BASE_COLORS,
        credits: {
            enabled: false
        },
        chart: {
            zoomType: "x",
            height: "60%",
            spacingTop: 24,
            backgroundColor: "transparent"
        },
        tooltip: {
            xDateFormat: "%Y-%m-%d",
            style: {
                padding: "1rem"
            },
            shared: true,
            valueDecimals: 2
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0
        }
    };
    return React.createElement(Chart, { config: config });
};
var Chart = React.forwardRef(function (_a, ref) {
    var config = _a.config, props = __rest(_a, ["config"]);
    return React.createElement(ReactHighcharts, __assign({ config: config, ref: ref }, props));
});
export default Chart;
