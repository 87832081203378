import styled from "../styled";
import shouldForwardProp from "@styled-system/should-forward-prop";
import styledCss from "@styled-system/css";
import { space, color, layout, typography, flexbox, grid, position, border, compose } from "styled-system";
var cssInterpolation = function (_a) {
    var css = _a.css;
    var output = styledCss(css);
    return output;
};
var styledProps = compose(space, layout, color, flexbox, position, border, grid, typography);
var PROPS_WHITELIST = ["size", "data-testid", "pattern", "config"];
var Box = styled("div", {
    shouldForwardProp: function (prop) { return shouldForwardProp(prop) || PROPS_WHITELIST.indexOf(prop) !== -1; }
})(styledProps, cssInterpolation);
Box.displayName = "Box";
export default Box;
