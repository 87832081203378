var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from "react";
import ThemeWrapper from "@skepool/core/src/components/ThemeWrapper";
import Box from "@skepool/core/src/components/Box";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import Input, { ControlledNumberInput } from "@skepool/core/src/components/Input";
import Text from "@skepool/core/src/components/Text";
import { InputUnit } from "@skepool/core/src/components/InputUnit";
import InputGroup from "@skepool/core/src/components/InputGroup";
import Select from "@skepool/core/src/components/Select";
import Button from "@skepool/core/src/components/Button";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import Axios from "axios";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import Loader from "@skepool/core/src/components/Loader";
import format from "date-fns/format";
import { STATUS_MAP } from "./MarketAnalysisList";
export var MarketAnalysisService = {
    createMarketAnalysis: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, Axios.post("/api/analysis", payload)];
        });
    }); },
    updateMarketAnalysis: function (id, payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, Axios.put("/api/analysis/" + id, payload)];
        });
    }); },
    getMarketAnalysisList: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, Axios.get("/api/analysis/marknadskommentar?withUnpublished=1").then(function (res) { return res.data.sort(function (a, b) { return b.created_at - a.created_at; }); })];
        });
    }); },
    getMarketAnalysis: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, Axios.get("/api/analysis/" + id).then(function (res) { return res.data; })];
        });
    }); },
};
export var fieldGroups = [
    {
        heading: "Tiodygnsprognos (EC00 NP)",
        fields: {
            temperature: {
                label: "Temperatur",
                type: "number",
                unit: "°C",
            },
            hydrologic_balance: {
                label: "Hydrologisk balans",
                type: "number",
                unit: " TWh",
            },
            precipitation: {
                label: "Nederbörd",
                type: "number",
                unit: " TWh",
            },
            wind: {
                label: "Vind",
                type: "number",
                unit: "%",
            },
        },
    },
    {
        heading: "Spotmarknad",
        fields: {
            temperature_change: {
                label: "Temperatur mot normalt",
                type: "forecast",
                unit: "",
            },
            wind_change: {
                label: "Vindförändring",
                type: "forecast",
                unit: "",
            },
            nuclear: {
                label: "Kärnkraft",
                type: "number",
                unit: "%",
            },
            spotprognosis: {
                label: "Spotprognos",
                type: "number",
                unit: " EUR/MWh",
            },
        },
    },
    {
        heading: "Terminsmarknad",
        fields: {
            ug_current_year_change: {
                label: "UG innevarande år",
                type: "forecast",
                unit: "",
            },
            EUA_change: {
                label: "EUA SPOT",
                type: "forecast",
                unit: "",
            },
            germany_change: {
                label: "Tyskland",
                type: "forecast",
                unit: "",
            },
            gas_change: {
                label: "Gas",
                type: "forecast",
                unit: "",
            },
        },
    },
];
var TRENDS = {
    2: {
        color: "#f4633a",
        rotation: -60,
    },
    1: {
        color: "#f4633a",
        rotation: -30,
    },
    0: {
        color: "#49c6b0",
        rotation: 0,
    },
    3: {
        color: "#00a8e0",
        rotation: 30,
    },
    4: {
        color: "#00a8e0",
        rotation: 60,
    },
    5: {
        color: "black",
        rotation: 0,
    },
};
export var TrendIcon = function (_a) {
    var size = _a.size, trend = _a.trend;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: size, height: size, viewBox: "0 0 100 100", enableBackground: "new 0 0 100 100", style: {
            flexShrink: 0,
            fill: TRENDS[trend].color,
            transform: "rotate(" + TRENDS[trend].rotation + "deg)",
            transformOrigin: "center",
        } },
        React.createElement("path", { d: "M1.743 60.948V40.052c0-1.443 1.17-2.612 2.612-2.612h36.568c1.443 0 2.612-1.17 2.612-2.612v-9.85c0-1.442 1.17-2.61 2.612-2.61.42 0 .836.1 1.21.296L95.824 47.97c1.28.668 1.774 2.246 1.107 3.524-.244.473-.63.86-1.104 1.107l-48.47 25.31c-1.28.67-2.857.173-3.524-1.106-.193-.372-.295-.787-.295-1.21v-9.417c0-1.443-1.17-2.612-2.612-2.612H4.355c-1.443 0-2.612-1.17-2.612-2.61z" })));
};
export var TREND_EXTRA = {
    2: "10%+",
    1: "2-10%+",
    0: "2%+-",
    3: "2-10%-",
    4: "10%-",
    5: "",
};
var TREND_OPTIONS = [
    { value: 2, label: "Ökning 10% eller mer" },
    { value: 1, label: "Ökning 2-10%" },
    { value: 0, label: "Ökning/minskning 2%" },
    { value: 3, label: "Minskning 2-10%" },
    { value: 4, label: "Minskning -10% eller mer" },
    { value: 5, label: "För svaga signaler" },
];
var MarketAnalysis = function (_a) {
    var _comment = _a.comment;
    var _b, _c, _d, _e;
    var _f = useState(_comment), comment = _f[0], setComment = _f[1];
    var _g = useState(comment
        ? {
            id: comment.id,
            type: "marknadskommentar",
            status: comment.status,
            data: comment.data,
        }
        : {
            type: "marknadskommentar",
            status: 1,
            data: {
                default: {
                    key: "default",
                    temperature: 0,
                    precipitation: 0,
                    wind: 0,
                    temperature_change: 5,
                    hydrologic_balance: 0,
                    wind_change: 5,
                    nuclear: 0,
                    spotprognosis: 0,
                    gas_change: 5,
                    ug_current_year_change: 5,
                    EUA_change: 5,
                    germany_change: 5,
                    content: "",
                },
            },
        }), draft = _g[0], setDraft = _g[1];
    var createMarketAnalysis = useAsyncCallback(MarketAnalysisService.createMarketAnalysis);
    var updateMarketAnalysis = useAsyncCallback(MarketAnalysisService.updateMarketAnalysis);
    var publish = useAsyncCallback(function (payload) {
        return MarketAnalysisService.updateMarketAnalysis(payload.id, __assign(__assign({}, payload), { status: 2 }));
    });
    function setData(key, value) {
        setDraft(function (d) {
            var _a;
            return (__assign(__assign({}, d), { data: __assign(__assign({}, d.data), { default: __assign(__assign({}, d.data.default), (_a = {}, _a[key] = value, _a)) }) }));
        });
    }
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            e.preventDefault();
            if (draft.id) {
                updateMarketAnalysis.execute(draft.id, draft).then(function (res) {
                    setComment(res.data);
                    setDraft({
                        id: res.data.id,
                        type: "marknadskommentar",
                        status: res.data.status,
                        data: res.data.data,
                    });
                });
            }
            else {
                createMarketAnalysis.execute(draft).then(function (res) {
                    window.location.hash = "/analys/comment/" + res.data.id;
                });
            }
            return [2 /*return*/];
        });
    }); };
    var onPublish = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (draft.id) {
            publish.execute(draft);
        }
    };
    var error = createMarketAnalysis.error || updateMarketAnalysis.error;
    var fieldErrors = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errors;
    return (React.createElement(Box, { as: "form", onSubmit: handleSubmit, w: "100%", bg: "white" },
        React.createElement(HStack, { alignItems: "flex-start" }, fieldGroups.map(function (group) { return (React.createElement(Box, { flex: "1", key: group.heading },
            React.createElement(Text, { as: "h2", fontWeight: "bold" }, group.heading),
            React.createElement(VStack, { style: { width: "100%" } }, Object.entries(group.fields).map(function (_a) {
                var key = _a[0], field = _a[1];
                return (React.createElement(InputGroup, { width: "100%", maxWidth: 250, key: key },
                    React.createElement(Text, { as: "label", htmlFor: key, fontSize: "sm", mb: 1, display: "block" }, field.label),
                    field.type === "number" ? (React.createElement(Box, { position: "relative", width: "100%" },
                        React.createElement(ControlledNumberInput, { error: fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors["data.default." + key], required: true, name: key, value: draft.data.default[key], onChange: function (value) {
                                setData(key, value);
                            } }),
                        React.createElement(InputUnit, null, field.unit))) : null,
                    field.type === "forecast" ? (React.createElement(React.Fragment, null,
                        React.createElement(Select, { options: TREND_OPTIONS, name: key, value: TREND_OPTIONS.find(function (x) { return x.value === draft.data.default[key]; }), 
                            // @ts-ignore
                            formatOptionLabel: function (_a) {
                                var value = _a.value, label = _a.label;
                                return (React.createElement(HStack, { spacing: "2" },
                                    React.createElement(TrendIcon, { size: 16, trend: value }),
                                    React.createElement(Text, { fontSize: "xs" }, label)));
                            }, onChange: function (x) {
                                setData(key, x.value);
                            } }),
                        (fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors["data.default." + key]) ? (React.createElement(Text, { color: "red", fontSize: "sm" }, fieldErrors["data.default." + key])) : null)) : null));
            })))); })),
        React.createElement(Input, { as: "textarea", width: "100%", placeholder: "Skriv din analys h\u00E4r...", value: draft.data.default.content, minHeight: 150, onChange: function (e) {
                var value = e.target.value;
                setData("content", value);
            } }),
        error ? (React.createElement(Text, { color: "red", fontSize: "sm" }, ((_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message) || error.message)) : null,
        React.createElement(HStack, null,
            React.createElement(Button, { loading: publish.loading || updateMarketAnalysis.loading || createMarketAnalysis.loading, type: "submit", mt: "4" }, "Spara"),
            draft.id && comment ? (React.createElement(HStack, { alignItems: "flex-end" },
                React.createElement(Button, { onClick: onPublish, loading: publish.loading, disabled: draft.status === 2 }, "Publicera"),
                React.createElement(VStack, { justifyContent: "flex-end", spacing: 1 },
                    React.createElement(Text, { fontSize: "sm" },
                        React.createElement("b", null, "Sparad senast:"), " " + format(new Date(comment.updated_at * 1000), "yyyy-MM-dd HH:mm")),
                    React.createElement(Text, { fontSize: "sm" },
                        React.createElement("b", null, "Status:"), " " + STATUS_MAP[draft.status])))) : null)));
};
export var MarketAnalysisWithThemeWrapper = function (props) {
    return (React.createElement(ThemeWrapper, null,
        React.createElement(MarketAnalysisContainer, null)));
};
var MarketAnalysisFormSkeleton = function () { return (React.createElement(Box, { className: "react-app", style: {
        width: "75rem",
        margin: "2rem auto",
        float: "left",
    } },
    React.createElement(Box, { p: "2rem", bg: "white" },
        React.createElement(Loader, null)))); };
var MarketAnalysisContainer = function (props) {
    var hash = window.location.hash.split("/");
    var string_id = hash[hash.length - 1];
    var id = string_id === "new" || !string_id ? -1 : Number(string_id);
    var getAnalysis = useAsync(function () { return (id === -1 ? Promise.resolve(null) : MarketAnalysisService.getMarketAnalysis(id)); }, [id]);
    if (getAnalysis.loading) {
        return React.createElement(MarketAnalysisFormSkeleton, null);
    }
    if (getAnalysis.error) {
        return React.createElement(ErrorFallback, { error: getAnalysis.error });
    }
    return (React.createElement(Box, { className: "react-app", style: {
            width: "75rem",
            margin: "2rem auto",
            float: "left",
        } },
        React.createElement(Box, { p: "2rem", bg: "white" },
            React.createElement(MarketAnalysis, { comment: getAnalysis.result }))));
};
export default MarketAnalysisContainer;
