var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import Spreadsheet from "@skepool/core/src/components/Spreadsheet";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import Loader from "@skepool/core/src/components/Loader";
import { VStack } from "@skepool/core/src/components/Layout";
import Button from "@skepool/core/src/components/Button";
import TradeService from "../../services/TradeService";
import { sumByProp, SWE } from "../../utils";
import HistoricalTradeModal from "../../modals/HistoricalTradeModal";
var HistoricalTradesElcert = function (_a) {
    var priceType = _a.priceType, area = _a.area, account = _a.account;
    var type = "elcert";
    var data = useAsync(function () { return TradeService.getHistoricalPrices(type, area); }, [area]);
    var trade = useAsyncCallback(TradeService.historicalTradeElcert);
    var _b = React.useState(null), orderPayload = _b[0], setOrderPayload = _b[1];
    var _c = React.useState(null), transactionIds = _c[0], setTransactionIds = _c[1];
    var onCellChange = function (row, column, value) {
        if (data.result) {
            var newRows = __spreadArrays(data.result);
            column.set(newRows[row], value);
            data.setResult(newRows);
        }
    };
    var columns = [
        {
            alignment: "left",
            header: {
                title: "Produkt",
                subtitle: "År-Månad"
            },
            type: "string",
            id: "product",
            readonly: true,
            get: function (r) { return r.product.name; },
            set: function (r, val) { return (r.product.name = val); },
            format: function (n) { return n.toString(); }
        },
        {
            alignment: "right",
            header: {
                title: "Antal",
                subtitle: "st"
            },
            get: function (r) { return r.electricityCertificateVolume; },
            set: function (r, val) { return (r.electricityCertificateVolume = val); },
            id: "electricityCertificateVolume"
        },
        {
            alignment: "right",
            header: {
                title: "Pris",
                subtitle: "SEK"
            },
            get: function (r) { return r[priceType].electricityCertificatePrice.SEK; },
            set: function (r, val) { return (r[priceType].electricityCertificatePrice.SEK = val); },
            id: "electricityCertificatePrice"
        }
    ];
    if (data.loading) {
        return (React.createElement(Box, { height: 200, width: 352, position: "relative" },
            React.createElement(Loader, null)));
    }
    else if (data.error) {
        return React.createElement(Text, null, data.error.message);
    }
    if (!data.result) {
        return null;
    }
    var rows = data.result;
    var totals = {
        electricityCertificateVolume: sumByProp(rows, "electricityCertificateVolume")
    };
    var onOrder = function () {
        setOrderPayload(__assign({}, totals));
    };
    var onOrderClose = function (success) {
        if (trade.loading)
            return;
        if (success) {
            trade
                .execute({
                accountId: account,
                withMail: 1,
                area: area,
                rows: rows
                    .filter(function (x) { return x.electricityCertificateVolume !== 0; })
                    .map(function (x) { return ({
                    product: x.product.id,
                    electricityCertificatePrice: x[priceType].electricityCertificatePrice.SEK,
                    electricityCertificateVolume: x.electricityCertificateVolume
                }); })
            })
                .then(function (transactionId) {
                if (typeof transactionId === "string") {
                    setTransactionIds([transactionId]);
                }
                else {
                    setTransactionIds(transactionId);
                }
            });
        }
        else {
            setOrderPayload(null);
            setTransactionIds(null);
        }
    };
    return (React.createElement(VStack, { py: 2, spacing: 4, alignItems: "flex-start" },
        React.createElement(Button, { onClick: onOrder }, "Registrera"),
        React.createElement(Box, null,
            React.createElement(Spreadsheet.Table, { "data-testid": "Spreadsheet-SVK", onCellChange: onCellChange, dataColumns: columns, dataRows: data.result })),
        React.createElement(Button, { onClick: onOrder }, "Registrera"),
        orderPayload && (React.createElement(HistoricalTradeModal, { loading: trade.loading, error: trade.error, onClose: onOrderClose, transactionIds: transactionIds },
            React.createElement(VStack, { spacing: 1, mt: 2 },
                React.createElement(Text, { fontWeight: "black", fontSize: "sm" }, "Best\u00E4ll"),
                React.createElement(Box, { as: "table", css: { borderCollapse: "collapse" }, minWidth: "100%" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement(Text, { fontWeight: "bold", fontSize: "sm" }, "Elcertifikat")),
                            React.createElement("td", null,
                                React.createElement(Text, { fontWeight: "bold", fontSize: "sm" }, SWE.format(orderPayload.electricityCertificateVolume) + " st"))))))))));
};
export default HistoricalTradesElcert;
