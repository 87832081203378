var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import format from "date-fns/format";
import { hasPermission } from "../utils";
var handleTradeError = function (err) {
    var res = err.response;
    if (res && res.data && res.data.errors && res.data.errors.rows) {
        throw new Error(res.data.errors.rows.join(", "));
    }
    if (res && res.data && res.data.messages && res.data.messages.rows) {
        throw new Error(res.data.messages.rows.join(", "));
    }
    if (res && res.data && res.data.message) {
        throw new Error(res.data.message);
    }
    throw err;
};
var checkDeviceType = function () {
    var userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
        return "Android";
    }
    else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return "iOS";
    }
    else if (/Windows Phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    return "Desktop";
};
var TradeService = {
    openBankId: function (token) {
        if (checkDeviceType() === "Desktop") {
            return window.open("bankid:///?autostarttoken=" + token + "&redirect=null", "_blank");
        }
        return window.open("https://app.bankid.com/?autostarttoken=" + token + "&redirect=null", "_bankid");
    },
    getAreas: function () {
        return axios.get("api/areas").then(function (res) { return res.data; });
    },
    getAccounts: function () {
        return axios.get("api/accounts").then(function (res) { return res.data; });
    },
    createAccount: function (payload) {
        return axios
            .post("api/accounts", { name: payload.name, storage: payload.storage })
            .then(function (res) { return res.data; });
    },
    updateAccount: function (payload) {
        return axios.put("api/accounts/" + payload.id, payload).then(function (res) { return res.data; });
    },
    getProfiles: function () {
        return axios.get("api/profiles").then(function (res) { return res.data; });
    },
    getProfile: function (profile) {
        return axios.get("api/profiles/" + profile.id).then(function (res) { return res.data; });
    },
    createProfile: function (payload) {
        return axios.post("api/profiles", payload).then(function (res) { return res.data; });
    },
    updateProfile: function (payload) {
        return axios.put("api/profiles/" + payload.id, payload).then(function (res) { return res.data; });
    },
    getParameterSets: function () {
        return axios.get("api/parametersets").then(function (res) { return res.data; });
    },
    createParameterset: function (payload) {
        return axios.post("api/parametersets", payload).then(function (res) { return res.data; });
    },
    updateParameterset: function (payload) {
        return axios.put("api/parametersets/" + payload.id, payload).then(function (res) { return res.data; });
    },
    getProducts: function () {
        return axios.get("api/tbd/products").then(function (res) { return res.data; });
    },
    getTakprisProducts: function () {
        return axios.get("api/takpris/products").then(function (res) { return res.data; });
    },
    getCertificateQuotas: function () {
        return axios.get("api/handel/electricity-certificate-quotas").then(function (res) { return res.data; });
    },
    sign: function (payload) {
        return axios
            .put("/api/core/bankid/sign", payload)
            .then(function (res) {
            return res.data.result;
        });
    },
    signPassword: function (payload) {
        return axios.put("/api/core/sign", payload).then(function (res) {
            if (res.data.result.SignedStatus === 0) {
                throw new Error("Fel lösenord");
            }
            return res.data.result;
        });
    },
    getSignQRCodeUrl: function () {
        return "/api/core/bankid/qrcodesign";
    },
    getSignStatus: function () {
        return axios.get("/api/core/bankid/sign");
    },
    getUGProductsAndRows: function (deliveryType, type, addfund) {
        return __awaiter(this, void 0, void 0, function () {
            var products, rows;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios
                            .get("/api/ug/products", {
                            params: { addfund: addfund ? -1 : 0, pricetype: type }
                        })
                            .then(function (res) { return res.data; })];
                    case 1:
                        products = _a.sent();
                        rows = products.reduce(function (acc, t) {
                            var current = acc.find(function (x) {
                                return x.name === t.name && x.YearOfConsumption === t.YearOfConsumption;
                            });
                            if (current) {
                                if (current.products) {
                                    current.products.push(t);
                                }
                            }
                            else {
                                acc.push({
                                    name: t.name,
                                    Value: 0,
                                    YearOfConsumption: t.YearOfConsumption,
                                    YearOfProduction: t.YearOfProduction,
                                    Bid_sek: t.Bid_sek,
                                    Ask_sek: t.Ask_sek,
                                    Pricemaster_sek: t.Pricemaster_sek,
                                    TypeOfDelivery: deliveryType,
                                    Volume: 0,
                                    products: [t],
                                    Class: t.Class,
                                    DeliveryDate: t.DeliveryDate,
                                    FundFee: t.FundFee,
                                    BraMiljo: t.BraMiljo
                                });
                            }
                            acc.forEach(function (x) {
                                var last_price = null;
                                x.products.forEach(function (y, index) {
                                    var price = type === "ask" ? y.Ask_sek : y.Bid_sek;
                                    y.price = { SEK: price };
                                    if (last_price === null) {
                                        last_price = price;
                                        x.YearOfProduction = y.YearOfProduction;
                                        x.Ask_sek = y.Ask_sek;
                                        x.Bid_sek = y.Bid_sek;
                                    }
                                    else {
                                        if (price < last_price) {
                                            x.YearOfProduction = y.YearOfProduction;
                                            x.Ask_sek = y.Ask_sek;
                                            x.Bid_sek = y.Bid_sek;
                                        }
                                    }
                                    last_price = price;
                                });
                            });
                            return acc;
                        }, []);
                        return [2 /*return*/, { products: products, rows: rows }];
                }
            });
        });
    },
    getCurrentUser: function () {
        return axios.get("/api/core/current").then(function (res) { return res.data; });
    },
    getIsOpen: function () {
        return axios.get("/api/handel/is-open").then(function (res) { return res.data; });
    },
    getOfferTradeConfig: function () {
        return __awaiter(this, void 0, void 0, function () {
            var currentUser, _a, isOpen, areas, accounts, profiles, parameterSets, products, UGOptions;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, TradeService.getCurrentUser()];
                    case 1:
                        currentUser = _b.sent();
                        return [4 /*yield*/, Promise.all([
                                TradeService.getIsOpen(),
                                TradeService.getAreas(),
                                TradeService.getAccounts(),
                                TradeService.getProfiles(),
                                TradeService.getParameterSets(),
                                TradeService.getProducts(),
                                hasPermission("MILJOPROD", currentUser.permissions)
                                    ? TradeService.getAvailableUGOffers({})
                                    : Promise.resolve([])
                            ])];
                    case 2:
                        _a = _b.sent(), isOpen = _a[0], areas = _a[1], accounts = _a[2], profiles = _a[3], parameterSets = _a[4], products = _a[5], UGOptions = _a[6];
                        return [2 /*return*/, {
                                isOpen: isOpen,
                                areas: areas,
                                accounts: accounts,
                                profiles: profiles,
                                parameterSets: parameterSets,
                                products: products,
                                currentUser: currentUser,
                                UGOptions: UGOptions
                            }];
                }
            });
        });
    },
    getTradeConfig: function (takpris) {
        if (takpris === void 0) { takpris = false; }
        return __awaiter(this, void 0, void 0, function () {
            var currentUser, _a, areas, accounts, profiles, parameterSets, products, quotas, isOpen, UGOptions;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, TradeService.getCurrentUser()];
                    case 1:
                        currentUser = _b.sent();
                        return [4 /*yield*/, Promise.all([
                                TradeService.getAreas(),
                                TradeService.getAccounts(),
                                TradeService.getProfiles(),
                                TradeService.getParameterSets(),
                                takpris ? TradeService.getTakprisProducts() : TradeService.getProducts(),
                                TradeService.getCertificateQuotas(),
                                TradeService.getIsOpen(),
                                hasPermission("MILJOPROD", currentUser.permissions)
                                    ? TradeService.getAvailableUGOffers({})
                                    : Promise.resolve([])
                            ])];
                    case 2:
                        _a = _b.sent(), areas = _a[0], accounts = _a[1], profiles = _a[2], parameterSets = _a[3], products = _a[4], quotas = _a[5], isOpen = _a[6], UGOptions = _a[7];
                        return [2 /*return*/, {
                                areas: areas,
                                accounts: accounts,
                                profiles: profiles,
                                parameterSets: parameterSets,
                                products: products,
                                currentUser: currentUser,
                                quotas: quotas,
                                isOpen: isOpen,
                                UGOptions: UGOptions
                            }];
                }
            });
        });
    },
    getOffersMultiple: function (params) {
        return Promise.all(params.map(function (p) { return TradeService.getOffers(p); }));
    },
    getOffers: function (params) {
        return axios
            .get("api/handel/offertrade", {
            params: {
                from: format(params.from, "yyyy-MM"),
                to: format(params.to, "yyyy-MM"),
                areas: params.areas,
                accountId: params.account,
                profileId: params.profile,
                parameterSetId: params.parameterSet,
                ugname: params.ugname,
                bFundFee: params.bFundFee ? 1 : 0,
                ranges: "ALL",
                withElectricityCertificate: params.withElectricityCertificate ? 1 : 0,
                volume: params.volume ? params.volume : undefined
            }
        })
            .then(function (res) { return res.data; })
            .catch(function (e) {
            var _a, _b;
            if ((_a = e.response) === null || _a === void 0 ? void 0 : _a.data.message) {
                return Promise.reject((_b = e.response) === null || _b === void 0 ? void 0 : _b.data.message);
            }
            return Promise.reject(e);
        });
    },
    getAvailableUGOffers: function (params) {
        return axios
            .get("api/handel/offertradeug", {
            params: params
        })
            .then(function (res) { return res.data; });
    },
    customTrade: function (payload) {
        return axios
            .post("/api/handel/trade/custom", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    fromOfferTrade: function (payload) {
        return axios
            .post("api/handel/trade/from/offer-calculator", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    getCombinedVolumesAndPrices: function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var result, volumeRows, priceRows, rows;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([TradeService.getVolumes(params), TradeService.getPrices(params)])];
                case 1:
                    result = _a.sent();
                    volumeRows = result[0];
                    priceRows = result[1];
                    rows = priceRows.reduce(function (acc, row, index) {
                        var r = __assign(__assign({}, volumeRows[index]), priceRows[index]);
                        acc.push(r);
                        return acc;
                    }, []);
                    return [2 /*return*/, rows];
            }
        });
    }); },
    getCombinedVolumesAndPricesMarch: function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var result, volumeRows, priceRows, rows;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([TradeService.getVolumes(params), TradeService.getMarchPrices(params)])];
                case 1:
                    result = _a.sent();
                    volumeRows = result[0];
                    priceRows = result[1];
                    rows = priceRows.reduce(function (acc, row, index) {
                        var r = __assign(__assign({}, volumeRows[index]), priceRows[index]);
                        acc.push(r);
                        return acc;
                    }, []);
                    return [2 /*return*/, rows];
            }
        });
    }); },
    getVolumes: function (params) {
        var _params = __assign(__assign({}, params), { from: format(params.from, "yyyy-MM"), to: format(params.to, "yyyy-MM"), withElectricityCertificate: params.withElectricityCertificate ? 1 : 0, volume: params.volume ? params.volume : 10000, ugname: params.ugname, bFundFee: params.bFundFee ? 1 : 0 });
        return axios
            .get("api/handel/volumes", { params: _params })
            .then(function (res) { return res.data[params.areas] || res.data["1"]; })
            .then(function (rows) {
            if (!params.volume) {
                return rows.map(function (x) {
                    x.electricityDerivativeVolume = 0;
                    x.electricityCertificateVolume = 0;
                    return x;
                });
            }
            return rows;
        })
            .catch(function (e) {
            var _a, _b;
            if ((_b = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) {
                return Promise.reject(e.response.data.message);
            }
            return Promise.reject(e);
        });
    },
    getSpotProducts: function (params) {
        var _params = __assign(__assign({}, params), { from: format(params.from, "yyyy-MM"), to: format(params.to, "yyyy-MM"), withElectricityCertificate: params.withElectricityCertificate ? 1 : 0, volume: params.volume ? params.volume : 10000 });
        return axios
            .get("api/handel/prices/spot", { params: _params })
            .then(function (res) {
            return res.data.map(function (x) {
                x.electricityCertificateVolume = 0;
                x.electricityCertificateValue = 0;
                return x;
            });
        });
    },
    getPriceUpdateTime: function () {
        return axios.get("api/handel/time-price-update");
    },
    getPrices: function (params) {
        var _params = __assign(__assign({}, params), { from: format(params.from, "yyyy-MM"), to: format(params.to, "yyyy-MM"), withElectricityCertificate: params.withElectricityCertificate ? 1 : 0, volume: params.volume ? params.volume : 10000, ugname: params.ugname, bFundFee: params.bFundFee ? 1 : 0 });
        return axios
            .get("api/handel/prices", { params: _params })
            .then(function (res) { return res.data[params.areas] || res.data["1"]; })
            .catch(function (e) {
            var _a, _b;
            if ((_b = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) {
                return Promise.reject(e.response.data.message);
            }
            return Promise.reject(e);
        });
    },
    observe: function (payload) {
        return axios
            .post("api/handel/observe", payload)
            .then(function (res) { return res.data; })
            .catch(function (e) {
            if (e.response.data.errors) {
                var messages_1 = e.response.data.errors;
                var errors = Object.keys(messages_1).reduce(function (acc, key) {
                    acc += messages_1[key].join(", ") + "\n";
                    return acc;
                }, "");
                throw new Error(errors);
            }
            if (e.response.data.message) {
                try {
                    var json = JSON.parse(e.response.data.message);
                    return Promise.reject(json);
                }
                catch (e) {
                    return Promise.reject(e.response.data.message);
                }
            }
            if (e.response.data.messages) {
                var messages_2 = e.response.data.messages;
                var errors = Object.keys(messages_2).reduce(function (acc, key) {
                    acc += messages_2[key].join(", ") + "\n";
                    return acc;
                }, "");
                throw new Error(errors);
            }
            else {
                throw e;
            }
        });
    },
    getReciept: function (transactionId) {
        return axios
            .get("/api/handel/transactiongroup/" + transactionId + "/grouped")
            .then(function (res) { return res.data; })
            .then(function (x) {
            x.groupedByProducts = x.groupedByProducts.map(function (item) {
                if (!Array.isArray(item.buy)) {
                    if (item.buy.electricityCertificatePrice === undefined) {
                        item.buy.electricityCertificateVolume = 0;
                        item.buy.electricityCertificateValue = {
                            SEK: 0
                        };
                        item.buy.electricityCertificatePrice = {
                            SEK: 0
                        };
                    }
                }
                else if (!Array.isArray(item.sell)) {
                    if (item.sell.electricityCertificatePrice === undefined) {
                        item.sell.electricityCertificateVolume = 0;
                        item.sell.electricityCertificateValue = {
                            SEK: 0
                        };
                        item.sell.electricityCertificatePrice = {
                            SEK: 0
                        };
                    }
                }
                return item;
            });
            return x;
        });
    },
    getUGReciept: function (transactionIds) {
        var reciepts = transactionIds.map(function (id) {
            return axios.get("/api/handel/transactiongroup/" + id + "/grouped").then(function (res) { return res.data; });
        });
        return Promise.all(reciepts);
    },
    getMarchPrices: function (params) {
        return axios
            .get("api/handel/prices/march", { params: params })
            .then(function (res) { return res.data; });
    },
    getCombinedTakprisVolumesAndPrices: function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var result, volumeRows, priceRows, rows;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            TradeService.getTakprisVolume(params),
                            TradeService.getTakprisPrices(params)
                        ])];
                    case 1:
                        result = _a.sent();
                        volumeRows = result[0];
                        priceRows = result[1].list[params.areas] || result[1].list["1"];
                        rows = priceRows.reduce(function (acc, row, index) {
                            var r = __assign(__assign({}, volumeRows[index]), priceRows[index]);
                            if (params.takpris) {
                                r.takpris = params.takpris;
                            }
                            acc.push(r);
                            return acc;
                        }, []);
                        return [2 /*return*/, {
                                rows: rows,
                                header: result[1].header,
                                takpris: (params.takprisOptions && params.takprisOptions.map(function (x) { return x.value; })) || result[1].takpris
                            }];
                }
            });
        });
    },
    getTakprisPrices: function (params) {
        return axios
            .get("api/handel/prices/takpris", { params: params })
            .then(function (res) { return res.data; });
    },
    getTakprisVolume: function (params) {
        return axios
            .get("api/handel/volumes/takpris", { params: params })
            .then(function (res) { return res.data[params.areas] || res.data["1"]; });
    },
    offerTrade: function (params) {
        return axios.post("api/handel/trade/from/offer-calculator", params);
    },
    spotTrade: function (payload) {
        // Executes a trade and returns the id of the transaction
        return axios
            .post("api/handel/trade/spot", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    marchTrade: function (payload) {
        // Executes a trade and returns the id of the transaction
        return axios
            .post("api/handel/trade/march", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    ugTrade: function (payload) {
        return axios
            .post("api/ug/trade", payload)
            .then(function (res) {
            if (res.data === -1 || res.data === "-1") {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2 || res.data === "-2") {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    // External trades / Historical trades
    getHistoricalPrices: function (type, area) {
        if (type == "termcert") {
            return axios.get("/api/handel/historical-prices/" + area).then(function (res) {
                return res.data.map(function (x) {
                    x.electricityDerivativeVolume = 0;
                    x.electricityCertificateVolume = 0;
                    return x;
                });
            });
        }
        else {
            return axios.get("/api/handel/historical-prices/certificate/" + area).then(function (res) {
                return res.data.map(function (x) {
                    x.electricityCertificateVolume = 0;
                    x.electricityDerivativeVolume = 0;
                    return x;
                });
            });
        }
    },
    getHistoricalPricesUG: function (type) {
        if (type === void 0) { type = "external"; }
        return __awaiter(void 0, void 0, void 0, function () {
            var products;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/handel/historical-prices/ug").then(function (res) {
                            return res.data.map(function (x) {
                                var p = __assign(__assign({}, x.product), { Ask_sek: x.buy.SEK, Bid_sek: x.sell.SEK, Pricemaster_sek: x.pricemaster.SEK, Volume: x.volume, Value: 0, FundFee: x.fundfee });
                                if (type === "historical") {
                                    p.DeliveryDate = format(new Date(), "yyyy-MM-dd");
                                }
                                return p;
                            });
                        })];
                    case 1:
                        products = _a.sent();
                        return [2 /*return*/, { products: products }];
                }
            });
        });
    },
    historicalTradeUG: function (payload) {
        return axios.post("/api/handel/historical-prices/trade/ug", payload).then(function (res) {
            if (res.data === -1 || res.data === "-1") {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2 || res.data === "-2") {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    historicalTradeCustom: function (payload) {
        return axios
            .post("/api/handel/historical-prices/trade/custom", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    historicalTradeElcert: function (payload) {
        return axios
            .post("/api/handel/historical-prices/trade/elcert", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    externalTradeCustom: function (payload) {
        return axios
            .post("api/handel/external/trade/custom", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    externalTradeElcert: function (payload) {
        return axios
            .post("api/handel/external/trade/elcert", payload)
            .then(function (res) {
            if (res.data === -1) {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2) {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    externalTradeUG: function (payload) {
        return axios
            .post("api/external/ug/trade", payload)
            .then(function (res) {
            if (res.data === -1 || res.data === "-1") {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2 || res.data === "-2") {
                throw new Error("Skepool är stängt");
            }
            return res.data;
        }, handleTradeError);
    },
    counterTradeGroup: function (id, message) {
        return axios
            .post("/api/counter-trade/group/" + id, { message: message })
            .then(function (res) { return res.data; });
    },
    counterTradeTransactions: function (ids, message) {
        return axios
            .post("/api/counter-trade/transactions", { transactionIds: ids, message: message })
            .then(function (res) { return res.data; });
    },
    getNasdaqPriceAreas: function () {
        return axios.get("/api/nasdaq/priceareas").then(function (res) { return res.data; });
    },
    getNasdaqProducts: function (currency) {
        return axios
            .get("/api/nasdaq/productlist/" + currency)
            .then(function (res) {
            var _a;
            if (!res.data.NasProd) {
                throw new Error("Just nu finns det inga handelsprodukter");
            }
            if (((_a = res.data.NasProd) === null || _a === void 0 ? void 0 : _a.length) === 1 && res.data.NasProd[0].Error !== "") {
                return [];
            }
            return res.data.NasProd;
        })
            .then(function (rows) {
            return rows.map(function (x) {
                x.Value = 0;
                x.Volume = 0;
                return x;
            });
        })
            .catch(function (e) {
            var _a, _b;
            if ((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) {
                throw new Error(e.response.data.message);
            }
            else {
                throw e;
            }
        });
    },
    getNasdaqReciept: function (transactionId) {
        return axios.get("api/nasdaq/transactiongroup/" + transactionId).then(function (res) { return res.data; });
    },
    tradeNasdaq: function (payload) {
        return axios.post("api/nasdaq/trade", payload).then(function (res) {
            if (res.data === -1 || res.data === "-1") {
                throw new Error("Handel misslyckades");
            }
            else if (res.data === -2 || res.data === "-2") {
                throw new Error("Skepool är stängt");
            }
            else if (res.data === -3 || res.data === "-3") {
                throw new Error("Maxvolume exceeded");
            }
            return res.data;
        }, handleTradeError);
    }
};
export default TradeService;
