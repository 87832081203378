import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
var TrackingContext = React.createContext(null);
export var TrackingProvider = function (_a) {
    var children = _a.children;
    var trackEvent = function (event) { };
    var trackPageView = function (page) {
        var ga = window.ga;
        ga && ga("send", "pageview", page);
    };
    var trackLink = function (target, href, action, label) {
        if (target === void 0) { target = "normal"; }
        return function (e) {
            var ga = window.ga;
            var normalClick = !(e.ctrlKey || e.shiftKey || e.metaKey || e.button === 1);
            if (target === "normal" && normalClick) {
                e.preventDefault();
                ga && ga("send", "event", window.location.pathname, action, label);
                window.location.href = href;
            }
            else {
                ga && ga("send", "event", window.location.pathname, action, label);
            }
        };
    };
    return (React.createElement(TrackingContext.Provider, { value: { trackEvent: trackEvent, trackPageView: trackPageView, trackLink: trackLink } }, children));
};
export var useTracking = function () {
    var ctx = React.useContext(TrackingContext);
    if (!ctx) {
        throw new Error("useTracking needs to be used inside a TrackingProvider");
    }
    return ctx;
};
var TrackingCallback = function (props) {
    useRouteTracking();
    return null;
};
export var TrackingRoute = function () {
    return React.createElement(Route, { render: function () { return React.createElement(TrackingCallback, null); } });
};
var useRouteTracking = function () {
    var trackPageView = useTracking().trackPageView;
    var history = useHistory();
    useEffect(function () {
        trackPageView(history.location.pathname);
    }, [history.action, history.location.pathname, trackPageView]);
};
