var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import useLocalStorage from "./useLocalStorage";
import { useHistory, Route } from "react-router-dom";
var TrackingCallback = function (props) {
    useLastVisited();
    return null;
};
export var LastVisitedTracker = function () {
    return React.createElement(Route, { component: TrackingCallback });
};
export var useLastVisitedRoutes = function () {
    var _a = useLocalStorage("ui-last-visited-routes", []), lastVisited = _a[0], setLastVisited = _a[1];
    return lastVisited;
};
var useLastVisited = function () {
    var history = useHistory();
    var _a = useLocalStorage("ui-last-visited-routes", []), lastVisited = _a[0], setLastVisited = _a[1];
    React.useEffect(function () {
        var path = history.location.pathname;
        if (lastVisited.length > 4) {
            var _1 = lastVisited[0], newLastVisited = lastVisited.slice(1);
            if (newLastVisited.indexOf(path) === -1 && path !== "/") {
                newLastVisited.push(path);
                setLastVisited(newLastVisited);
            }
        }
        else {
            var newLastVisited = __spreadArrays(lastVisited);
            if (newLastVisited.indexOf(path) === -1 && path !== "/") {
                newLastVisited.push(path);
                setLastVisited(newLastVisited);
            }
        }
    }, [history.location.pathname]);
};
