var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { HStack } from "@skepool/core/src/components/Layout";
var Filters = function (props) { return (React.createElement(HStack, __assign({ alignItems: "flex-end", spacing: 4, flexWrap: "wrap", css: {
        "> *:not(:last-child)": [
            {
                mr: 3,
                flexBasis: "calc(50% - 1rem)"
            },
            {
                flexBasis: "auto",
                mb: 3,
                mr: 4
            }
        ]
    } }, props))); };
export default Filters;
