var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import qs from "query-string";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import Filters from "@skepool/handel/src/components/Filters";
import InputGroup from "@skepool/core/src/components/InputGroup";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "@skepool/handel/src/services/TradeService";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import Loader from "@skepool/core/src/components/Loader";
import Select from "@skepool/core/src/components/Select";
import AdminService from "@skepool/handel/src/services/AdminService";
import Button, { Touchable } from "@skepool/core/src/components/Button";
import { hasPermission, SWE, serialize } from "@skepool/handel/src/utils";
import ReportsService from "../services/ReportsService";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { ReportTableRow, ReportSubtitle, ReportTableColumn, ReportTableHeader, ReportTable, useDynamicAccounts, CompaniesFilterSelect, } from "./TransactionsPage";
import { useHistory, useParams } from "react-router-dom";
import { Tabs, TabPanels, TabList, TabPanel, Tab } from "@skepool/core/src/components/Tabs";
import ErrorBoundary from "react-error-boundary";
import Chart, { BASE_COLORS } from "@skepool/core/src/components/Chart";
import { PageHeader } from "@skepool/core/src/components/PageHeader";
import { Page } from "@skepool/core/src/components/Page";
import { EditModalIcon, AddModalIcon } from "@skepool/core/src/components/Icons";
import FilterModal from "../modals/FilterModal";
import { useCompaniesFilter } from "./HistoricalPricesPage";
export var SavedFilters = function (_a) {
    var currentUser = _a.currentUser, accounts = _a.accounts, companies = _a.companies, companiesData = _a.companiesData, accountsData = _a.accountsData, filters = _a.filters, filter = _a.filter, setFilter = _a.setFilter, onFiltersChange = _a.onFiltersChange, getCompanyIds = _a.getCompanyIds;
    var filterOptions = filters ? filters.map(function (x) { return ({ label: x.name, value: x.name }); }) : [];
    var _b = React.useState(), editFilterPayload = _b[0], setEditFilterPayload = _b[1];
    var _c = React.useState(), addFilterPayload = _c[0], setAddFilterPayload = _c[1];
    return (React.createElement(InputGroup, { width: "auto", minWidth: ["100%", 224] },
        React.createElement(Text, { as: "label", fontSize: "sm" },
            React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                React.createElement("span", null, "Sparade filter"),
                React.createElement(HStack, { spacing: 2 },
                    React.createElement(Touchable, { p: 0, borderRadius: 0, css: { overflow: "visible" }, onClick: function () {
                            var f = filters.find(function (x) { return x.name === (filter === null || filter === void 0 ? void 0 : filter.label); });
                            if (f) {
                                setEditFilterPayload(f);
                            }
                        } },
                        React.createElement(Box, { "aria-label": "Redigera", "data-balloon-pos": "up" },
                            React.createElement(EditModalIcon, null))),
                    React.createElement(Touchable, { p: 0, borderRadius: 0, css: { overflow: "visible" }, onClick: function () {
                            setAddFilterPayload(true);
                        } },
                        React.createElement(Box, { "aria-label": "L\u00E4gg till", "data-balloon-pos": "up" },
                            React.createElement(AddModalIcon, null)))))),
        React.createElement(Select, { name: "filter", options: filterOptions, placeholder: "V\u00E4lj filter", isClearable: true, value: filter, onChange: function (x) {
                setFilter(x);
            } }),
        editFilterPayload && accountsData !== undefined && (React.createElement(FilterModal, { title: "Redigera filter", currentUser: currentUser, accountsData: accountsData, filters: filters, filter: editFilterPayload, defaultAccounts: accounts, defaultCompany: companies, companiesData: companiesData, onClose: function (u, f) {
                if (u) {
                    onFiltersChange(u);
                    if (f) {
                        // @ts-ignore
                        setFilter({ label: f.name, value: f.name, isFixed: false });
                    }
                    else {
                        setFilter(null);
                    }
                }
                setEditFilterPayload(undefined);
            } })),
        addFilterPayload && accountsData !== undefined && (React.createElement(FilterModal, { title: "L\u00E4gg till filter", currentUser: currentUser, filters: filters, defaultAccounts: accounts, defaultCompany: companies, companiesData: companiesData, accountsData: accountsData, filter: {
                name: "",
                values: {
                    accountId: accounts
                        .filter(function (x) { return x.value !== -1 && x.value !== -2; })
                        .map(function (x) { return x.value; })
                        .join(","),
                    companyId: getCompanyIds().companyIds,
                },
            }, onClose: function (u, f) {
                if (u) {
                    onFiltersChange(u);
                    if (f) {
                        // @ts-ignore
                        setFilter({ label: f.name, value: f.name, isFixed: false });
                    }
                    else {
                        setFilter(null);
                    }
                }
                setAddFilterPayload(undefined);
            } }))));
};
var MatrixPortfolio = function (_a) {
    var companiesData = _a.companies, userAccounts = _a.accounts, currentUser = _a.currentUser, type = _a.type, onFiltersChange = _a.onFiltersChange, filters = _a.filters, defaults = _a.defaults;
    var chartRef = React.useRef(null);
    var getSummary = useAsyncCallback(ReportsService.getMatrisPortfolio);
    var getPortfolioPDFLink = useAsyncCallback(ReportsService.getMatrisPortfolioPDF);
    var isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
    var isSysAdmin = hasPermission("USER_IS_SYSADMIN", currentUser.permissions);
    var _b = React.useState(null), filter = _b[0], setFilter = _b[1];
    var currentFilter = filters && filter ? filters.find(function (x) { return x.name === filter.label; }) : undefined;
    var _c = useCompaniesFilter(isAdmin, currentUser, companiesData, currentFilter, function () {
        setFilter(null);
    }), companies = _c.companies, companyOptions = _c.companyOptions, allCompaniesSelected = _c.allCompaniesSelected, allActiveCompaniesSelected = _c.allActiveCompaniesSelected, getCompanyIds = _c.getCompanyIds, onCompaniesChange = _c.onCompaniesChange;
    var _d = useDynamicAccounts(companies, allCompaniesSelected, allActiveCompaniesSelected, currentUser, userAccounts, currentFilter), accountOptions = _d.accountOptions, accounts = _d.accounts, accountsLoading = _d.accountsLoading, setAccounts = _d.setAccounts, defaultAccount = _d.defaultAccount, accountsData = _d.accountsData;
    var tradeTypes = [
        {
            value: -1,
            label: "Alla",
        },
        {
            value: 2,
            label: "Extern handel",
        },
    ];
    if (isSysAdmin) {
        tradeTypes.push({
            value: 1,
            label: "Historisk handel",
        });
        tradeTypes.push({
            value: 0,
            label: "Vanlig + historisk",
        });
    }
    var defaultTradeType = isSysAdmin ? tradeTypes[tradeTypes.length - 1] : tradeTypes[0];
    var includesNormalAccounts = accounts.some(function (x) { return !x.matris && x.value !== -1; });
    var getAccountIds = function () {
        var _a;
        if (JSON.stringify(accounts) === JSON.stringify([defaultAccount])) {
            return ((_a = accountsData === null || accountsData === void 0 ? void 0 : accountsData.filter(function (x) { return x.matris; }).map(function (x) { return x.id; }).join(",")) !== null && _a !== void 0 ? _a : "");
        }
        return accounts
            .filter(function (x) { return x.value !== -1; })
            .map(function (x) { return x.value; })
            .join(",");
    };
    var defaultParams = {
        currency: "sek",
        mainAccount: -1,
        matris: includesNormalAccounts ? -1 : 1,
        priceArea: "0",
        userId: 0,
        prodTypes: type,
        iHandelstyp: defaultTradeType.value,
        accountId: getAccountIds(),
        companyId: isAdmin ? companies.map(function (x) { return x.value; })[0] : currentUser.company.id,
    };
    var onPDFDdownload = function () {
        if (chartRef.current) {
            var chartsvg = chartRef.current.getChart().getSVG();
            getPortfolioPDFLink
                .execute(__assign(__assign({}, defaultParams), { chartsvg: chartsvg }))
                .then(function (res) {
                location.href = "/api/rapport/pdfreport?file=" + res.pdf;
            });
        }
    };
    var onUpdate = function () {
        getSummary.execute(defaultParams);
    };
    var error = getSummary.error;
    var loading = getSummary.loading;
    var summary = getSummary.result;
    var isAccountsDisabled = isAdmin
        ? allCompaniesSelected || allActiveCompaniesSelected || accountsLoading || companies.length > 1
        : accountsLoading;
    return (React.createElement(Box, { px: 4, py: 3 },
        React.createElement(Filters, null,
            isAdmin && (React.createElement(CompaniesFilterSelect, { isAdmin: isAdmin, companies: companies, companyOptions: companyOptions, onCompaniesChange: onCompaniesChange })),
            React.createElement(InputGroup, { width: "auto", maxWidth: 400, minWidth: ["100%", 224] },
                React.createElement(Text, { as: "label", fontSize: "sm", style: { opacity: isAccountsDisabled ? 0.5 : 1 } }, "Konton"),
                React.createElement(Select, { height: "auto", name: "accounts", disabled: isAccountsDisabled, isMulti: true, closeMenuOnSelect: false, options: [
                        {
                            label: "",
                            options: accountOptions.filter(function (x) { return x.value === -1 || x.value === -2; }),
                        },
                        {
                            label: "Matriskonton",
                            options: accountOptions.filter(function (x) { return x.matris && x.value !== -1 && x.value !== -2; }),
                        },
                        {
                            label: "Konton",
                            options: accountOptions.filter(function (x) { return !x.matris && x.value !== -1 && x.value !== -2; }),
                        },
                    ], value: accounts, isClearable: !accounts.some(function (x) { return x.value === -1; }), onChange: function (x, meta) {
                        if (meta.action === "clear" || !x || meta.option === defaultAccount) {
                            setAccounts([defaultAccount]);
                        }
                        else if (meta.option && meta.option.value === -2) {
                            setAccounts(accountOptions.filter(function (x) { return x.active && x.value !== -1 && x.value !== -2; }));
                        }
                        else {
                            setAccounts(x.filter(function (y) { return y.value !== -1 && x.value !== -2; }));
                        }
                    } })),
            React.createElement(SavedFilters, { currentUser: currentUser, accountsData: accountsData, companiesData: companiesData, onFiltersChange: onFiltersChange, accounts: accounts, companies: companies, filters: filters, filter: filter, setFilter: setFilter, getCompanyIds: getCompanyIds })),
        React.createElement(Filters, null,
            React.createElement(InputGroup, { width: "auto", minWidth: "auto", justifyContent: "flex-end" },
                React.createElement(Button, { loading: loading, disabled: accountsLoading, onClick: onUpdate }, "H\u00E4mta rapport"))),
        error && React.createElement(ErrorFallback, { error: error }),
        summary && (React.createElement(Box, null,
            React.createElement(Box, { maxWidth: 800 },
                type === "eltermin" && (React.createElement(VStack, null,
                    React.createElement(MatrixPortfolioChartTerms, { ref: chartRef, rows: summary.Transactions }),
                    React.createElement(Box, { display: "flex", justifyContent: "center" },
                        React.createElement(Button, { loading: getPortfolioPDFLink.loading, onClick: onPDFDdownload }, "Ladda ner pdf")),
                    React.createElement(MatrixPortfolioTableTerms, { total: summary.total, rows: summary.Transactions }))),
                type === "elcert" && (React.createElement(VStack, null,
                    React.createElement(MatrixPortfolioChartElcert, { ref: chartRef, rows: summary.Transactions }),
                    React.createElement(Box, { display: "flex", justifyContent: "center" },
                        React.createElement(Button, { loadidng: getPortfolioPDFLink.loading, onClick: onPDFDdownload }, "Ladda ner pdf")),
                    React.createElement(MatrixPortfolioTableElcert, { total: summary.total, rows: summary.Transactions }))))))));
};
var MatrixPortfolioTableTerms = function (_a) {
    var rows = _a.rows, total = _a.total;
    return (React.createElement(ReportTable, { mt: 4, minWidth: 800 },
        React.createElement("thead", null,
            React.createElement(ReportTableRow, null,
                React.createElement(ReportTableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(ReportTableHeader, null,
                    "S\u00E4krad volym",
                    React.createElement(ReportSubtitle, null, "MWh")),
                React.createElement(ReportTableHeader, null,
                    "S\u00E4krat pris",
                    React.createElement(ReportSubtitle, null, "SEK/MWh")),
                React.createElement(ReportTableHeader, null, "Andel av prognos"),
                React.createElement(ReportTableHeader, null,
                    "R\u00F6rligt elpris",
                    React.createElement(ReportSubtitle, null, "SEK/MWh")),
                React.createElement(ReportTableHeader, null, "Andel"),
                React.createElement(ReportTableHeader, null,
                    "Budgetpris",
                    React.createElement(ReportSubtitle, null, "SEK/MWh")))),
        React.createElement("tbody", null, rows.map(function (r) { return (React.createElement(ReportTableRow, { key: r.product },
            React.createElement(ReportTableColumn, { textAlign: "left" }, format(parse(r.product, "yyyyMM", new Date()), "yyyy-MM")),
            React.createElement(ReportTableColumn, null, SWE.format(r.sumVolume)),
            React.createElement(ReportTableColumn, null, SWE.format(r.priceAvg)),
            React.createElement(ReportTableColumn, null,
                SWE.format(Number(r.partAgreed.replace(",", ".")) || 0),
                "%"),
            React.createElement(ReportTableColumn, null, SWE.format(r.variablePrice)),
            React.createElement(ReportTableColumn, null,
                SWE.format(Number(r.part.replace(",", ".")) || 0),
                "%"),
            React.createElement(ReportTableColumn, null, SWE.format(r.budgetPrice)))); })),
        React.createElement("tbody", null, total && (React.createElement(ReportTableRow, { bg: "offwhite" },
            React.createElement(ReportTableColumn, { fontWeight: "bold", textAlign: "left" }, "Summa"),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.sumVolume)),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.priceAvg)),
            React.createElement(ReportTableColumn, { fontWeight: "bold" },
                SWE.format(Number(total.partAgreed) || 0),
                "%"),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.variablePrice)),
            React.createElement(ReportTableColumn, { fontWeight: "bold" },
                SWE.format(Number(total.part) || 0),
                "%"),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.budgetPrice)))))));
};
var MatrixPortfolioTableElcert = function (_a) {
    var rows = _a.rows, total = _a.total;
    return (React.createElement(ReportTable, { mt: 4, minWidth: 800 },
        React.createElement("thead", null,
            React.createElement(ReportTableRow, null,
                React.createElement(ReportTableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(ReportTableHeader, null,
                    "S\u00E4krad volym",
                    React.createElement(ReportSubtitle, null, "st")),
                React.createElement(ReportTableHeader, null,
                    "S\u00E4krat pris",
                    React.createElement(ReportSubtitle, null, "SEK/st")),
                React.createElement(ReportTableHeader, null, "Andel av prognos"),
                React.createElement(ReportTableHeader, null,
                    "R\u00F6rligt elpris",
                    React.createElement(ReportSubtitle, null, "SEK/st")),
                React.createElement(ReportTableHeader, null, "Andel"),
                React.createElement(ReportTableHeader, null,
                    "Budgetpris",
                    React.createElement(ReportSubtitle, null, "SEK/st")))),
        React.createElement("tbody", null, rows.map(function (r) { return (React.createElement(ReportTableRow, { key: r.product },
            React.createElement(ReportTableColumn, { textAlign: "left" }, format(parse(r.product, "yyyyMM", new Date()), "yyyy-MM")),
            React.createElement(ReportTableColumn, null, SWE.format(r.certificateEnergyVolume)),
            React.createElement(ReportTableColumn, null, SWE.format(r.certificateAveragePrice)),
            React.createElement(ReportTableColumn, null,
                SWE.format(Number(r.certificatePartAgreed.replace(",", ".")) || 0),
                "%"),
            React.createElement(ReportTableColumn, null, SWE.format(r.certificateVariablePrice)),
            React.createElement(ReportTableColumn, null,
                SWE.format(Number(r.certificatePart.replace(",", ".")) || 0),
                "%"),
            React.createElement(ReportTableColumn, null, SWE.format(r.certificateBudgetPrice)))); })),
        React.createElement("tbody", null, total && (React.createElement(ReportTableRow, { bg: "offwhite" },
            React.createElement(ReportTableColumn, { fontWeight: "bold", textAlign: "left" }, "Summa"),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.certificateEnergyVolume)),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.certificateAveragePrice)),
            React.createElement(ReportTableColumn, { fontWeight: "bold" },
                SWE.format(Number(total.certificatePartAgreed) || 0),
                "%"),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.certificateVariablePrice)),
            React.createElement(ReportTableColumn, { fontWeight: "bold" },
                SWE.format(Number(total.certificatePart) || 0),
                "%"),
            React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(total.certificateBudgetPrice)))))));
};
var getChartConfigTerm = function (rows) {
    var volumeMin = Math.min.apply(null, rows.map(function (p) {
        return p.sumVolume;
    }));
    var volumeMax = Math.max.apply(null, rows.map(function (p) {
        return p.sumVolume;
    }));
    var predictionMin = Math.min.apply(null, rows.map(function (p) {
        return p.prognosisVolume;
    }));
    var predictionMax = Math.max.apply(null, rows.map(function (p) {
        return p.prognosisVolume;
    }));
    var max = Math.max(volumeMax, predictionMax);
    return {
        yAxis: [
            {
                // maxPadding: 0.2,
                min: 0,
                max: max,
                minRange: 10,
                maxPadding: 0,
                title: {
                    text: "Volymer MWh",
                },
            },
            {
                title: {
                    text: "Budgeterat pris SEK/MWh",
                },
                opposite: true,
            },
        ],
        series: [
            {
                name: "Prissäkrat",
                type: "column",
                data: rows.map(function (x) {
                    return [parse(x.product, "yyyyMM", new Date()).getTime(), x.sumVolume];
                }),
                yAxis: 0,
                tooltip: {
                    valueSuffix: " MWh",
                },
            },
            {
                name: "Prognosvolym",
                type: "column",
                data: rows.map(function (x) {
                    return [parse(x.product, "yyyyMM", new Date()).getTime(), x.prognosisVolume];
                }),
                yAxis: 0,
                tooltip: {
                    valueSuffix: " MWh",
                },
            },
            {
                name: "Budgeterat pris",
                type: "line",
                yAxis: 1,
                data: rows.map(function (x) {
                    return [parse(x.product, "yyyyMM", new Date()).getTime(), x.budgetPrice];
                }),
                tooltip: {
                    valueSuffix: " SEK",
                },
            },
        ],
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
                month: "%Y-%m",
            },
            tickPixelInterval: 50,
            labels: {
                rotation: -45,
                style: {
                    fontSize: ".6rem",
                },
            },
        },
        title: {
            text: "",
        },
        colors: BASE_COLORS,
        credits: {
            enabled: false,
        },
        chart: {
            zoomType: "x",
            height: "45%",
            spacingTop: 24,
            backgroundColor: "transparent",
        },
        tooltip: {
            xDateFormat: "%Y-%m-%d",
            style: {
                padding: "1rem",
            },
            shared: true,
            valueDecimals: 2,
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0,
        },
    };
};
var getChartConfigElcert = function (rows) {
    var volumeMin = Math.min.apply(null, rows.map(function (p) {
        return p.certificateEnergyVolume;
    }));
    var volumeMax = Math.max.apply(null, rows.map(function (p) {
        return p.certificateEnergyVolume;
    }));
    var predictionMin = Math.min.apply(null, rows.map(function (p) {
        return p.certificatePrognosisVolume;
    }));
    var predictionMax = Math.max.apply(null, rows.map(function (p) {
        return p.certificatePrognosisVolume;
    }));
    var max = Math.max(volumeMax, predictionMax);
    return {
        yAxis: [
            {
                // maxPadding: 0.2,
                min: 0,
                max: max,
                minRange: 10,
                maxPadding: 0,
                title: {
                    text: "Volymer antal",
                },
            },
            {
                title: {
                    text: "Budgeterat pris SEK/st",
                },
                opposite: true,
            },
        ],
        series: [
            {
                name: "Prissäkrat",
                type: "column",
                data: rows.map(function (x) {
                    return [parse(x.product, "yyyyMM", new Date()).getTime(), x.certificateEnergyVolume];
                }),
                yAxis: 0,
                tooltip: {
                    valueSuffix: " st",
                },
            },
            {
                name: "Prognosvolym",
                type: "column",
                data: rows.map(function (x) {
                    return [parse(x.product, "yyyyMM", new Date()).getTime(), x.certificatePrognosisVolume];
                }),
                yAxis: 0,
                tooltip: {
                    valueSuffix: " st",
                },
            },
            {
                name: "Budgeterat pris",
                type: "line",
                yAxis: 1,
                data: rows.map(function (x) {
                    return [parse(x.product, "yyyyMM", new Date()).getTime(), x.certificateBudgetPrice];
                }),
                tooltip: {
                    valueSuffix: " SEK",
                },
            },
        ],
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
                month: "%Y-%m",
            },
            tickPixelInterval: 50,
            labels: {
                rotation: -45,
                style: {
                    fontSize: ".6rem",
                },
            },
        },
        title: {
            text: "",
        },
        colors: BASE_COLORS,
        credits: {
            enabled: false,
        },
        chart: {
            zoomType: "x",
            height: "45%",
            spacingTop: 24,
            backgroundColor: "transparent",
        },
        tooltip: {
            xDateFormat: "%Y-%m-%d",
            style: {
                padding: "1rem",
            },
            shared: true,
            valueDecimals: 2,
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            borderWidth: 0,
        },
    };
};
var MatrixPortfolioChartTerms = React.memo(React.forwardRef(function (_a, ref) {
    var rows = _a.rows;
    return (React.createElement(VStack, null,
        React.createElement(Chart, { config: getChartConfigTerm(rows), ref: ref })));
}));
var MatrixPortfolioChartElcert = React.memo(React.forwardRef(function (_a, ref) {
    var rows = _a.rows;
    return (React.createElement(VStack, null,
        React.createElement(Chart, { config: getChartConfigElcert(rows), ref: ref })));
}));
var Service = {
    getConfig: function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentUser, isAdmin, companies, accounts, userSettings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TradeService.getCurrentUser()];
                case 1:
                    currentUser = _a.sent();
                    isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
                    if (!isAdmin) return [3 /*break*/, 3];
                    return [4 /*yield*/, AdminService.getCompanies()];
                case 2:
                    companies = _a.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, ReportsService.getAccounts(true)];
                case 4:
                    accounts = _a.sent();
                    _a.label = 5;
                case 5: return [4 /*yield*/, ReportsService.getFilterSettings("matrisrapport")];
                case 6:
                    userSettings = _a.sent();
                    return [2 /*return*/, {
                            currentUser: currentUser,
                            userSettings: userSettings,
                            companies: companies,
                            accounts: accounts,
                        }];
            }
        });
    }); },
};
var MatrixSkeleton = function (_a) {
    var children = _a.children;
    return (React.createElement(Box, null,
        React.createElement(PageHeader, null,
            React.createElement(Text, { fontSize: "xl", fontWeight: "black" }, "Matrishandelsrapport")),
        children));
};
var MatrixPortfolioPage = function () {
    var params = useParams();
    var data = useAsync(function () { return Service.getConfig(); }, []);
    var history = useHistory();
    var search = qs.parse(history.location.search, { decode: true });
    var searchIsEmpty = JSON.stringify(search) === JSON.stringify({});
    var defaults = searchIsEmpty ? undefined : search;
    var onFilterChange = function (u) {
        if (data.result) {
            data.setResult(__assign(__assign({}, data.result), { userSettings: u }));
        }
    };
    if (data.loading) {
        return (React.createElement(MatrixSkeleton, null,
            React.createElement(Box, { height: 200, position: "relative" },
                React.createElement(Loader, null))));
    }
    if (data.error) {
        return (React.createElement(MatrixSkeleton, null,
            React.createElement(ErrorFallback, { error: data.error })));
    }
    var res = data.result;
    if (!res) {
        return null;
    }
    var tabs = [
        {
            path: "terminer",
            name: "Terminer",
            render: function () { return (React.createElement(MatrixPortfolio, { type: "eltermin", defaults: defaults, filters: res.userSettings.filters, companies: res.companies, onFiltersChange: onFilterChange, accounts: res.accounts, currentUser: res.currentUser })); },
        },
        {
            path: "elcertifikat",
            name: "Elcertifikat",
            render: function () { return (React.createElement(MatrixPortfolio, { type: "elcert", defaults: defaults, filters: res.userSettings.filters, onFiltersChange: onFilterChange, companies: res.companies, accounts: res.accounts, currentUser: res.currentUser })); },
        },
    ];
    var currentIndex = tabs.findIndex(function (x) { return x.path === params.type; });
    return (React.createElement(MatrixSkeleton, null,
        React.createElement(Page, null,
            React.createElement(Tabs, { bg: "transparent", mt: "-23px", index: currentIndex === -1 ? 0 : currentIndex, onChange: function (index) {
                    return history.push("/matrishandelsrapport/" + tabs[index].path + (defaults ? serialize(defaults) : ""));
                } },
                React.createElement(TabList, { px: 4, py: [3, 0], bg: "offwhite" }, tabs.map(function (t) { return (React.createElement(Tab, { key: t.name }, t.name)); })),
                React.createElement(TabPanels, null, tabs.map(function (t) { return (React.createElement(TabPanel, { key: t.name, renderOnlyWhenSelected: true, py: 0, bg: "white" },
                    React.createElement(ErrorBoundary, { FallbackComponent: ErrorFallback },
                        React.createElement(Box, { minHeight: "calc(100vh - 100px)" }, t.render())))); }))))));
};
export default MatrixPortfolioPage;
