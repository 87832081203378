var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Spreadsheet from "@skepool/core/src/components/Spreadsheet";
import { useAsync, useAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "../../services/TradeService";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import Loader from "@skepool/core/src/components/Loader";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import { VStack } from "@skepool/core/src/components/Layout";
import Button from "@skepool/core/src/components/Button";
import { SWE, sumByProp } from "../../utils";
import Filters from "../../components/Filters";
import InputGroup from "@skepool/core/src/components/InputGroup";
import Select from "@skepool/core/src/components/Select";
import HistoricalTradeModal from "../../modals/HistoricalTradeModal";
import format from "date-fns/format";
import uniqBy from "lodash-es/uniqBy";
import sortBy from "lodash-es/sortBy";
import parse from "date-fns/parse";
import { getUgPriceTypeFromPriceType } from "../ExternalTrades/ExternalTradesUG";
export var setPriceToLowest = function (type) { return function (x) {
    var last_price = null;
    x.products.forEach(function (y, index) {
        var price = y.Bid_sek;
        if (last_price === null) {
            last_price = price;
            x[type] = y[type];
            x.Ask_sek = y.Ask_sek;
            x.Bid_sek = y.Bid_sek;
        }
        else {
            if (price < last_price) {
                x[type] = y[type];
                x.Ask_sek = y.Ask_sek;
                x.Bid_sek = y.Bid_sek;
            }
        }
        last_price = price;
    });
    return x;
}; };
export var groupByConsumptionYear = function (products) {
    var transactions = products.reduce(function (acc, t) {
        var current = acc.find(function (x) {
            return x.name === t.name && x.YearOfConsumption === t.YearOfConsumption;
        });
        if (current) {
            if (current.products) {
                current.products.push(t);
            }
        }
        else {
            acc.push({
                name: t.name,
                Value: 0,
                YearOfConsumption: t.YearOfConsumption,
                YearOfProduction: t.YearOfProduction,
                Bid_sek: t.Bid_sek,
                Ask_sek: t.Ask_sek,
                Pricemaster_sek: t.Pricemaster_sek,
                TypeOfDelivery: "annulera",
                Volume: 0,
                products: [t],
                Class: t.Class,
                DeliveryDate: t.DeliveryDate,
                FundFee: t.FundFee,
                BraMiljo: t.BraMiljo,
            });
        }
        return acc;
    }, []);
    var rows = sortBy(transactions, function (x) {
        return parse(x.YearOfConsumption.toString(), "yyyy", new Date()).getTime();
    });
    return rows;
};
export var groupByProductionYear = function (products) {
    var transactions = products.reduce(function (acc, t) {
        var current = acc.find(function (x) {
            return x.name === t.name && x.YearOfProduction === t.YearOfProduction;
        });
        if (current) {
            if (current.products) {
                current.products.push(t);
            }
        }
        else {
            acc.push({
                name: t.name,
                Value: 0,
                YearOfConsumption: t.YearOfConsumption,
                YearOfProduction: t.YearOfProduction,
                Bid_sek: t.Bid_sek,
                Ask_sek: t.Ask_sek,
                Pricemaster_sek: t.Pricemaster_sek,
                TypeOfDelivery: "annulera",
                Volume: 0,
                products: [t],
                Class: t.Class,
                DeliveryDate: t.DeliveryDate,
                FundFee: t.FundFee,
                BraMiljo: t.BraMiljo,
            });
        }
        return acc;
    }, []);
    var rows = sortBy(transactions, function (x) {
        return parse(x.YearOfProduction.toString(), "yyyy", new Date()).getTime();
    });
    return rows;
};
var HisstoricalTradesUG = function (_a) {
    var priceType = _a.priceType, accounts = _a.accounts;
    var data = useAsync(function () { return TradeService.getHistoricalPricesUG("historical"); }, []);
    var types = [
        { label: "Förbrukning", value: "ask" },
        { label: "Produktion", value: "bid" },
    ];
    var _b = React.useState(types[0]), type = _b[0], setType = _b[1];
    var products = data.result && data.result.products;
    var _c = React.useState(null), product = _c[0], setProduct = _c[1];
    var trade = useAsyncCallback(TradeService.historicalTradeUG);
    var _d = React.useState(null), orderPayload = _d[0], setOrderPayload = _d[1];
    var _e = React.useState(null), transactionIds = _e[0], setTransactionIds = _e[1];
    var productNames = products ? Array.from(new Set(products.map(function (x) { return x.name; }))) : [];
    var productOptions = productNames.map(function (x) { return ({ label: x, value: x }); });
    var _f = React.useState([]), productRows = _f[0], setProductRows = _f[1];
    var ugPriceType = getUgPriceTypeFromPriceType(priceType);
    React.useEffect(function () {
        if (product === null && productOptions.length) {
            setProduct(productOptions[0]);
        }
    }, [productOptions, product]);
    React.useEffect(function () {
        if (product !== null && products) {
            var rows = type.value === "bid" ? groupByProductionYear(products) : groupByConsumptionYear(products);
            rows = rows.filter(function (x) { return x.name === product.value; });
            rows =
                type.value === "bid"
                    ? rows.map(setPriceToLowest("YearOfConsumption"))
                    : rows.map(setPriceToLowest("YearOfProduction"));
            var newRows = uniqBy(rows, function (x) {
                if (type.value === "bid") {
                    return x.YearOfProduction;
                }
                else {
                    return x.YearOfConsumption;
                }
            });
            var newProductRows = newRows.map(function (r) {
                var y = r.products.find(function (x) { return x.YearOfProduction === r.YearOfProduction; });
                if (y) {
                    r.YearOfProduction = y.YearOfProduction;
                    r[ugPriceType] = y[ugPriceType];
                }
                return r;
            });
            // console.log(rows, Array.from(new Set(rows.map((x) => x.YearOfProduction))), newProductRows);
            setProductRows(newProductRows);
        }
    }, [product, products, type, ugPriceType]);
    if (data.loading) {
        return (React.createElement(Box, { height: 200, width: 630, position: "relative" },
            React.createElement(Loader, null)));
    }
    else if (data.error) {
        return React.createElement(ErrorFallback, { error: data.error });
    }
    if (!data.result) {
        return null;
    }
    var consumptionColumn = {
        id: "förbrukningsår",
        alignment: "left",
        header: {
            title: "Förbrukingsår",
            subtitle: "",
        },
        format: function (n) { return n.toString(); },
        get: function (r) { return r.YearOfConsumption; },
        set: function (r, val) {
            var y = r.products.find(function (x) { return x.YearOfConsumption === val; });
            if (y) {
                r.YearOfConsumption = y.YearOfConsumption;
                r[ugPriceType] = y[ugPriceType];
            }
        },
        readonly: type.value === "ask",
        options: function (r) {
            return r.products.map(function (x) { return ({ label: x.YearOfConsumption.toString(), value: x.YearOfConsumption }); });
        },
    };
    var productionColumn = {
        id: "produktionsår",
        alignment: "left",
        header: {
            title: "Produktionsår",
            subtitle: "",
        },
        format: function (n) { return n.toString(); },
        get: function (r) { return r.YearOfProduction; },
        set: function (r, val) {
            var y = r.products.find(function (x) { return x.YearOfProduction === val; });
            if (y) {
                r.YearOfProduction = y.YearOfProduction;
                r[ugPriceType] = y[ugPriceType];
            }
        },
        readonly: type.value === "bid",
        options: function (r) {
            return r.products.map(function (x) { return ({ label: x.YearOfProduction.toString(), value: x.YearOfProduction }); });
        },
    };
    var deliveryDateColumn = {
        id: "deliverydate",
        type: "date",
        alignment: "left",
        header: {
            title: "Leveransdatum",
            subtitle: "",
        },
        get: function (r) { return r.DeliveryDate; },
        set: function (r, val) {
            var d = format(new Date(val), "yyyy-MM-dd");
            r.DeliveryDate = d;
        },
    };
    var restColumns = [
        {
            id: "volume",
            alignment: "right",
            header: {
                title: "Antal",
                subtitle: "st",
            },
            get: function (r) { return r.Volume; },
            set: function (r, val) {
                if (type.value === "bid") {
                    if (val < 0) {
                        r.Volume = val;
                    }
                    else {
                        r.Volume = r.Volume;
                    }
                }
                else {
                    if (val > 0) {
                        r.Volume = val;
                    }
                    else {
                        r.Volume = r.Volume;
                    }
                }
            },
            format: function (n) { return SWE.format(n); },
        },
        {
            id: "price",
            alignment: "right",
            header: {
                title: "Pris",
                subtitle: "SEK / st",
            },
            get: function (r) { return r[ugPriceType]; },
            set: function (r, val) {
                r[ugPriceType] = val;
            },
            format: function (n) { return SWE.format(n); },
        },
    ];
    var columns;
    if (type.value === "bid") {
        columns = __spreadArrays([productionColumn], restColumns, [deliveryDateColumn]);
    }
    else {
        columns = __spreadArrays([consumptionColumn], restColumns, [productionColumn, deliveryDateColumn]);
    }
    var onCellChange = function (row, column, value) {
        if (productRows) {
            var newRows = __spreadArrays(productRows);
            column.set(newRows[row], value);
            setProductRows(newRows);
        }
    };
    var totals = {
        Volume: sumByProp(productRows, "Volume"),
    };
    var onOrder = function () {
        if (product) {
            setOrderPayload({ Volume: totals.Volume, product: product.value });
        }
    };
    var onOrderClose = function (success) {
        if (trade.loading)
            return;
        if (success) {
            trade
                .execute({
                accountId: -1,
                rows: productRows
                    .filter(function (x) { return Math.abs(x.Volume) > 0; })
                    .map(function (x) { return ({
                    name: x.name,
                    YearOfConsumption: x.YearOfConsumption,
                    YearOfProduction: x.YearOfProduction,
                    Bid_sek: x[ugPriceType],
                    Ask_sek: x[ugPriceType],
                    Pricemaster_sek: x[ugPriceType],
                    TypeOfDelivery: "historic",
                    ReferenceText: "Historisk handel",
                    BraMiljo: x.BraMiljo ? 1 : 0,
                    DeliveryDate: x.DeliveryDate,
                    Class: x.Class,
                    Volume: x.Volume,
                    FundFee: x.FundFee,
                }); }),
            })
                .then(function (transactionId) {
                if (typeof transactionId === "string") {
                    setTransactionIds([transactionId]);
                }
                else {
                    setTransactionIds(transactionId);
                }
            });
        }
        else {
            setOrderPayload(null);
            setTransactionIds(null);
        }
    };
    return (React.createElement(VStack, { spacing: 2, alignItems: "flex-start" },
        React.createElement(Filters, null,
            React.createElement(InputGroup, null,
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Produkt"),
                React.createElement(Select, { name: "product", options: productOptions, value: product, onChange: function (x) {
                        setProduct(x);
                    } })),
            React.createElement(InputGroup, null,
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Typ"),
                React.createElement(Select, { name: "type", options: types, value: type, onChange: function (x) {
                        setType(x);
                    } }))),
        React.createElement(Box, { minWidth: "25rem" },
            React.createElement(Spreadsheet.Table, { css: { minWidth: "100%" }, onCellChange: onCellChange, dataColumns: columns, dataRows: productRows }),
            React.createElement(Button, { mt: 4, onClick: onOrder }, "Registrera")),
        orderPayload && (React.createElement(HistoricalTradeModal, { loading: trade.loading, error: trade.error, onClose: onOrderClose, transactionIds: transactionIds },
            React.createElement(VStack, { spacing: 1, mt: 2 },
                React.createElement(Box, { as: "table", css: { borderCollapse: "collapse" }, minWidth: "100%" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement(Text, { fontWeight: "bold", fontSize: "sm" }, orderPayload.product)),
                            React.createElement("td", null,
                                React.createElement(Text, { fontWeight: "bold", fontSize: "sm" }, SWE.format(orderPayload.Volume) + " st"))))))))));
};
export default HisstoricalTradesUG;
