import React from "react";
import axios from "axios";
import Modal, { ModalTitle } from "../components/Modal";
import { NormalLoginForm, BankIDLoginForm } from "@skepool/site/src/pages/login";
import { VStack } from "../components/Layout";
import useLocalStorage from "../hooks/useLocalStorage";
var Service = {
    check: function () {
        return axios.get("/api/core/token/timeout").then(function (res) { return res.data; });
    }
};
var TimeoutModal = function (_a) {
    var onLoginSuccess = _a.onLoginSuccess;
    var _b = useLocalStorage("ui-login-type", "NORMAL"), loginType = _b[0], setLoginType = _b[1];
    var onEmpty = function () {
        setLoginType("NORMAL");
    };
    return (React.createElement(Modal, { onClose: function () { }, minWidth: 0 },
        React.createElement(VStack, null,
            React.createElement(ModalTitle, { fontSize: "base", width: 300 }, "Du har blivit utloggad ur systemet pga inaktivitet"),
            window.SKEPOOL.USER_LOGIN_METHOD === "BankID" && (React.createElement(BankIDLoginForm, { onEmpty: onEmpty, onLoginSuccess: onLoginSuccess })),
            window.SKEPOOL.USER_LOGIN_METHOD !== "BankID" && (React.createElement(NormalLoginForm, { showForgotPassword: false, onLoginSuccess: onLoginSuccess })))));
};
var TimeoutModalContainer = function () {
    var _a = React.useState(false), notLoggedIn = _a[0], setNotLoggedIn = _a[1];
    var _b = React.useState(0), shouldUpdate = _b[0], setShouldUpdate = _b[1];
    React.useEffect(function () {
        var timer;
        var mounted = true;
        var check = function () {
            Service.check()
                .then(function (time) {
                if (time && mounted) {
                    timer = setTimeout(check, time * 1000);
                }
                else if (!time && mounted) {
                    setNotLoggedIn(true);
                }
            })
                .catch(function () {
                setNotLoggedIn(true);
            });
        };
        check();
        return function () {
            mounted = false;
            clearTimeout(timer);
        };
    }, [shouldUpdate]);
    if (notLoggedIn) {
        return (React.createElement(TimeoutModal, { onLoginSuccess: function () {
                setShouldUpdate(shouldUpdate + 1);
                setNotLoggedIn(false);
            } }));
    }
    return null;
};
export default TimeoutModalContainer;
