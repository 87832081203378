var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import axios from "axios";
import Box from "@skepool/core/src/components/Box";
import Heading from "@skepool/core/src/components/Heading";
import { VStack, HStack } from "@skepool/core/src/components/Layout";
import Text from "@skepool/core/src/components/Text";
import Input, { Checkbox, Radio } from "@skepool/core/src/components/Input";
import Button from "@skepool/core/src/components/Button";
import { useAsyncCallback, useCancellableAsyncCallback } from "@skepool/core/src/hooks/async";
import Alert from "@skepool/core/src/components/Alert";
import { useImagePoller } from "@skepool/core/src/hooks/useImagePoller";
import { useStatusPoller } from "@skepool/core/src/hooks/useStatusPoller";
import primaryContacts from "../mocks/skepool_contacts_primary.json";
import secondaryContacts from "../mocks/skepool_contacts_secondary.json";
import { Link } from "react-router-dom";
import useLocalStorage from "@skepool/core/src/hooks/useLocalStorage";
import Loader from "@skepool/core/src/components/Loader";
var Container = function (props) { return React.createElement(Box, __assign({ maxWidth: 1280, width: "100%", mx: "auto" }, props)); };
var checkDeviceType = function () {
    var userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
        return "Android";
    }
    else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return "iOS";
    }
    else if (/Windows Phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    return "Desktop";
};
export var LoginService = {
    openBankId: function (token) {
        if (checkDeviceType() === "Desktop") {
            return window.open("bankid:///?autostarttoken=" + token + "&redirect=null", "_blank");
        }
        return window.open("https://app.bankid.com/?autostarttoken=" + token + "&redirect=null", "_bankid");
    },
    login: function (credentials) {
        return new Promise(function (resolve, reject) {
            axios.post("/api/core/login", credentials).then(function (res) {
                if (res.data.token) {
                    resolve(res.data);
                }
                reject("Not authenticated");
            });
        });
    },
    bankIDLogin: function (source) {
        return axios
            .post("/api/core/bankid/login", {}, {
            cancelToken: source.token
        })
            .then(function (res) {
            var _a;
            if (res.data.type === "login" && !((_a = res.data.data) === null || _a === void 0 ? void 0 : _a.BankIdAutoStartToken)) {
                throw new Error("Något gick fel vid inlogging med BankID");
            }
            return res.data;
        })
            .catch(function (e) {
            throw e;
        });
    },
    bankIDLoginStatus: function (source) {
        return axios
            .get("/api/core/bankid/login", {
            cancelToken: source.token
        })
            .then(function (res) {
            return res.data;
        })
            .catch(function (e) {
            throw e;
        });
    },
    bankIDLoginUser: function (payload, source) {
        return axios
            .put("/api/core/bankid/login", payload, { cancelToken: source.token })
            .then(function (res) {
            var _a;
            if (!((_a = res.data.data) === null || _a === void 0 ? void 0 : _a.token)) {
                throw new Error("Not authenticated");
            }
            return res.data.data;
        });
    },
    recover: function (email) {
        return axios.post("/api/core/password/recover", { email: email });
    },
    logout: function () {
        return axios.post("/api/core/logout").then(function (res) {
            window.location.reload();
        });
    }
};
function useForm(initialstate) {
    var _a = React.useState(initialstate), form = _a[0], setForm = _a[1];
    var onChange = React.useCallback(function (e) {
        var _a;
        setForm(__assign(__assign({}, form), (_a = {}, _a[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value, _a)));
    }, [form, setForm]);
    return [form, onChange];
}
var Contact = function (_a) {
    var name = _a.name, image = _a.image, phone = _a.phone, email = _a.email, title = _a.title, _b = _a.secondary, secondary = _b === void 0 ? false : _b, props = __rest(_a, ["name", "image", "phone", "email", "title", "secondary"]);
    return (React.createElement(Box, __assign({ display: "flex", alignItems: "center" }, props),
        !secondary ? (React.createElement(Box, { borderRadius: "100%", css: { flexShrink: 0, img: { width: "100%" } }, width: 64, height: 64, bg: "gray", mr: 3 }, image !== undefined && React.createElement("img", { src: image }))) : null,
        React.createElement(Text, { mt: 0, mb: 0, lineHeight: "1.3", fontWeight: "normal" },
            React.createElement(Text, { as: "span", fontWeight: "bold" }, name),
            React.createElement("br", null),
            title,
            React.createElement("br", null),
            phone,
            React.createElement("br", null),
            email)));
};
export var NormalLoginForm = function (_a) {
    var onLoginSuccess = _a.onLoginSuccess, _b = _a.showForgotPassword, showForgotPassword = _b === void 0 ? true : _b;
    var login = useAsyncCallback(LoginService.login);
    var _c = useForm({
        username: window.localStorage.getItem("login.username") || "",
        password: "",
        remember: true
    }), form = _c[0], onChange = _c[1];
    var onLogin = React.useCallback(function (e) {
        e.preventDefault();
        if (form.remember) {
            window.localStorage.setItem("login.username", form.username);
        }
        login.execute({ username: form.username, password: form.password }).then(function (data) {
            onLoginSuccess(data);
        });
    }, [login, form]);
    return (React.createElement(VStack, { as: "form", width: 300, onSubmit: onLogin },
        React.createElement(Box, null,
            React.createElement(Text, { as: "label", htmlFor: "username", fontSize: "sm", mb: 1, display: "block" }, "E-postadress"),
            React.createElement(Input, { name: "username", tabIndex: 1, value: form.username, onChange: onChange })),
        React.createElement(Box, null,
            React.createElement(HStack, { spacing: 1, justifyContent: "space-between", mb: 1 },
                React.createElement(Text, { as: "label", htmlFor: "password", fontSize: "sm", display: "block" }, "L\u00F6senord"),
                showForgotPassword && (React.createElement(Text, { as: Link, tabIndex: 0, to: "/recover", fontSize: "sm", color: "blue", css: { textDecoration: "none" } }, "Gl\u00F6mt l\u00F6senordet?"))),
            React.createElement(Input, { name: "password", tabIndex: 1, type: "password", value: form.password, onChange: onChange }),
            React.createElement(Alert, { show: login.error !== undefined }, "Fel anv\u00E4ndarnamn eller l\u00F6senord")),
        React.createElement(Checkbox, { name: "remember", checked: form.remember, onChange: onChange },
            React.createElement(Text, { mb: 0, mt: 0, mx: 2, tabIndex: 0, lineHeight: "looser" }, "Kom ih\u00E5g mig")),
        React.createElement(Button, { width: 180, height: 40, tabIndex: 1, loading: login.loading, onClick: onLogin }, "Logga in")));
};
var BankIdMessage = function (_a) {
    var _b = _a.bg, bg = _b === void 0 ? "lightblue" : _b, _c = _a.color, color = _c === void 0 ? "black" : _c, children = _a.children, props = __rest(_a, ["bg", "color", "children"]);
    return (React.createElement(Box, __assign({ bg: bg, p: 3 }, props),
        React.createElement(Text, { fontWeight: "bold", color: color, lineHeight: "1.375rem" }, children)));
};
export var BankIDLoginForm = function (_a) {
    var onEmpty = _a.onEmpty, onLoginSuccess = _a.onLoginSuccess;
    var _b, _c;
    var startBankIdLogin = useCancellableAsyncCallback(function (signal, payload) { return LoginService.bankIDLogin(signal); });
    var getBankIdLoginStatus = useCancellableAsyncCallback(function (signal, payload) {
        return LoginService.bankIDLoginStatus(signal);
    });
    var completeBankIdLogin = useCancellableAsyncCallback(function (signal, payload) {
        return LoginService.bankIDLoginUser(payload, signal);
    });
    var _d = React.useState("IDLE"), state = _d[0], setState = _d[1];
    var _e = React.useState(false), isSameDevice = _e[0], setIsSameDevice = _e[1];
    var _f = React.useState(null), selectUserPayload = _f[0], setSelectUserPayload = _f[1];
    var _g = React.useState(), user = _g[0], setUser = _g[1];
    var qrcode = useImagePoller("/api/core/bankid/qrcode", state === "QR" && !isSameDevice);
    var login = function (u, refId) {
        setState("LOGIN");
        completeBankIdLogin
            .execute({ UserId: u.UserId, refId: refId })
            .then(function (res) {
            onLoginSuccess();
        })
            .catch(function (e) {
            if (axios.isCancel(e)) {
                console.log("Request canceled", e.message);
            }
            else {
                setState("ERROR");
            }
        });
    };
    var onCancel = function (e) {
        e.preventDefault();
        startBankIdLogin.reset();
        startBankIdLogin.cancel();
        completeBankIdLogin.reset();
        completeBankIdLogin.cancel();
        setSelectUserPayload(null);
        setUser(undefined);
        setState("IDLE");
    };
    var onEmptyUsers = function (e) {
        onCancel(e);
        onEmpty();
    };
    useStatusPoller(function () { return getBankIdLoginStatus.execute(); }, {
        timeout: 60000,
        interval: 3000,
        enabled: state === "QR",
        onSuccess: function (status, cancel) {
            if (status.Users.length === 1) {
                cancel();
                login(status.Users[0], status.Users[0].BankIdOrderref);
            }
            else if (status.Users.length > 1) {
                cancel();
                setState("PICKING_USER");
                setSelectUserPayload({ users: status.Users });
            }
            else if (status.Users.length === 0 && status.LoginInStatus === 1) {
                cancel();
                setState("NO_USERS");
            }
        },
        onTimeout: function () {
            setState("IDLE");
        }
    });
    var onSubmit = function (e) {
        var _a, _b;
        e.preventDefault();
        // @ts-ignore
        var id = (_b = (_a = e.nativeEvent) === null || _a === void 0 ? void 0 : _a.submitter) === null || _b === void 0 ? void 0 : _b.id;
        if (id === "same-device") {
            setIsSameDevice(true);
        }
        else {
            setIsSameDevice(false);
        }
        if (state === "IDLE" || state === "ERROR") {
            startBankIdLogin.reset();
            completeBankIdLogin.reset();
            startBankIdLogin
                .execute()
                .then(function (res) {
                if (res.type === "started") {
                    setState("QR");
                    if (id === "same-device") {
                        setTimeout(function () {
                            LoginService.openBankId(res.data.BankIdAutoStartToken);
                        });
                    }
                }
            })
                .catch(function (e) {
                if (axios.isCancel(e)) {
                    console.log("Request canceled", e.message);
                }
                else {
                    setState("ERROR");
                }
            });
        }
        if (state === "PICKING_USER") {
            if (user) {
                login(user, user.BankIdOrderref);
            }
        }
    };
    return (React.createElement(VStack, { as: "form", width: 300, onSubmit: onSubmit },
        state === "IDLE" && (React.createElement(VStack, { mt: 3, spacing: 2 },
            React.createElement(Button, { id: "other-device", width: 250, height: 40, loading: startBankIdLogin.loading }, "Logga in"),
            React.createElement(Button, { id: "same-device", width: 250, height: 40, loading: startBankIdLogin.loading }, "Logga in med samma enhet"))),
        state === "QR" && !isSameDevice && (React.createElement(VStack, { spacing: 3 },
            React.createElement(BankIdMessage, null, "Starta din Bank ID app p\u00E5 din mobil eller surfplatta och skanna QR-koden"),
            React.createElement(Box, { as: "img", width: "100%", src: qrcode }),
            React.createElement(Button, { onClick: onCancel, width: 100, height: 40, variant: "secondary" }, "Avbryt"))),
        state === "QR" && isSameDevice && (React.createElement(VStack, { spacing: 3 },
            React.createElement(BankIdMessage, null, "V\u00E4ntar p\u00E5 inloggning..."),
            React.createElement(Box, { position: "relative", py: 3 },
                React.createElement(Loader, null)),
            React.createElement(Button, { onClick: onCancel, width: 100, height: 40, variant: "secondary" }, "Avbryt"))),
        state === "LOGIN" && (React.createElement(Box, null,
            React.createElement(VStack, { position: "relative", py: 3 },
                React.createElement(Loader, null)))),
        state === "NO_USERS" && (React.createElement(Box, null,
            React.createElement(BankIdMessage, { bg: "orange", color: "white" }, "Ingen anv\u00E4ndare kunde hittas med detta BankID"),
            React.createElement(HStack, { mt: 3 },
                React.createElement(Button, { onClick: onEmptyUsers, height: 40, variant: "secondary" }, "Logga in med l\u00F6senord ist\u00E4llet")),
            React.createElement(Text.Small, { mt: 3 }, "Vi kommer att skicka dig vidare till inst\u00E4llningar s\u00E5 att du kan fylla i ditt personnummer efter inloggning"))),
        state === "PICKING_USER" && (React.createElement(VStack, { py: 3 },
            React.createElement(Text, null, "V\u00E4lj anv\u00E4ndare:"),
            React.createElement(VStack, { spacing: 2 }, selectUserPayload === null || selectUserPayload === void 0 ? void 0 : selectUserPayload.users.map(function (x) { return (React.createElement(Radio, { key: x.UserId, name: "user-" + x.UserId, onChange: function (e) {
                    setUser(e.target.checked ? x : x);
                }, checked: x.UserId === (user === null || user === void 0 ? void 0 : user.UserId) },
                React.createElement(Text, { mx: 2, lineHeight: "loose" }, x.UserName))); })),
            React.createElement(Button, { width: 180, height: 40, loading: completeBankIdLogin.loading }, "Forts\u00E4tt"))),
        ((_b = getBankIdLoginStatus.error) === null || _b === void 0 ? void 0 : _b.message) ? (React.createElement(Box, null,
            React.createElement(BankIdMessage, { bg: "orange", color: "white" }, getBankIdLoginStatus.error.message),
            React.createElement(HStack, { mt: 3 },
                React.createElement(Button, { onClick: onEmptyUsers, height: 40, variant: "secondary" }, "Logga in med l\u00F6senord ist\u00E4llet")),
            React.createElement(Text.Small, { mt: 3 }, "Vi kommer att skicka dig vidare till inst\u00E4llningar s\u00E5 att du kan fylla i ditt personnummer efter inloggning"))) : null,
        ((_c = completeBankIdLogin.error) === null || _c === void 0 ? void 0 : _c.message) ? (React.createElement(Box, null,
            React.createElement(BankIdMessage, { bg: "orange", color: "white" }, "Ov\u00E4ntat fel uppstod vid inloggning."),
            React.createElement(HStack, { mt: 3 },
                React.createElement(Button, { width: 180, height: 40 }, "Prova igen"),
                React.createElement(Button, { onClick: onCancel, width: 100, height: 40, variant: "secondary" }, "Avbryt")))) : null));
};
var LoginForm = function (props) {
    var _a = useLocalStorage("ui-login-type", "NORMAL"), loginType = _a[0], setLoginType = _a[1];
    var _b = React.useState(undefined), settingsRedirect = _b[0], setSettingsRedirect = _b[1];
    var onEmpty = function () {
        setSettingsRedirect("/portal#/usersettings");
        setLoginType("NORMAL");
    };
    return (React.createElement(Box, { css: { flexShrink: 0 }, width: ["100%", 550], pr: [0, 250], mb: 4 },
        React.createElement(VStack, null,
            React.createElement(Heading.S2, null, "Logga in"),
            React.createElement(HStack, null,
                React.createElement(Radio, { name: "normal", onChange: function (e) {
                        setLoginType(e.target.checked ? "NORMAL" : "BANKID");
                    }, checked: loginType === "NORMAL" },
                    React.createElement(Text, { mx: 2, lineHeight: "loose" }, "L\u00F6senord")),
                React.createElement(Radio, { name: "bankid", onChange: function (e) {
                        setLoginType(e.target.checked ? "BANKID" : "NORMAL");
                    }, checked: loginType === "BANKID" },
                    React.createElement(Text, { mx: 2, lineHeight: "loose" }, "BankID"))),
            loginType === "NORMAL" && (React.createElement(NormalLoginForm, { onLoginSuccess: function (res) {
                    if (!res.registrationnumber) {
                        window.location.href = "portal#/update";
                    }
                    else {
                        if (settingsRedirect) {
                            window.location.href = settingsRedirect;
                        }
                        else {
                            window.location.href = props.redirectUrl;
                        }
                    }
                } })),
            loginType === "BANKID" && (React.createElement(BankIDLoginForm, { onEmpty: onEmpty, onLoginSuccess: function () {
                    window.location.href = props.redirectUrl;
                } })))));
};
var Page = function (_a) {
    var children = _a.children;
    var _b = React.useState(false), showMore = _b[0], setShowMore = _b[1];
    return (React.createElement(Container, { minHeight: "100vh", display: "flex", justifyContent: "center" },
        React.createElement(VStack, { p: 4, spacing: 4, width: "100%", alignSelf: "center", justifySelf: "center" },
            React.createElement(Box, null,
                React.createElement(Box, { css: { textDecoration: "none" }, as: Link, to: "/" },
                    React.createElement(Heading.S1, { as: "h1", mb: 0, lineHeight: "none" }, "Skepool")),
                React.createElement(Text, { as: "span" }, "Finansiell handel \u2022 Fysisk handel \u2022 Analys av elmarknaden \u2022 Riskhantering")),
            React.createElement(Box, { display: "flex", flexWrap: "wrap" },
                children,
                React.createElement(Box, null,
                    React.createElement(Heading.S2, { mb: 3 }, "Kontaktpersoner"),
                    React.createElement(VStack, { spacing: "1.25rem" },
                        primaryContacts.map(function (x) { return (React.createElement(Contact, __assign({}, x))); }),
                        !showMore && (React.createElement(Box, null,
                            React.createElement(Button, { variant: "secondary", color: "darkpurple", fontSize: "sm", borderColor: "darkpurple", onClick: function () { return setShowMore(true); } }, "Fler kontaktpersoner")))))),
            showMore && (React.createElement(Box, { mt: 3, display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridRowGap: "1.5rem" }, secondaryContacts.map(function (x) { return (React.createElement(Box, { mb: 3 },
                React.createElement(Contact, __assign({ secondary: true }, x)))); }))))));
};
var Login = function (_a) {
    var isLoggedIn = _a.isLoggedIn, redirectUrl = _a.redirectUrl;
    var logout = useAsyncCallback(LoginService.logout);
    return (React.createElement(Page, null, !isLoggedIn ? (React.createElement(LoginForm, { redirectUrl: redirectUrl })) : (React.createElement(VStack, { css: { flexShrink: 0, flexGrow: 0 }, width: ["100%", 550], pr: [0, 250], mb: 4 },
        React.createElement(Heading.S2, null, "Redan inloggad"),
        React.createElement(Box, null,
            React.createElement(Button, { mb: 0, as: "a", href: redirectUrl }, "Forts\u00E4tt till Portalen")),
        React.createElement(Box, null,
            React.createElement(Button, { variant: "secondary", loading: logout.loading, onClick: logout.execute }, "Logga ut"))))));
};
var ResetForm = function (_a) {
    var oldPassword = _a.oldPassword, username = _a.username;
    var recover = useAsyncCallback(LoginService.recover);
    var _b = useForm({
        email: username,
        old_password: oldPassword,
        new_password: ""
    }), form = _b[0], onChange = _b[1];
    var onRecover = React.useCallback(function (e) {
        e.preventDefault();
        // recover.execute(form.username).then(() => {})
    }, [recover, form]);
    return (React.createElement(Box, { width: ["100%", 550], pr: [0, 250], mb: 4 },
        React.createElement(VStack, { as: "form", onSubmit: onRecover },
            React.createElement(Heading.S2, null, "\u00C5terst\u00E4llning av l\u00F6senord"),
            React.createElement(Box, null,
                React.createElement(Text, { as: "label", htmlFor: "password", fontSize: "sm", mb: 1, display: "block" }, "Nytt l\u00F6senord"),
                React.createElement(Input, { name: "password", value: form.new_password, onChange: onChange })),
            React.createElement(Button, { width: 200, loading: recover.loading, type: "submit" }, "\u00C4ndra l\u00F6senord"))));
};
export var Reset = function (props) {
    return (React.createElement(Page, null,
        React.createElement(ResetForm, { oldPassword: "", username: "" })));
};
var RecoverForm = function (props) {
    var recover = useAsyncCallback(LoginService.recover);
    var _a = useForm({
        username: window.localStorage.getItem("login.username") || ""
    }), form = _a[0], onChange = _a[1];
    var onRecover = React.useCallback(function (e) {
        e.preventDefault();
        recover.execute(form.username).then(function () { });
    }, [recover, form]);
    return (React.createElement(Box, { width: ["100%", 550], pr: [0, 250], mb: 4 },
        React.createElement(VStack, { as: "form", onSubmit: onRecover },
            React.createElement(Heading.S2, null, "Gl\u00F6mt l\u00F6senord"),
            React.createElement(Box, null,
                React.createElement(Text, { as: "label", htmlFor: "username", fontSize: "sm", mb: 1, display: "block" }, "E-postadress"),
                React.createElement(Input, { name: "username", value: form.username, onChange: onChange })),
            recover.result && React.createElement(Text, { fontSize: "xs" }, "\u00C5terst\u00E4llning av l\u00F6senord skickas med e-post"),
            React.createElement(Button, { width: 200, loading: recover.loading, type: "submit" }, "\u00C5terst\u00E4ll l\u00F6senord"))));
};
export var Recover = function (props) {
    return (React.createElement(Page, null,
        React.createElement(RecoverForm, null)));
};
export default Login;
