export var isEmptyObject = function (obj) { return Object.keys(obj).length === 0 && obj.constructor === Object; };
export var has = function (a, b) {
    return b.hasOwnProperty(a);
};
export var uid = function () {
    return (Date.now().toString(36) +
        Math.random()
            .toString(36)
            .substr(2));
};
