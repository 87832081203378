var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Text from "./Text";
var Heading = function (props) { return (React.createElement(Text, __assign({ as: "h1", fontSize: "5xl", lineHeight: "tight", color: "darkpurple", fontWeight: "black", className: "Heading" }, props))); };
Heading.displayName = "Heading";
Heading.S1 = function (props) { return React.createElement(Heading, __assign({ fontSize: "5xl", lineHeight: "tight" }, props)); };
Heading.S2 = function (props) { return React.createElement(Heading, __assign({ fontSize: "xl", lineHeight: "normal", letterSpacing: "-0.1px" }, props)); };
Heading.S3 = function (props) { return React.createElement(Heading, __assign({ fontSize: "2xl", lineHeight: "normal" }, props)); };
Heading.S4 = function (props) { return React.createElement(Heading, __assign({ fontSize: "xl", lineHeight: "normal" }, props)); };
Heading.S5 = function (props) { return React.createElement(Heading, __assign({ fontSize: "base", lineHeight: "normal" }, props)); };
Heading.S6 = function (props) { return React.createElement(Heading, __assign({ fontSize: "sm", lineHeight: "normal" }, props)); };
Heading.S1.displayName = "Heading.S1";
Heading.S2.displayName = "Heading.S2";
Heading.S3.displayName = "Heading.S3";
Heading.S4.displayName = "Heading.S4";
Heading.S5.displayName = "Heading.S5";
Heading.S6.displayName = "Heading.S6";
export default Heading;
