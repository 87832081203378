var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Modal, { ModalTitle, ModalCloseButton } from "@skepool/core/src/components/Modal";
import Button from "@skepool/core/src/components/Button";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import Text from "@skepool/core/src/components/Text";
import Input from "@skepool/core/src/components/Input";
import Select from "@skepool/core/src/components/Select";
import { hasPermission } from "@skepool/handel/src/utils";
import { useAsyncCallback } from "@skepool/core/src/hooks/async";
import ReportsService from "../services/ReportsService";
import { useDynamicAccounts, CompaniesFilterSelect } from "../pages/TransactionsPage";
import { useCompaniesFilter } from "../pages/HistoricalPricesPage";
import { uid } from "@skepool/core/src/utils";
var FilterModal = function (_a) {
    var filters = _a.filters, onClose = _a.onClose, title = _a.title, currentUser = _a.currentUser, defaultFilter = _a.filter, companiesData = _a.companiesData, accountsData = _a.accountsData;
    var isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
    var saveSettings = useAsyncCallback(function (params) {
        return ReportsService.saveFilterSettings("matrisrapport", params);
    });
    var _b = React.useState(defaultFilter.name), filterName = _b[0], setFilterName = _b[1];
    var _c = useCompaniesFilter(isAdmin, currentUser, companiesData, defaultFilter), companies = _c.companies, companyOptions = _c.companyOptions, allCompaniesSelected = _c.allCompaniesSelected, allActiveCompaniesSelected = _c.allActiveCompaniesSelected, getCompanyIds = _c.getCompanyIds, onCompaniesChange = _c.onCompaniesChange;
    var _d = useDynamicAccounts(companies, allCompaniesSelected, allActiveCompaniesSelected, currentUser, accountsData, defaultFilter), accountOptions = _d.accountOptions, accounts = _d.accounts, accountsLoading = _d.accountsLoading, setAccounts = _d.setAccounts, defaultAccount = _d.defaultAccount;
    var onSubmit = function (e) {
        var _a;
        e.preventDefault();
        var newFilters;
        var filter = {
            id: (_a = defaultFilter.id) !== null && _a !== void 0 ? _a : uid(),
            name: filterName,
            values: {
                companyId: companies
                    .filter(function (x) { return x.value !== -1 && x.value !== -2; })
                    .map(function (x) { return x.value; })
                    .join(","),
                accountId: accounts
                    .filter(function (x) { return x.value !== -1 && x.value !== -2; })
                    .map(function (x) { return x.value; })
                    .join(",")
            }
        };
        if (filters) {
            newFilters = __spreadArrays(filters);
            var prevFilterIndex = filters.findIndex(function (x) { return x.id === defaultFilter.id; });
            if (prevFilterIndex !== -1) {
                newFilters[prevFilterIndex] = filter;
            }
            else {
                newFilters.push(filter);
            }
        }
        else {
            newFilters = [filter];
        }
        saveSettings
            .execute({
            filters: newFilters
        })
            .then(function (userSettings) {
            onClose(userSettings, filter);
        });
    };
    var onRemove = function (e) {
        e.preventDefault();
        if (filters) {
            var newFilters = __spreadArrays(filters);
            var prevFilterIndex = filters.findIndex(function (x) { return x.id === defaultFilter.id; });
            if (prevFilterIndex !== -1) {
                newFilters.splice(prevFilterIndex, 1);
                saveSettings
                    .execute({
                    filters: newFilters
                })
                    .then(function (userSettings) {
                    onClose(userSettings);
                });
            }
        }
    };
    var editing = (filters === null || filters === void 0 ? void 0 : filters.find(function (x) { return x.id == defaultFilter.id; })) !== undefined;
    var isAccountsDisabled = isAdmin
        ? allCompaniesSelected || allActiveCompaniesSelected || accountsLoading || companies.length > 1
        : accountsLoading;
    return (React.createElement(Modal, { style: { overflow: "visible" }, onClose: function () { return onClose(); } },
        React.createElement(VStack, { spacing: 4 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null, title),
                React.createElement(ModalCloseButton, { onClose: function () { return onClose(); } })),
            React.createElement(VStack, { as: "form", spacing: 4, width: "25rem", onSubmit: onSubmit },
                React.createElement(VStack, { spacing: 2 },
                    React.createElement(Text, { as: "label", fontSize: "sm", htmlFor: "name" }, "Namn p\u00E5 filter"),
                    React.createElement(Input, { required: true, name: "name", value: filterName, onChange: function (e) {
                            setFilterName(e.target.value);
                        } })),
                React.createElement(VStack, null,
                    isAdmin && (React.createElement(CompaniesFilterSelect, { isAdmin: isAdmin, companies: companies, companyOptions: companyOptions, onCompaniesChange: onCompaniesChange })),
                    React.createElement(VStack, { spacing: 2 },
                        React.createElement(Text, { as: "label", fontSize: "sm", style: { opacity: isAccountsDisabled ? 0.5 : 1 } }, "Konton"),
                        React.createElement(Select, { name: "accounts", height: "auto", disabled: isAccountsDisabled, isMulti: true, closeMenuOnSelect: false, options: [
                                {
                                    label: "",
                                    options: accountOptions.filter(function (x) { return x.value === -1 || x.value === -2; })
                                },
                                {
                                    label: "Konton",
                                    options: accountOptions.filter(function (x) { return !x.matris && x.value !== -1 && x.value !== -2; })
                                },
                                {
                                    label: "Matriskonton",
                                    options: accountOptions.filter(function (x) { return x.matris && x.value !== -1 && x.value !== -2; })
                                }
                            ], value: accounts, isClearable: !accounts.some(function (x) { return x.value === -1; }), onChange: function (x, meta) {
                                if (meta.action === "clear" || !x || meta.option === defaultAccount) {
                                    setAccounts([defaultAccount]);
                                }
                                else if (meta.option && meta.option.value === -2) {
                                    setAccounts(accountOptions.filter(function (x) { return x.active && x.value !== -1 && x.value !== -2; }));
                                }
                                else {
                                    setAccounts(x.filter(function (y) { return y.value !== -1 && x.value !== -2; }));
                                }
                            } }))),
                saveSettings.error && (React.createElement(Text, { fontSize: "sm", color: "red" }, saveSettings.error.message)),
                React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                    React.createElement(Button, { loading: saveSettings.loading, type: "submit" }, "Spara"),
                    editing && (React.createElement(Button, { onClick: onRemove, loading: saveSettings.loading, variant: "secondary" }, "Ta bort")))))));
};
export default FilterModal;
