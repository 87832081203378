var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import Box from "./Box";
import Text from "./Text";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "./Modal.css";
import Button from "./Button";
import { Close } from "./Icons";
export var ModalText = function (props) { return (React.createElement(Text, __assign({ fontSize: "sm", lineHeight: "tighter", fontWeight: "normal" }, props))); };
export var ModalTitle = function (props) { return (React.createElement(Text, __assign({ as: "h2", fontSize: "xl", fontWeight: "black" }, props))); };
export var ModalCloseButton = function (_a) {
    var onClose = _a.onClose, props = __rest(_a, ["onClose"]);
    return (React.createElement(Button, { "data-testid": "modal-close", tabIndex: 0, minHeight: 0, width: "1.5rem", height: "1.5rem", px: 0, py: 0, borderRadius: "100%", onClick: function () { return onClose(); } },
        React.createElement(Close, { height: "100%", width: "100%", css: { flexShrink: 0, verticalAlign: "top" } })));
};
var Modal = function (_a) {
    var children = _a.children, initialFocusRef = _a.initialFocusRef, onClose = _a.onClose, _b = _a.minWidth, minWidth = _b === void 0 ? "32.5rem" : _b, style = _a.style, props = __rest(_a, ["children", "initialFocusRef", "onClose", "minWidth", "style"]);
    return (React.createElement(DialogOverlay, { initialFocusRef: initialFocusRef, isOpen: true, onDismiss: onClose },
        React.createElement(Box, __assign({ as: DialogContent, alignSelf: "flex-start", bg: "white", p: 4, css: {
                "&[data-reach-dialog-content]": __assign({ overflow: "hidden", height: "auto", flexShrink: 0, width: ["calc(100% - 1rem)", "auto"], minWidth: ["calc(100% - 1rem)", minWidth] }, style)
            } }, props), children)));
};
export default Modal;
