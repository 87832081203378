var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import Modal, { ModalTitle, ModalCloseButton } from "@skepool/core/src/components/Modal";
import { HStack, VStack } from "@skepool/core/src/components/Layout";
import { SWE } from "@skepool/handel/src/utils";
var Table = function (props) {
    return (React.createElement(Box, __assign({ mb: 4, as: "table", css: {
            flexShrink: 0,
            borderCollapse: "collapse",
            "tr:not(:last-child)": {
                borderBottom: 1,
                borderBottomColor: "lightgrey"
            }
        } }, props)));
};
var TableRow = function (props) {
    return React.createElement(Box, __assign({ as: "tr" }, props));
};
var TableHeader = function (props) {
    return React.createElement(Text, __assign({ p: 2, verticalAlign: "top", fontSize: "xs", textAlign: "right", fontWeight: "bold", as: "th" }, props));
};
var TableColumn = function (props) {
    return React.createElement(Text, __assign({ as: "td", fontSize: "xs", textAlign: "right", p: 2 }, props));
};
var DerivativeTable = function (_a) {
    var transactionGroup = _a.transactionGroup;
    return (React.createElement(Table, null,
        React.createElement("thead", null,
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { fontSize: "base", textAlign: "left" }, "Terminer"),
                React.createElement(TableHeader, null),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.electricityDerivative.cumulated
                    ? transactionGroup.electricityDerivative.cumulated.volume
                    : 0)),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.electricityDerivative.cumulated
                    ? transactionGroup.electricityDerivative.cumulated.totalPrice.SEK
                    : 0))),
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { textAlign: "left" }, "Id"),
                React.createElement(TableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(TableHeader, null,
                    "Volym",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "MWh")),
                React.createElement(TableHeader, null,
                    "Pris",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK")))),
        React.createElement("tbody", null, transactionGroup.electricityDerivative.transactions.map(function (t) { return (React.createElement(TableRow, { key: t.id },
            React.createElement(TableColumn, { textAlign: "left" }, t.id),
            React.createElement(TableColumn, { textAlign: "left" }, t.product.name),
            React.createElement(TableColumn, null, SWE.format(t.volume)),
            React.createElement(TableColumn, null, SWE.format(t.price.SEK)))); }))));
};
var TakprisTable = function (_a) {
    var transactionGroup = _a.transactionGroup;
    return (React.createElement(Table, null,
        React.createElement("thead", null,
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { fontSize: "base", textAlign: "left" }, "Takpris"),
                React.createElement(TableHeader, null),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.takpris.cumulated ? transactionGroup.takpris.cumulated.volume : 0)),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.takpris.cumulated ? transactionGroup.takpris.cumulated.takpris : 0)),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.takpris.cumulated ? transactionGroup.takpris.cumulated.premie_sek_rakt.SEK : 0)),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.takpris.cumulated ? transactionGroup.takpris.cumulated.premievarde : 0))),
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { textAlign: "left" }, "Id"),
                React.createElement(TableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(TableHeader, null,
                    "Volym",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "MWh")),
                React.createElement(TableHeader, null,
                    "Takpris",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK / MWh")),
                React.createElement(TableHeader, null,
                    "Premie",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK / MWh")),
                React.createElement(TableHeader, null,
                    "Premiev\u00E4rde",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK")))),
        React.createElement("tbody", null, transactionGroup.takpris.transactions.map(function (t) { return (React.createElement(TableRow, { key: t.id },
            React.createElement(TableColumn, { textAlign: "left" }, t.id),
            React.createElement(TableColumn, { textAlign: "left" }, t.product.name),
            React.createElement(TableColumn, null, SWE.format(t.volume)),
            React.createElement(TableColumn, null, SWE.format(t.takpris)),
            React.createElement(TableColumn, null, SWE.format(t.premie)),
            React.createElement(TableColumn, null, SWE.format(t.premievarde)))); }))));
};
var CertificateTable = function (_a) {
    var transactionGroup = _a.transactionGroup;
    return (React.createElement(Table, null,
        React.createElement("thead", null,
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { fontSize: "base", textAlign: "left" }, "Elcertifikat"),
                React.createElement(TableHeader, null),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.electricityCertificate.cumulated
                    ? transactionGroup.electricityCertificate.cumulated.volume
                    : 0)),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.electricityCertificate.cumulated
                    ? transactionGroup.electricityCertificate.cumulated.totalPrice.SEK
                    : 0))),
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { textAlign: "left" }, "Id"),
                React.createElement(TableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(TableHeader, null,
                    "Volym",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "Antal")),
                React.createElement(TableHeader, null,
                    "Pris",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK")))),
        React.createElement("tbody", null, transactionGroup.electricityCertificate.transactions.map(function (t) { return (React.createElement(TableRow, { key: t.id },
            React.createElement(TableColumn, { textAlign: "left" }, t.id),
            React.createElement(TableColumn, { textAlign: "left" }, t.product.name),
            React.createElement(TableColumn, null, SWE.format(t.volume)),
            React.createElement(TableColumn, null, SWE.format(t.price.SEK)))); }))));
};
var UGTable = function (_a) {
    var transactionGroup = _a.transactionGroup;
    return (React.createElement(Table, null,
        React.createElement("thead", null,
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { fontSize: "base", textAlign: "left" }, "Milj\u00F6produkter"),
                React.createElement(TableHeader, null),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.miljoprod.cumulated ? transactionGroup.miljoprod.cumulated.volume : 0)),
                React.createElement(TableHeader, { fontSize: "sm" }, SWE.format(transactionGroup.miljoprod.cumulated ? transactionGroup.miljoprod.cumulated.totalPrice.SEK : 0))),
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { textAlign: "left" }, "Id"),
                React.createElement(TableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(TableHeader, null,
                    "Volym",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "Antal")),
                React.createElement(TableHeader, null,
                    "Pris",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK")))),
        React.createElement("tbody", null, transactionGroup.miljoprod.transactions.map(function (t) { return (React.createElement(TableRow, { key: t.id },
            React.createElement(TableColumn, { textAlign: "left" }, t.id),
            React.createElement(TableColumn, { textAlign: "left" }, t.product.name),
            React.createElement(TableColumn, null, SWE.format(t.volume)),
            React.createElement(TableColumn, null, SWE.format(t.price.SEK)))); }))));
};
export var NasdaqCurrencyTable = function (_a) {
    var transactionGroup = _a.transactionGroup;
    return (React.createElement(Table, null,
        React.createElement("thead", null,
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { fontSize: "base", textAlign: "left" }, "Valuta"),
                React.createElement(TableHeader, null),
                React.createElement(TableHeader, { fontSize: "sm" }),
                React.createElement(TableHeader, { fontSize: "sm" }),
                React.createElement(TableHeader, { fontSize: "sm" })),
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { textAlign: "left" }, "Id"),
                React.createElement(TableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(TableHeader, null,
                    "Antal",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "EURO")),
                React.createElement(TableHeader, null,
                    "Pris",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK / EURO")),
                React.createElement(TableHeader, null,
                    "V\u00E4rde",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "SEK")))),
        React.createElement("tbody", null, transactionGroup.nasdaqvalutaprod.transactions.map(function (t) { return (React.createElement(TableRow, { key: t.id },
            React.createElement(TableColumn, { textAlign: "left" }, t.id),
            React.createElement(TableColumn, { textAlign: "left" }, t.product.name),
            React.createElement(TableColumn, null, SWE.format(t.volume)),
            React.createElement(TableColumn, null, SWE.format(t.price.SEK)),
            React.createElement(TableColumn, null, SWE.format(t.transactionvalue.SEK)))); }))));
};
export var NasdaqEpadTable = function (_a) {
    var transactionGroup = _a.transactionGroup, type = _a.type, currency = _a.currency;
    return (React.createElement(Table, null,
        React.createElement("thead", null,
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { fontSize: "base", textAlign: "left" }, type === "nasdaqelprod" ? "EL" : "EPAD"),
                React.createElement(TableHeader, null),
                React.createElement(TableHeader, { fontSize: "sm" }),
                React.createElement(TableHeader, { fontSize: "sm" })),
            React.createElement(TableRow, null,
                React.createElement(TableHeader, { textAlign: "left" }, "Id"),
                React.createElement(TableHeader, { textAlign: "left" }, "Produkt"),
                React.createElement(TableHeader, null,
                    "Volym",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, "MWh")),
                React.createElement(TableHeader, null,
                    "Pris",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" },
                        currency,
                        " / MWh")),
                React.createElement(TableHeader, null,
                    "V\u00E4rde",
                    React.createElement(Text, { as: "span", display: "block", fontSize: "xxs" }, currency)))),
        React.createElement("tbody", null, transactionGroup[type].transactions.map(function (t) { return (React.createElement(TableRow, { key: t.id },
            React.createElement(TableColumn, { textAlign: "left" }, t.id),
            React.createElement(TableColumn, { textAlign: "left" }, t.product.name),
            React.createElement(TableColumn, null, SWE.format(t.volume)),
            React.createElement(TableColumn, null, SWE.format(t.price[currency])),
            React.createElement(TableColumn, null, SWE.format(t.transactionvalue[currency])))); }))));
};
var TransactionGroupModal = function (_a) {
    var onClose = _a.onClose, transactionGroup = _a.transactionGroup;
    var hasDerivative = transactionGroup.electricityDerivative.transactions.length > 0;
    var hasCertificate = transactionGroup.electricityCertificate.transactions.length > 0;
    var hasTakpris = transactionGroup.takpris.transactions.length > 0;
    var hasUG = transactionGroup.miljoprod.transactions.length > 0;
    var hasNasdaqEpad = transactionGroup.nasdaqepadprod.transactions.length > 0;
    var hasNasdaqEno = transactionGroup.nasdaqelprod.transactions.length > 0;
    var hasNasdaqCurrency = transactionGroup.nasdaqvalutaprod.transactions.length > 0;
    var hasAnyNasdaq = hasNasdaqCurrency || hasNasdaqEno || hasNasdaqEpad;
    return (React.createElement(Modal, { onClose: function () { return onClose(); } },
        React.createElement(VStack, { spacing: 3 },
            React.createElement(HStack, { justifyContent: "space-between", alignItems: "center" },
                React.createElement(ModalTitle, null,
                    "Kvittensnummer: ",
                    transactionGroup.transactionGroupId),
                React.createElement(ModalCloseButton, { onClose: onClose })),
            hasAnyNasdaq ? (React.createElement(VStack, { spacing: 4 },
                React.createElement(VStack, null,
                    React.createElement(Text, { fontSize: "sm", fontWeight: "bold" }, "F\u00F6ljande produkter har handlats och kommer att avr\u00E4knas i SEK:"),
                    hasNasdaqEno && (React.createElement(NasdaqEpadTable, { currency: "SEK", type: "nasdaqelprod", transactionGroup: transactionGroup })),
                    hasNasdaqEpad && (React.createElement(NasdaqEpadTable, { currency: "SEK", type: "nasdaqepadprod", transactionGroup: transactionGroup }))),
                React.createElement(VStack, null,
                    React.createElement(Text, { fontSize: "sm", fontWeight: "bold" }, "De ing\u00E5ende produkterna \u00E4r i EURO och har f\u00F6ljande priser:"),
                    hasNasdaqEno && (React.createElement(NasdaqEpadTable, { currency: "EUR", type: "nasdaqelprod", transactionGroup: transactionGroup })),
                    hasNasdaqEpad && (React.createElement(NasdaqEpadTable, { currency: "EUR", type: "nasdaqepadprod", transactionGroup: transactionGroup })),
                    hasNasdaqCurrency && React.createElement(NasdaqCurrencyTable, { transactionGroup: transactionGroup })))) : (React.createElement(HStack, { spacing: 4, flexWrap: "wrap", width: ["100%", "auto"] },
                hasDerivative && React.createElement(DerivativeTable, { transactionGroup: transactionGroup }),
                hasCertificate && React.createElement(CertificateTable, { transactionGroup: transactionGroup }),
                hasTakpris && React.createElement(TakprisTable, { transactionGroup: transactionGroup }),
                hasUG && React.createElement(UGTable, { transactionGroup: transactionGroup }))))));
};
export default TransactionGroupModal;
