var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import Box from "@skepool/core/src/components/Box";
import Text from "@skepool/core/src/components/Text";
import Filters from "@skepool/handel/src/components/Filters";
import InputGroup from "@skepool/core/src/components/InputGroup";
import { HStack } from "@skepool/core/src/components/Layout";
import Datepicker from "@skepool/core/src/components/Datepicker";
import { useAsync, useAsyncCallback, useCancellableAsyncCallback } from "@skepool/core/src/hooks/async";
import TradeService from "@skepool/handel/src/services/TradeService";
import ErrorFallback from "@skepool/core/src/components/ErrorFallback";
import Loader from "@skepool/core/src/components/Loader";
import Select from "@skepool/core/src/components/Select";
import AdminService from "@skepool/handel/src/services/AdminService";
import Input from "@skepool/core/src/components/Input";
import Button, { MoreInfoButton } from "@skepool/core/src/components/Button";
import { hasPermission, SWE, serialize } from "@skepool/handel/src/utils";
import ReportsService from "../services/ReportsService";
import format from "date-fns/format";
import TransactionGroupModal from "../modals/TransactionGroupModal";
import setDate from "date-fns/setDate";
import setMonth from "date-fns/setMonth";
import { PageHeader } from "@skepool/core/src/components/PageHeader";
import { Page } from "@skepool/core/src/components/Page";
import { useCompaniesFilter } from "./HistoricalPricesPage";
import { SavedFilters } from "./MatrixPortfolio";
var TransactionRow = function (_a) {
    var t = _a.transaction;
    var _b = React.useState(null), historyPayload = _b[0], setHistoryPayload = _b[1];
    var openHistory = function (history) {
        setHistoryPayload(history);
    };
    var transactionsCount = t.takpris.transactions.length ||
        t.miljoprod.transactions.length ||
        t.electricityCertificate.transactions.length ||
        t.electricityDerivative.transactions.length ||
        t.nasdaqelprod.transactions.length ||
        t.nasdaqepadprod.transactions.length ||
        t.nasdaqvalutaprod.transactions.length ||
        0;
    return (React.createElement(ReportTableRow, { key: t.transactionGroupId },
        React.createElement(ReportTableColumn, null,
            React.createElement(MoreInfoButton, { onClick: function () { return openHistory(t); } }),
            historyPayload && (React.createElement(TransactionGroupModal, { transactionGroup: historyPayload, onClose: function () { return setHistoryPayload(null); } }))),
        React.createElement(ReportTableColumn, { "data-testid": "transaction-group-id", textAlign: "left" },
            t.transactionGroupId,
            React.createElement(Text, { fontSize: "xxs", as: "span", color: "grey" }, " (" + transactionsCount + ")")),
        React.createElement(ReportTableColumn, { textAlign: "left" }, format(new Date(t.createdAt * 1000), "yyyy-MM-dd hh:mm")),
        React.createElement(ReportTableColumn, { textAlign: "left" }, t.account.name),
        React.createElement(ReportTableColumn, { textAlign: "left" }, t.area.id === -1 ? "" : t.area.id),
        React.createElement(ReportTableColumn, { textAlign: "left" }, t.description),
        React.createElement(ReportTableColumn, null, SWE.format(t.electricityDerivative.cumulated ? t.electricityDerivative.cumulated.volume : 0)),
        React.createElement(ReportTableColumn, null, SWE.format(t.electricityCertificate.cumulated ? t.electricityCertificate.cumulated.volume : 0)),
        React.createElement(ReportTableColumn, null, SWE.format(t.takpris.cumulated ? t.takpris.cumulated.volume : 0)),
        React.createElement(ReportTableColumn, null, SWE.format(t.miljoprod.cumulated ? t.miljoprod.cumulated.volume : 0)),
        React.createElement(ReportTableColumn, null, SWE.format(t.nasdaqelprod.cumulated ? t.nasdaqelprod.cumulated.volume : 0)),
        React.createElement(ReportTableColumn, null, SWE.format(t.nasdaqepadprod.cumulated ? t.nasdaqepadprod.cumulated.volume : 0)),
        React.createElement(ReportTableColumn, null, SWE.format(t.nasdaqvalutaprod.cumulated ? t.nasdaqvalutaprod.cumulated.volume : 0))));
};
export var useDynamicAccounts = function (companies, allCompaniesSelected, allActiveCompaniesSelected, currentUser, userAccounts, filter) {
    var isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
    var accountsData = useCancellableAsyncCallback(function (cancelToken, companyId) {
        return AdminService.getCompanyAccounts(false, companyId, cancelToken);
    });
    var lastCompanyIds = React.useRef(null);
    var defaultAccount = {
        label: "Alla",
        value: -1,
        matris: false,
        active: true,
        isFixed: true,
    };
    var allActiveAccounts = {
        label: "Alla aktiva",
        value: -2,
        matris: false,
        active: true,
        isFixed: false,
    };
    var _a = React.useState([defaultAccount]), accounts = _a[0], setAccounts = _a[1];
    var _b = React.useState([defaultAccount, allActiveAccounts]), accountOptions = _b[0], setAccountOptions = _b[1];
    var filterCompanyIds = filter === null || filter === void 0 ? void 0 : filter.values.companyId;
    var filterAccountIds = filter === null || filter === void 0 ? void 0 : filter.values.accountId;
    var companyIds = companies.map(function (x) { return String(x.value); }).join(",");
    var accountIds = accounts.map(function (x) { return String(x.value); }).join(",");
    React.useEffect(function () {
        var _a;
        if (!isAdmin) {
            var newAccountOptions = __spreadArrays([
                defaultAccount,
                allActiveAccounts
            ], (userAccounts
                ? userAccounts.map(function (x) { return ({
                    label: x.name,
                    value: x.id,
                    matris: x.matris,
                    isFixed: false,
                    active: x.active,
                }); })
                : []));
            var filterAccounts_1 = (_a = filter === null || filter === void 0 ? void 0 : filter.values.accountId.split(",").map(function (x) { return Number(x); })) !== null && _a !== void 0 ? _a : [];
            var filterOptions = newAccountOptions.filter(function (x) { return x.value !== -1 && (filterAccounts_1 === null || filterAccounts_1 === void 0 ? void 0 : filterAccounts_1.indexOf(x.value)) !== -1; });
            if (filterAccounts_1.length > 0 && filterOptions) {
                setAccounts(filterOptions);
            }
            else {
                setAccounts([defaultAccount]);
            }
            setAccountOptions(newAccountOptions);
        }
        else if (!allCompaniesSelected && !allActiveCompaniesSelected && companies.length === 1) {
            accountsData.cancel();
            console.log("fetching accounts for companies", companies.map(function (x) { return x.value; }));
            accountsData.execute(companies[0].value).then(function (newAccounts) {
                var _a;
                var newAccountOptions = __spreadArrays([
                    defaultAccount,
                    allActiveAccounts
                ], newAccounts.map(function (x) { return ({
                    label: x.name,
                    value: x.id,
                    matris: x.matris,
                    isFixed: false,
                    active: x.active,
                }); }));
                var filterAccounts = (_a = filter === null || filter === void 0 ? void 0 : filter.values.accountId.split(",").map(function (x) { return Number(x); })) !== null && _a !== void 0 ? _a : [];
                var filterOptions = newAccountOptions.filter(function (x) { return x.value !== -1 && (filterAccounts === null || filterAccounts === void 0 ? void 0 : filterAccounts.indexOf(x.value)) !== -1; });
                if (filterAccounts.length > 0 && filterOptions) {
                    setAccounts(filterOptions);
                }
                else {
                    setAccounts([defaultAccount]);
                }
                setAccountOptions(newAccountOptions);
            });
        }
        else if (!allCompaniesSelected && !allActiveCompaniesSelected && companies.length > 1) {
            setAccounts([defaultAccount]);
        }
        else if (allCompaniesSelected || allActiveCompaniesSelected) {
            setAccounts([defaultAccount]);
        }
    }, [companyIds, filterCompanyIds !== companyIds, filterAccountIds !== accountIds, allCompaniesSelected]);
    return {
        accountOptions: accountOptions,
        accounts: accounts,
        setAccounts: setAccounts,
        defaultAccount: defaultAccount,
        accountsData: accountsData.result,
        accountsLoading: accountsData.loading,
    };
};
export var CompaniesFilterSelect = function (_a) {
    var isAdmin = _a.isAdmin, companyOptions = _a.companyOptions, companies = _a.companies, onCompaniesChange = _a.onCompaniesChange;
    if (!isAdmin) {
        return null;
    }
    return (React.createElement(InputGroup, { width: "auto", maxWidth: 400, minWidth: ["100%", 224] },
        React.createElement(Text, { as: "label", fontSize: "sm" }, "F\u00F6retag"),
        React.createElement(Select, { isMulti: true, name: "companies", height: "auto", closeMenuOnSelect: false, options: companyOptions, value: companies, isClearable: !companies.some(function (x) { return x.value === -1; }), onChange: onCompaniesChange })));
};
var Transactions = function (_a) {
    var areas = _a.areas, companiesData = _a.companies, userAccounts = _a.accounts, currentUser = _a.currentUser, filters = _a.filters, onFiltersChange = _a.onFiltersChange;
    var isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
    var isSysAdmin = hasPermission("USER_IS_SYSADMIN", currentUser.permissions);
    var _b = React.useState(null), filter = _b[0], setFilter = _b[1];
    var currentFilter = filters && filter ? filters.find(function (x) { return x.name === filter.label; }) : undefined;
    var _c = useCompaniesFilter(isAdmin, currentUser, companiesData, currentFilter, function () {
        setFilter(null);
    }), companies = _c.companies, companyOptions = _c.companyOptions, allCompaniesSelected = _c.allCompaniesSelected, allActiveCompaniesSelected = _c.allActiveCompaniesSelected, getCompanyIds = _c.getCompanyIds, onCompaniesChange = _c.onCompaniesChange;
    var _d = useDynamicAccounts(companies, allCompaniesSelected, allActiveCompaniesSelected, currentUser, userAccounts, currentFilter), accountOptions = _d.accountOptions, accounts = _d.accounts, accountsLoading = _d.accountsLoading, setAccounts = _d.setAccounts, defaultAccount = _d.defaultAccount, accountsData = _d.accountsData;
    var isAccountsDisabled = isAdmin
        ? allCompaniesSelected || allActiveCompaniesSelected || accountsLoading || companies.length > 1
        : accountsLoading;
    var tradeTypes = [
        {
            value: -1,
            label: "Alla",
        },
        {
            value: 2,
            label: "Extern handel",
        },
    ];
    if (isSysAdmin) {
        tradeTypes.push({
            value: 1,
            label: "Historisk handel",
        });
        tradeTypes.push({
            value: 0,
            label: "Vanlig + historisk",
        });
    }
    var defaultTradeType = isSysAdmin ? tradeTypes[tradeTypes.length - 1] : tradeTypes[0];
    var _e = React.useState(defaultTradeType), tradeType = _e[0], setTradeType = _e[1];
    var revenues = [
        { value: -1, label: "Alla" },
        { value: 0, label: "I trans" },
        { value: 1, label: "Ej i trans" },
    ];
    var defaultRevenue = revenues[0];
    var getTransaction = useAsyncCallback(ReportsService.getTransactions);
    var areaOptions = [{ label: "Alla", value: -1 }].concat(areas.map(function (x) { return ({ label: x.name, value: x.id }); }));
    var defaultArea = areaOptions[0];
    var _f = React.useState(setDate(setMonth(new Date(), 0), 1)), from = _f[0], setFrom = _f[1];
    var _g = React.useState(new Date()), to = _g[0], setTo = _g[1];
    var _h = React.useState(defaultArea), area = _h[0], setArea = _h[1];
    var _j = React.useState(defaultRevenue), revenue = _j[0], setRevenue = _j[1];
    var _k = React.useState(""), description = _k[0], setDescription = _k[1];
    var _l = React.useState(""), transactionId = _l[0], setTransactionId = _l[1];
    var onUpdate = function () {
        getTransaction.execute(__assign({ from: format(from, "yyyy-MM-dd"), to: format(to, "yyyy-MM-dd"), area: area.value === -1 ? undefined : area.value, revenue: revenue.value, iHandelstyp: tradeType.value, accountIds: accounts
                .filter(function (x) { return x.value !== -1; })
                .map(function (x) { return x.value; })
                .join(","), description: description, transactionGroupId: transactionId, withHidden: isAdmin ? 1 : 0 }, getCompanyIds()));
    };
    var getExcelLink = function () {
        var params = __assign({ area: area.value === -1 ? undefined : area.value, accountIds: accounts
                .filter(function (x) { return x.value !== -1; })
                .map(function (x) { return x.value; })
                .join(","), from: format(from, "yyyy-MM-dd"), to: format(to, "yyyy-MM-dd"), withHidden: isAdmin ? 1 : 0, description: description, iHandelstyp: tradeType.value, transactionGroupId: transactionId }, getCompanyIds());
        if (isAdmin) {
            params.revenue = revenue.value;
        }
        return "/api/rapport/transactions/export/excel" + serialize(params);
    };
    var transactions = getTransaction.result;
    return (React.createElement(Box, { px: 4 },
        React.createElement(Filters, null,
            React.createElement(InputGroup, { width: ["100%", 230] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Period"),
                React.createElement(HStack, null,
                    React.createElement(Datepicker, { selected: from, "data-testid": "from", dateFormat: "yyyy-MM-dd", onChange: function (d) {
                            d && setFrom(d);
                        } }),
                    React.createElement(Text, { as: "span" }, "\u2013"),
                    React.createElement(Datepicker, { selected: to, dateFormat: "yyyy-MM-dd", "data-testid": "to", onChange: function (d) {
                            d && setTo(d);
                        } }))),
            React.createElement(InputGroup, { width: ["100%", 132] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Elomr\u00E5de"),
                React.createElement(Select, { name: "area", options: areaOptions, value: area, onChange: function (x) { return setArea(x); } })),
            isAdmin && (React.createElement(InputGroup, { width: ["100%", 132] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Oms\u00E4ttning"),
                React.createElement(Select, { name: "revenue", options: revenues, value: revenue, onChange: function (x) { return setRevenue(x); } }))),
            React.createElement(InputGroup, { width: ["100%", 132] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Handelstyp"),
                React.createElement(Select, { name: "tradeType", options: tradeTypes, value: tradeType, onChange: function (x) { return setTradeType(x); } })),
            React.createElement(CompaniesFilterSelect, { isAdmin: isAdmin, companies: companies, companyOptions: companyOptions, onCompaniesChange: onCompaniesChange }),
            React.createElement(InputGroup, { width: "auto", maxWidth: 400, minWidth: ["100%", 224] },
                React.createElement(Text, { as: "label", fontSize: "sm", style: { opacity: isAccountsDisabled ? 0.5 : 1 } }, "Konton"),
                React.createElement(Select, { name: "accounts", disabled: isAccountsDisabled, height: "auto", isMulti: true, closeMenuOnSelect: false, options: [
                        { label: "", options: accountOptions.filter(function (x) { return x.value === -1 || x.value === -2; }) },
                        {
                            label: "Konton",
                            options: accountOptions.filter(function (x) { return !x.matris && x.value !== -1 && x.value !== -2; }),
                        },
                        {
                            label: "Matriskonton",
                            options: accountOptions.filter(function (x) { return x.matris && x.value !== -1 && x.value !== -2; }),
                        },
                    ], value: accounts, isClearable: !accounts.some(function (x) { return x.value === -1; }), onChange: function (x, meta) {
                        if (meta.action === "clear" || !x || meta.option === defaultAccount) {
                            setAccounts([defaultAccount]);
                        }
                        else if (meta.option && meta.option.value === -2) {
                            setAccounts(accountOptions.filter(function (x) { return x.active && x.value !== -1 && x.value !== -2; }));
                        }
                        else {
                            setAccounts(x.filter(function (y) { return y.value !== -1 && x.value !== -2; }));
                        }
                    } })),
            React.createElement(SavedFilters, { currentUser: currentUser, accountsData: accountsData, companiesData: companiesData, onFiltersChange: onFiltersChange, accounts: accounts, companies: companies, filters: filters, filter: filter, setFilter: setFilter, getCompanyIds: getCompanyIds })),
        React.createElement(Filters, null,
            React.createElement(InputGroup, { width: ["100%", 224] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Beskrivning"),
                React.createElement(Input, { value: description, onChange: function (e) { return setDescription(e.target.value); } })),
            React.createElement(InputGroup, { width: ["100%", 132] },
                React.createElement(Text, { as: "label", fontSize: "sm" }, "Kvittensnummer"),
                React.createElement(Input, { value: transactionId, onChange: function (e) { return setTransactionId(e.target.value); } })),
            React.createElement(InputGroup, { justifyContent: "flex-end", width: "auto" },
                React.createElement(Box, null,
                    React.createElement(Button, { onClick: onUpdate, loading: getTransaction.loading, px: 4, py: 2 }, "H\u00E4mta transaktioner"))),
            React.createElement(InputGroup, { justifyContent: "flex-end", width: "auto" },
                React.createElement(Box, null,
                    React.createElement(Button, { px: 4, py: 2, variant: "secondary", as: "a", href: getExcelLink(), target: "_blank", rel: "noopener" }, "H\u00E4mta excel")))),
        getTransaction.error && React.createElement(ErrorFallback, { error: getTransaction.error }),
        transactions && transactions.__EMPTY_RESULT__ && (React.createElement(Text, { mt: 4, fontWeight: "bold", borderTop: 1, borderTopColor: "lightgrey", py: 3 }, "Inga transaktioner funna med valt filter")),
        transactions && !transactions.__EMPTY_RESULT__ && (React.createElement(Box, { mb: 5, mt: 4 },
            React.createElement(ReportTable, null,
                React.createElement("thead", null,
                    React.createElement(ReportTableRow, null,
                        React.createElement(ReportTableHeader, null),
                        React.createElement(ReportTableHeader, { textAlign: "left" }, "Kvittensnummer"),
                        React.createElement(ReportTableHeader, { textAlign: "left" }, "Datum"),
                        React.createElement(ReportTableHeader, { textAlign: "left" }, "Konto"),
                        React.createElement(ReportTableHeader, { textAlign: "left" }, "Elomr."),
                        React.createElement(ReportTableHeader, { textAlign: "left" }, "Beskrivning"),
                        React.createElement(ReportTableHeader, null,
                            "Elterminer",
                            React.createElement(ReportSubtitle, null, "MWh")),
                        React.createElement(ReportTableHeader, null,
                            "Elcert",
                            React.createElement(ReportSubtitle, null, "Antal")),
                        React.createElement(ReportTableHeader, null,
                            "Takpris",
                            React.createElement(ReportSubtitle, null, "MWh")),
                        React.createElement(ReportTableHeader, null,
                            "Milj\u00F6produkter",
                            React.createElement(ReportSubtitle, null, "Antal")),
                        React.createElement(ReportTableHeader, null,
                            "El",
                            React.createElement(ReportSubtitle, null, "MWh")),
                        React.createElement(ReportTableHeader, null,
                            "Epad",
                            React.createElement(ReportSubtitle, null, "MWh")),
                        React.createElement(ReportTableHeader, null,
                            "Valuta",
                            React.createElement(ReportSubtitle, null, "EUR")))),
                React.createElement("tbody", null, transactions.history.map(function (t) { return (React.createElement(TransactionRow, { key: t.transactionGroupId, transaction: t })); })),
                React.createElement("tfoot", null,
                    React.createElement(ReportTableRow, null,
                        React.createElement(ReportTableColumn, null),
                        React.createElement(ReportTableColumn, { textAlign: "left" }),
                        React.createElement(ReportTableColumn, { textAlign: "left" }),
                        React.createElement(ReportTableColumn, { textAlign: "left" }),
                        React.createElement(ReportTableColumn, { textAlign: "left" }),
                        React.createElement(ReportTableColumn, { textAlign: "left" }),
                        React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(transactions.total.electricityDerivative.totalAbsoluteVolume)),
                        React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(transactions.total.electricityCertificate.totalAbsoluteVolume)),
                        React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(transactions.total.takpris.totalAbsoluteVolume)),
                        React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(transactions.total.miljoprod.totalAbsoluteVolume)),
                        React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(transactions.total.sys.totalAbsoluteVolume)),
                        React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(transactions.total.epad.totalAbsoluteVolume)),
                        React.createElement(ReportTableColumn, { fontWeight: "bold" }, SWE.format(transactions.total.valuta.totalAbsoluteVolume)))))))));
};
export var ReportTable = function (_a) {
    var css = _a.css, props = __rest(_a, ["css"]);
    return (React.createElement(Box, __assign({ as: "table", css: __assign({ borderCollapse: "collapse", "tr:not(:last-child)": {
                borderBottom: 1,
                borderBottomColor: "lightgrey",
            } }, css) }, props)));
};
export var ReportTableRow = function (props) {
    return React.createElement(Box, __assign({ as: "tr" }, props));
};
export var ReportTableHeader = function (props) {
    return React.createElement(Text, __assign({ p: 2, fontSize: "xs", verticalAlign: "top", textAlign: "right", fontWeight: "bold", as: "th" }, props));
};
export var ReportTableColumn = function (_a) {
    var css = _a.css, props = __rest(_a, ["css"]);
    return (React.createElement(Text, __assign({ as: "td", css: __assign({ fontVariantNumeric: "tabular-nums" }, css), fontSize: "xs", textAlign: "right", p: 2 }, props)));
};
export var ReportSubtitle = function (props) {
    return React.createElement(Text, __assign({ as: "span", display: "block", fontSize: "xxs", color: "grey" }, props));
};
export var TransactionsService = {
    getConfig: function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentUser, isAdmin, promises, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TradeService.getCurrentUser()];
                case 1:
                    currentUser = _a.sent();
                    isAdmin = hasPermission("VIEW_OTHER_COMPANY_REPORTS", currentUser.permissions);
                    promises = [
                        ReportsService.getAreas(),
                        ReportsService.getFilterSettings("transaktionslistan"),
                    ];
                    if (isAdmin) {
                        promises.push(AdminService.getCompanies());
                    }
                    else {
                        promises.push(ReportsService.getAccounts(true));
                    }
                    return [4 /*yield*/, Promise.all(promises)];
                case 2:
                    result = _a.sent();
                    return [2 /*return*/, {
                            currentUser: currentUser,
                            areas: result[0],
                            userSettings: result[1],
                            companies: result[2],
                            accounts: result[2],
                        }];
            }
        });
    }); },
};
var TransactionSkeleton = function (_a) {
    var children = _a.children;
    return (React.createElement(Box, null,
        React.createElement(PageHeader, null,
            React.createElement(Text, { fontSize: "xl", fontWeight: "black" }, "Transaktionslistan")),
        children));
};
var TransactionsPage = function () {
    var data = useAsync(function () { return TransactionsService.getConfig(); }, []);
    var onFilterChange = function (u) {
        if (data.result) {
            data.setResult(__assign(__assign({}, data.result), { userSettings: u }));
        }
    };
    if (data.loading) {
        return (React.createElement(TransactionSkeleton, null,
            React.createElement(Box, { height: 200, position: "relative" },
                React.createElement(Loader, null))));
    }
    if (data.error) {
        return (React.createElement(TransactionSkeleton, null,
            React.createElement(ErrorFallback, { error: data.error })));
    }
    var res = data.result;
    if (!res) {
        return null;
    }
    return (React.createElement(TransactionSkeleton, null,
        React.createElement(Page, null,
            React.createElement(Transactions, { areas: res.areas, companies: res.companies, accounts: res.accounts, currentUser: res.currentUser, filters: res.userSettings.filters, onFiltersChange: onFilterChange }))));
};
export default TransactionsPage;
